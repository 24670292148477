import {getRedCount} from "@/api/redCount";
import store from "@/store";
class nakedApproveRedNum {
    constructor() {
        this.total = 0
        this.pending = 0
        this.filling = 0
    }

    computeTotal() {
        this.total = this.pending + this.filling
    }

    empty() {
        this.total = 0
        this.fillNum = 0
        this.pendingNum = 0
    }
}

const state = {
    showLeftMenu: true,//控制侧边栏的折叠与展开
    reportRedDoltNum: 0,
    nakedRedDoltNum: new nakedApproveRedNum(),
}
const getters = {
    getShowLeftMenu: (state) => {
        return state.showLeftMenu
    },
    getNakedRedDoltNum: (state) => {
        state.nakedRedDoltNum.computeTotal()
        return state.nakedRedDoltNum
    },
    getReportRedDoltNum: (state) => {
        return state.reportRedDoltNum
    },

}
const mutations = {
    setInit(state){
        state.nakedRedDoltNum.empty()
        state.reportRedDoltNum=0
    },
    setShowLeftMenu: (state) => {
        state.showLeftMenu = !state.showLeftMenu
    },
    setNakedRedDoltNum(state, data) {
        state.nakedRedDoltNum[data.type] = data.num
    },
    setReportRedDoltNum(state, data) {
        if (!data.type) {
            state.reportRedDoltNum = data.num
        }

    },
}
const actions = {
    //获取报告、裸测未审批条数
    async getRedDoltNum({commit}, info) {
        if(['ADMIN','MANAGER'].includes(store.getters["right/getRole"])||store.getters["right/getUser"][info.viewer.content]){
            const res = await getRedCount(info.data, info.sys)
            if (res.code === 200) {
                commit(info.setRedDoltName, {num: parseInt(res.data), type: info.type})
            }
        }

    }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
