import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";
export const apprUserTabCol=(filteredInfos)=>{
    let filteredInfo=changeFilteredInfo(filteredInfos)
    return [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '审批人',
            dataIndex: 'personnel.name',
            key: 'name',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
            },
            filteredValue: filteredInfo.name || [],
        }, {
            title: '部门',
            dataIndex: 'personnel.personType',
            key: 'personType',
            scopedSlots: {
                customRender: 'personType'
            }
        },
        {
            title: '邮箱账号',
            dataIndex: 'personnel.email',
            key: 'email',
        },
        {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 300,

        }
    ]
}
