import {nakedApi} from "@/axios";

export function nakedUserList(params,data){
    return nakedApi({
        url:'/data-reviewer/page',
        method:'post',
        data:data,
        params:params,
    })
}
export function nakedUserAdd(data){
    return nakedApi({
        url:'/data-reviewer/add',
        method:'post',
        data:data,
    })
}
export function nakedUserDelete(ids){
    return nakedApi({
        url:'/data-reviewer/delete/'+ids,
        method:'delete',
    })
}
export function isNakedApprover(){
    return nakedApi({
        url:'/data-reviewer/isReviewer',
        method:'get',
    })
}

