// 检查邮箱
export var checkMail=(rule, email, callback)=>{
  if(email){
    var emailRegExp=/^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if(emailRegExp.test(email))	{
      callback()
    }else{
      return callback(  new Error('邮箱格式不对'))
    }
  }else {
    return callback( new Error('邮箱不能为空！'))
  }
}
// 是否是数字
export var checkIsIntNumber=(rule, value, callback)=>{
  if(value){
    let reg = /^[1-9]\d*$/;
    if(reg.test(value))	{
      callback()
    }else{
      return callback(  new Error('请填写数字'))
    }
  }else {
    return callback(  new Error('样本数量不为空'))
  }
}
