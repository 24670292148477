
// 复制文本
 export function copyText(text){
     if (navigator.clipboard) {
         navigator.clipboard.writeText(text.trim())
     } else {
         const textArea = document.createElement('textArea')
         textArea.value = text.trim()
         textArea.style.width = 0
         textArea.style.position = 'fixed'
         textArea.style.left = '-999px'
         textArea.style.top = '10px'
         textArea.setAttribute('readonly', 'readonly')
         document.body.appendChild(textArea)
         textArea.select()
         document.execCommand('copy')
         document.body.removeChild(textArea)
     }
 }
