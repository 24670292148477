<template>
  <div>
    <div  style="display: flex">
      <div style="display: flex;margin-right: 30px">
        <div style="display: flex;flex-direction: column">
        <div class="show-file-name" >{{
            fileName ? fileName : inputPlaceholder ? inputPlaceholder : '请上传文件'
          }}</div>
        </div>
        <div>
          <el-upload
            :accept="Array.from(childMsg.types).map(item => item = '.' + item).join(',')"
            class="upload-simple"
            :auto-upload="false"
            :on-change="fileChanage"
            :file-list="fileList"
            :on-exceed="fileMaxNumber"
            :on-remove="handleRemove"
            ref="myUpload"
            :show-file-list="false"
            action
            :limit="1">
            <slot>
              <el-button type="primary" slot="trigger" class="set-el-button">选取文件</el-button>
            </slot>
          </el-upload>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Upload',
  props: ['childMsg','inputPlaceholder', 'someFile','fileNames'],
  data() {
    return {
      fileList: [],
      sample: [],
      downloadUrl: '../',
      fileName:JSON.parse(JSON.stringify(this.fileNames))
    }

  },
  methods: {
    async handleFileChangeSome(file, fileList) {
      this.fileList = fileList
      this.callFatherFile(file, fileList)
    },
    // 读取文件前端
    async fileChanage(file, fileList) {
      this.callFatherFile(file.raw, fileList)
      this.fileList = fileList.slice(-3);
      this.checkUploadFile(file)
    },
    callFatherFile(file, fileList) {
      if (this.someFile) {
        var num = 0
        var tempFileList = []
        var returnFileList = []
        var returnFileListName = []
        fileList.map(file => {
          var isNormal = this.checkUploadFile(file)
          if (isNormal) {
            this.fileName = file.name
            num++
            returnFileList.push(file.raw)
            returnFileListName.push(file.name)
            var fileListSet = new Set(returnFileListName)
            if (fileListSet.size !== returnFileListName.length) {
              this.$message.error("上传失败，该文件名" + file.name + "已存在")
              returnFileList.pop()
              returnFileListName.pop()
              this.$refs.myUpload.abort(file)
              this.$refs.myUpload.clearFiles()
            } else {
              tempFileList.push(file)
              this.fileList = tempFileList
            }
          } else {
            this.$refs.myUpload.clearFiles()
          }
        })
        if (num === fileList.length) {
          this.$emit('callFather', returnFileList)
        }
      } else {
        if (file === '') {
          this.fileName = ''
          this.$emit('callFather')
        }
        var isNormal = this.checkUploadFile(file)
        if (isNormal) {
          this.fileName = file.name
          this.$emit('callFather', file)
        } else {
          this.$refs.myUpload.clearFiles()
        }
      }
    },
    handleRemove() {
      this.callFatherFile('')
    },
    fileMaxNumber(file, fileList) {
      var flag = this.checkUploadFile(file[0])
      if (flag) {
        this.$refs.myUpload.clearFiles()
        this.fileList.push(file[0])
        this.callFatherFile(file[0])
      }
    },
    checkUploadFile(file) {
      if (!file) {
        return false
      }
      var fileSuffix = file.name.substr(file.name.lastIndexOf('.') + 1)
      var typeNormal = this.childMsg.types.has(fileSuffix) || this.childMsg.types.size == 0
      if (!typeNormal) {
        this.$message.error('文件类型不匹配,仅支持' + Array.from(this.childMsg.types).join(',') + '格式的文件')
      }
      const isLtM = file.size / 1024 / 1024 <= this.childMsg.fileSize
      if (!isLtM) {
        this.$message.error('上传文件大小不能超过' + this.childMsg.fileSize + 'M')
      }
      return typeNormal && isLtM
    },
  }
}
</script>

<style  scoped>
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
  transition: none;
}

.show-file-name {
  border: 1px solid #d9d9db;
  border-radius: 2px;
  padding: 0 10px;
  //height: 40px;
  //line-height: 40px;
  min-width: 240px;
  //color: #ced5d5;
}

.el-col {
  height: 40px;
  line-height: 40px;
}

::v-deep .el-list-enter,
::v-deep .el-list-leave-active {
  opacity: 0;
}

.upload-simple {
  display: inline-block;
  margin-left: 20px;
}

.set-el-button {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

::v-deep .el-upload__input {
  height: 0;
  width: 0;
}
</style>
