<template>
  <div>
    <div style="margin-bottom: 20px;margin-top: 30px">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
    </div>
    <div style="width:100%;">
      <w-table :columns="columns" :table-data="tableData"
               @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
               :page="page" @pageChange="handlePagePagination">
        <div slot="operation" slot-scope="{text,record}">
          <el-button size="mini" type="success" @click="handleEdit(record)">编辑</el-button>
          <el-button size="mini" type="warning" @click="handleResetPass(record)">重置密码</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(record)">转移</el-button>
        </div>
      </w-table>
    </div>
    <!--    图片加载-->
    <picture-loading v-if="loading"></picture-loading>
    <add-customer-dialog v-if="showAddEditCusDialog" :show-dialog="showAddEditCusDialog" :selectType="selectType"
                         :select-detail="selectDetail" @submit="closeAddEditCusDialog">
    </add-customer-dialog>
    <ReplaceByDelete v-if="showDeleteDialog" :deleterInfo="deleterInfo" :showDialog.sync="showDeleteDialog"
                     :userData="tableData"
                     @submit="submitDelete"></ReplaceByDelete>

  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import PictureLoading from "@/components/customerTable/PictureLoading";
import {userAdd, userDelete, userEdit, userList, userResetPass} from "@/api/dilivery/customer";
import {copyText} from "@/utils/windowsUtils";
import {customerTableCol} from "@/utils/data/tableCol/customerTableCol";
import AddCustomerDialog from "@/components/AddCustomerDialog";
import ReplaceByDelete from "@/components/ReplaceByDelete";

export default {
  name: "User",
  components: {PictureLoading, AddCustomerDialog, ReplaceByDelete},
  data() {
    return {
      locale: zhCN,
      loading: true, //是显示加载
      title: '添加客户',
      searchConditionTag: [],//搜索tag
      sortConditionTag: [],
      tableData: [],
      showAddEditCusDialog: false, //控制编辑页面显示与隐藏
      selectDetail: '',
      selectType: {},
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      deleterInfo: {},
      showDeleteDialog: false,
    }
  },
  computed: {
    columns() {
      return JSON.parse(JSON.stringify(customerTableCol(this.searchConditionTag || {})))
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //获取数据
    getData(type) {
      let params = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize
      }
      if (type) {
        params['page.current'] = 1
        params['page.size'] = 10
      }
      let sendData = {}
      sendData = this.getSearch(sendData)
      userList(params, sendData).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      }).catch(() => {
      })
    },
    getSearch(sendData) {
      this.searchConditionTag.forEach(item => {
        sendData[item.key] = item.value
      })
      return sendData
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      this.page.pageSize = page.pageSize
      this.getData()
    },
    //编辑
    handleEdit(row) {
      if (row !== undefined && row !== 'undefined') {
        this.selectDetail = row
        this.selectType = {
          type: 'edit',
          role: 'customer'
        }
      } else {
        this.selectType = {
          type: 'add',
          role: 'customer'
        }
        this.selectDetail = ''
      }
      this.showAddEditCusDialog = true
    },
    // 关闭编辑、增加、批量添加弹出框
    closeAddEditCusDialog(type) {
      if (type === 'confirm') {
        this.getData()
      }
      this.showAddEditCusDialog = false
    },
    //重置密码
    handleResetPass(row) {
      this.$confirmUi(`你确定要重置客户"${row.realName}"的密码吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: "warning"
      }).then(() => {
        userResetPass(row.id).then(res1 => {
          if (res1.code === 200) {
            let msg = `<div>密码重置成功！新密码：<span style="color: #3f85ff">${res1.data}</span></div>`
            this.$alert(msg, '提示', {
              confirmButtonText: '关闭并复制密码',
              dangerouslyUseHTMLString: true,
              type: "success",
              showClose: false,
            }).then(() => {
              copyText(res1.data)
              this.$messageUi.success('复制成功！')
            })
          }
        })
      }).catch(() => {
      })
    },
    handleDelete(record) {

      this.deleterInfo=record
      this.showDeleteDialog = true

    },
    submitDelete(data) {
      this.showDeleteDialog = false
      if (data !== 'cancel') {
        userDelete(data).then(res => {
          if (res.code === 200) {
            this.$message.success('转移成功')
            const totalPage = Math.ceil((this.page.total - 1) / this.page.pageSize)
            this.page.currentNum = this.page.currentNum > totalPage ? totalPage : this.page.currentNum
            this.page.currentNum = this.page.currentNum < 1 ? 1 : this.page.currentNum
            this.getData()
          } else {
            this.$message.error('转移失败')
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
