import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";

export const customerTableCol = (filteredInfos) => {
    let filteredInfo = changeFilteredInfo(filteredInfos)
    return [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sortDirections: ["descend", "ascend", 'descend'],
        },
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username',
            sortDirections: ["descend", "ascend", 'descend'],
        },
        {
            title: '姓名',
            dataIndex: 'realName',
            key: 'realName',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
            },
            filteredValue: filteredInfo.realName || [],
        },
        {
            title: '手机号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: '邮箱账号',
            dataIndex: 'emailAccount',
            key: 'emailAccount',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
            },
            filteredValue: filteredInfo.emailAccount || [],
        },
        {
            title: '操作',
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 300,

        }
    ]
}
