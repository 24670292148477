//项目红点获取条数

import { redCountReport} from "@/api/dilivery/approve/report";
import {redCountNaked} from "@/api/naked/rawData/approval";

export function getRedCount(sendData, plat) {
    return new Promise((resolve, reject) => {
        switch (plat) {
            case 'REPORT':
                resolve(redCountReport(sendData));
                break;
            case 'NAKED':
                resolve(redCountNaked(sendData));
                break;
        }
    })
}
