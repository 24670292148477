export const ch_en_role = [
    {
        ch: '销售',
        en: 'SALES'
    }, {
        ch: '分析',
        en: 'ANALYST'
    }, {
        ch: '审核',
        en: 'AUDITOR'
    }, {
        ch: '技术支持',
        en: 'TECH'
    }]

const platform = [
    {
        ch: '多样性交付系统',
        en: 'AMPLICON'
    }, {
        ch: '转录组交付系统',
        en: 'TRANS'
    }, {
        ch: '项目交付系统',
        en: 'RAW_DATA'
    }, {
        ch: '宏基因组交付系统',
        en: 'METAG'
    },  {
        ch: '转录组交付系统',
        en: 'TRANS'
    }, {
        ch: '交付系统',
        en: '交付系统'
    }, {
        ch: '代谢组交付系统',
        en: 'METABO'
    },
]
const approvalStatus = [
    {
        ch: '待审批',
        en: 'PENDING'
    }, {
        ch: '同意',
        en: 'PASSED'
    },  {
        ch: '拒绝',
        en: 'DECLINED'
    },  {
        ch: '已完成',
        en: 'SUCCESS'
    },  {
        ch: '待修正',
        en: 'FILLING'
    },  {
        ch: '已失败',
        en: 'FAILED'
    }, {
        ch: '上传中',
        en: 'CONSENTED'
    },
]
const mailSendStatus = [
    {
        ch: '已创建',
        en: 'CREATED'
    }, {
        ch: '未发送',
        en: 'IN_QUEUE'
    },  {
        ch: '已取消发送',
        en: 'CANCELED'
    }, {
        ch: '发送成功',
        en: 'SUCCESS'
    },{
        ch: '发送失败',
        en: 'FAIL'
    },
]
const mailType = [
    {
        ch: '上传',
        en: 'UPLOAD'
    }, {
        ch: '释放',
        en: 'RELEASE'
    },  {
        ch: '催款',
        en: 'DUNNING'
    }, {
        ch: '超时',
        en: 'TIMEOUT'
    }, {
        ch: '报告驳回',
        en: 'REFUSE_REPORT'
    }, {
        ch: '报告审批超时',
        en: 'REPORT_APPROVAL_TIMEOUT'
    }
]
const rawDataStatus = [
    {
        ch: '   无  ',
        en: 'NO_DATA',
    }, {
        ch: '  未释放 ',
        en: 'NOT_RELEASED'
    },  {
        ch: ' 链接释放 ',
        en: 'LINK_RELEASED'
    }, {
        ch: ' 硬盘释放 ',
        en: 'HDD_RELEASED'
    },
]
export const rawDataStatusColor = {
        'NO_DATA':'primary',
        'NOT_RELEASED':'info',
        'LINK_RELEASED':'success',
        'HDD_RELEASED':'success',
    }
export const approvalStatusColor = {
        'PENDING':'primary',
        'FILLING':'warning',
        'CONSENTED':'info',
        'FAILED':'danger',
        'SUCCESS':'success',
    }


export function getRoleChByEn(val) {
    return transCh(ch_en_role, val)
}

export function getRoleEnByCh(val) {
    return transEn(ch_en_role, val)
}

export function getPlatformChByEn(val) {
    return transCh(platform, val)
}

export function getPlatformEnByCh(val) {
    return transEn(platform, val)
}
export function getApprovalStatusChByEn(val) {
    return transCh(approvalStatus, val)
}
export function getApprovalStatusEnByCh(val) {
    return transEn(approvalStatus, val)
}
export function getMailSendStatusChByEn(val) {
    return transCh(mailSendStatus, val)
}
export function getMailSendStatusEnByCh(val) {
    return transEn(mailSendStatus, val)
}
export function getMailTypeChByEn(val) {
    return transCh(mailType, val)
}
export function getMailTypeEnByCh(val) {
    return transEn(mailType, val)
}
export function getRawDataStatusChByEn(val) {
    return transCh(rawDataStatus, val)
}
export function getRawDataStatusEnByCh(val) {
    return transEn(rawDataStatus, val)
}
function transEn(arr, val) {
    if (val) {
        const foundItem = arr.find(item => item.ch.trim() === val)
        if (foundItem) {
            return foundItem.en
        } else {
            return ''
        }
    } else {
        return ''
    }
}
function transCh(arr, val) {
    if (val) {
        const foundItem = arr.find(item => item.en === val);
        if (foundItem) {
            return foundItem.ch;
        } else {
            return ''
        }
    } else {
        return ''
    }

}
