<template>
  <!-- 添加或修改项目对话框 -->
  <el-dialog title="修改项目信息" :visible.sync="showDialog" width="800px"
             append-to-body :destroy-on-close="true"   :before-close="cancel">
    <el-form ref="form" :model="form" :rules="rules" label-width="165px" >
<!--      <el-form-item label="项目名称" prop="name">-->
<!--        <el-input v-model="form.name" placeholder="请输入项目名称"/>-->
<!--      </el-form-item>-->
      <el-form-item label="样本数量" prop="sampleNumber">
        <el-input v-model="form.sampleNumber" placeholder="请输入样本数量"/>
      </el-form-item>
      <div v-if="form.rawDataStatus!=='NO_DATA'">
        <el-form-item label="原始数据链接" prop="rawLink">
          <el-input v-model="form.rawLink" type="textarea" :rows="4" placeholder="请输入原始数据链接"/>
        </el-form-item>
      </div>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center!important;">
      <el-button type="primary" @click="handle">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import upload from "@/components/Upload";
import {checkIsIntNumber, checkMail} from "@/utils/rules";

export default {
  name: "editProject",
  components:{
    upload,
  },
  props:['selectDetail','showDialog'],
  watch:{
    'selectDetail':{
      handler(newVal,oldVal){
        if(newVal){
          this.form=JSON.parse(JSON.stringify(newVal))
        }
      },
      immediate:true
    }
  },
  data(){
    return{
      uploadConReportProperty:{
        fileSize: 100 * 1024 * 1024 * 1024,
        types: new Set(['rar', 'zip', 'arj', 'z', 'lzh', 'jar', '7z', '.dar', 'tar', 'gz'])
      },
      rules:{
        // name: {required: true, trigger: 'blur', message: '请输入项目名称'},
        sampleNumber: {validator: checkIsIntNumber, required: true, trigger: 'blur'},
      },
      conReport:'',
      form:{
        // name:'',
        sampleNumber:'',
        rawLink:'',
        remark:''
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('submit', this.form,this.conReport, 'cancel')
    },
    handle() {
      this.$emit('submit', this.form,this.conReport, 'confirm')
    },
  }
}
</script>

<style scoped>
.wenHaoValue {
  font-family: "Microsoft YaHei";
  display: inline-block;
  text-align: right;
  vertical-align: middle;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  white-space: nowrap;
  width: 165px;
}
</style>
