
import {nakedApi} from "@/axios";

// 查询项目共享人列表
export function listProjectSharerNaked(projectId) {
    return nakedApi({
        url: '/project/share/list/'+projectId,
        method: 'post',
    })
}
// 删除项目共享人
export function delProjectSharerNaked(data) {
    return nakedApi({
        url: '/project/share/delete',
        method: 'post',
        data:data
    })
}
export function addProjectSharerNaked(data){
    return nakedApi({
        url:'/project/share/add',
        method:'post',
        data:data
    })
}
