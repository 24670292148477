import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";

export const columnsData = (filteredInfos) => {
    let filteredInfo = changeFilteredInfo(filteredInfos)
    return [
        {
            title: '邮件编号',
            dataIndex: 'mailSign',
            key: 'mailSign',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
            },
            filteredValue: filteredInfo.mailSign || [],
        },
        {
            title: '模块',
            dataIndex: 'modelName',
            key: 'modelName',
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                filterDropdown: 'filterDropdownPlatform',
                filterIcon: 'filterIcon',
                filtered: true,
            },
            filteredValue: filteredInfo.modelName || [],
        },
        {
            title: '合同号',
            dataIndex: 'contractNo',
            key: 'contractNo',
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                customRender: 'foldInfo',
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
            },
            filteredValue: filteredInfo.contractNo || [],
        },
        {
            title: '发件人',
            dataIndex: 'sender.name',
            key: 'senderName',
        }, {
            title: '收件邮箱',
            dataIndex: 'toMails',
            key: 'to',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'toMail',
            },
            filteredValue: filteredInfo.to || [],
        },
        {
            title: '抄送邮箱',
            dataIndex: 'ccMails',
            key: 'cc',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'ccMail',
            },
            filteredValue: filteredInfo.cc || [],
        },
        {
            title: '邮件类型',
            dataIndex: 'mailType',
            key: 'mailType',
            scopedSlots: {
                filterDropdown: 'filterDropdownMailType',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'mailType',
            },
            filteredValue: filteredInfo.mailType || [],
        },
        {
            title: '发送时间',
            dataIndex: 'createTime',
            key: 'createTime',
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            // scopedSlots: {
            //     filterDropdown: 'filterDropdownTime',
            //     filterIcon: 'filterIcon',
            //     filtered: true,
            // },
            // filteredValue: filteredInfo.createTime || [],
        }, {
            title: '发送状态',
            dataIndex: 'mailStatus',
            key: 'mailStatus',
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                filterDropdown: 'filterMailStatus',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'mailStatus'
            },
            filteredValue: filteredInfo.mailStatus || [],
        },
        {
            title: '操作',
            width: 200,
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
        }
    ]
}
