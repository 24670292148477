<template>
  <el-dialog :destroy-on-close="true"
             title="更改项目关联用户"
             :visible.sync="showDialog"
             :before-close="cancel"
             width="500px">
    <el-form :model="form" label-width="120px">
      <el-form-item label="分析人员">
        <el-select v-model="form.selectedAnalyze" filterable placeholder="请选择">
          <el-option
              v-for="(item,index) in analyzerList"
              :key="index"
              :label="item.name"
              :value="item.uuid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="销售">
        <el-select v-model="form.selectedSalers" filterable multiple placeholder="请选择">
          <el-option
              v-for="(item,index) in saleList"
              :key="index"
              :label="item.name"
              :value="item.uuid">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
              <el-button @click="cancel">取 消</el-button>
              <el-button type="primary" @click="handle">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {userList} from "@/api/dilivery/user";

export default {
  name: "ChangeUsers",
  props: ['showDialog', 'selectDetail'],
  data() {
    return {
      form: {
        projectId:'',
        selectedAnalyze: '',
        selectedSalers: ''
      },
      saleList: [],
      analyzerList: [],
    }
  },
  watch:{
    'selectDetail':{
      handler(newVal){
        if(newVal){
          this.form.selectedAnalyze=newVal.selectedAnalyze
          this.form.selectedSalers=newVal.selectedSalers
          this.form.projectId=newVal.projectId
        }
      },
      immediate:true
    }

  },
  mounted() {
    this.getUser()
  },
  methods: {
    // //获取人员信息（分析、销售 ）
    getUser() {
      userList('ANALYST',{'page.current':1,'page.size':200},{}).then(response => {
        this.analyzerList = response.data.records
      })
      userList('SALES',{'page.current':1,'page.size':200},{}).then(response => {
        this.saleList = response.data.records
      })
    },
    handle() {
      this.$emit('submit', this.form, 'submit')
    },
    cancel() {
      this.$emit('submit', this.form, 'cancel')
    }
  }
}
</script>

<style scoped>

</style>
