import {
    addProjectSharerRaw,
    delProjectSharerRaw,
    listProjectSharerRaw
} from "@/api/rawData/shareCustomer";
import {
    addProjectSharerBz,
    delProjectSharerBz,
    listProjectSharerBz
} from "@/api/bz/shareCustomer";
import {
    addProjectSharerMetag,
    delProjectSharerMetag,
    listProjectSharerMetag
} from "@/api/metag/shareCustomer";
import {
    addProjectSharerNaked,
    delProjectSharerNaked,
    listProjectSharerNaked
} from "@/api/naked/rawData/project/shareCustomer";
import {bzApi, deliveryApi, metagApi, nakedApi, rawDataApi} from "@/axios";
import {addProjectSharerTrans, delProjectSharerTrans, listProjectSharerTrans} from "@/api/trans/shareCustomer";
import {
    reportUserAdd,
    reportUserDelete, reportUserList
} from "@/api/dilivery/approve/user";
import {nakedUserAdd, nakedUserDelete, nakedUserList} from "@/api/naked/user";
import {addProjectSharerMetabo, delProjectSharerMetabo, listProjectSharerMetabo} from "@/api/metabo/shareCustomer";

let platApi = ''

function getPlatApi(plat) {
    switch (plat) {
        case 'BZ':
            platApi = 'bzApi'
            break;
        case 'RAW':
            platApi = 'rawDataApi'
            break;
        case 'METAG':
            platApi = 'metagApi'
            break;
        case 'NAKED':
            platApi = 'nakedApi'
            break;
        case 'Trans':
            platApi = 'transApi'
            break;
        default:
            platApi = 'deliveryApi'
    }

}

export function listProjectSharer(sendData, plat) {
    return new Promise((resolve, reject) => {
        switch (plat) {
            case 'BZ':
                resolve(listProjectSharerBz(sendData));
                break;
            case 'RAW':
                resolve(listProjectSharerRaw(sendData));
                break;
            case 'METAG':
                resolve(listProjectSharerMetag(sendData));
                break;
            case 'NAKED':
                resolve(listProjectSharerNaked(sendData));
                break;
            case 'TRANS':
                resolve(listProjectSharerTrans(sendData));
                break;
            case 'METABO':
                resolve(listProjectSharerMetabo(sendData));
                break;
        }
    })
}

//添加
export function addProjectSharer(sendData, plat) {
    return new Promise((resolve, reject) => {
        switch (plat) {
            case 'BZ':
                resolve(addProjectSharerBz(sendData));
                break;
            case 'RAW':
                resolve(addProjectSharerRaw(sendData));
                break;
            case 'METAG':
                resolve(addProjectSharerMetag(sendData));
                break;
            case 'NAKED':
                resolve(addProjectSharerNaked(sendData));
                break;
            case 'TRANS':
                resolve(addProjectSharerTrans(sendData));
                break;
            case 'METABO':
                resolve(addProjectSharerMetabo(sendData));
                break;
        }
    })
}

//删除
export function delProjectSharer(sendData, plat) {
    return new Promise((resolve, reject) => {
        switch (plat) {
            case 'BZ':
                resolve(delProjectSharerBz(sendData));
                break;
            case 'RAW':
                resolve(delProjectSharerRaw(sendData));
                break;
            case 'METAG':
                resolve(delProjectSharerMetag(sendData));
                break;
            case 'NAKED':
                resolve(delProjectSharerNaked(sendData));
                break;
            case 'TRANS':
                resolve(delProjectSharerTrans(sendData));
                break;
            case 'METABO':
                resolve(delProjectSharerMetabo(sendData));
                break;
        }
    })
}

export function listApproveUser(params, sendData, model) {
    return new Promise((resolve, reject) => {
        switch (model) {
            case 'REPORT':
                resolve(reportUserList(params, sendData));
                break;
            case 'NAKED':
                resolve(nakedUserList(params, sendData));
                break;
        }
    })
}

//添加
export function addApproveUser(sendData, model) {
    return new Promise((resolve, reject) => {
        switch (model) {
            case 'REPORT':
                resolve(reportUserAdd(sendData));
                break;
            case 'NAKED':
                resolve(nakedUserAdd(sendData));
                break;
        }
    })
}

//删除
export function delApproveUser(sendData, model) {
    return new Promise((resolve, reject) => {

        switch (model) {
            case 'REPORT':
                resolve(reportUserDelete(sendData));
                break;
            case 'NAKED':
                resolve(nakedUserDelete(sendData));
                break;
        }
    })
}
