<template>
  <div>
    <template v-if="submenu.sonMenu.length===0">
      <el-menu-item :index="submenu.path" :key="submenu.path" v-if="isLeftMenuItem(submenu)">
        <i :class="submenu.icon">
          <span v-if="redDotNum!==0" class="red-dot">{{ getRedDotNum(submenu.redDotFc,submenu. isInClass) }}</span>
        </i>
        <span slot="title">{{ submenu.title }}</span>
      </el-menu-item>
    </template>
    <template v-else>
      <el-submenu :index="submenu.path" v-if="isLeftMenuItem(submenu)">
        <template slot="title">
          <i :class="submenu.icon">
            <span v-if="redDotNum!==0" class="red-dot">{{ getRedDotNum(submenu.redDotFc,submenu. isInClass) }}</span>
          </i>
          <span slot="title">{{ submenu.title }}</span>
        </template>
        <sidebar-item
            v-for="child in submenu.sonMenu"
            :key="child.path"
            :submenu="child"/>
      </el-submenu>
    </template>
  </div>
</template>

<script>

import {getDataInLocalStorage} from "@/utils/localStorage";

export default {
  name: "SidebarItem",
  props: ['submenu'],
  data() {
    return {}
  },
  computed: {
    redDotNum() {
      return this.getRedDotNum(this.submenu.redDotFc,this.submenu.isInClass);
    }
  },
  methods: {
    isLeftMenuItem(subItem) {
      this.user = JSON.parse(getDataInLocalStorage('user'))
      let isViewer = false
      if (subItem.viewer) {
        isViewer = this.user[subItem.viewer.content]
      }
      return (subItem.role.length === 0 || (subItem.role.includes(this.$store.getters["right/getRole"])) || isViewer);
    },
    getRedDotNum(redDotType,isInClass) {
      if (redDotType) {
        if(isInClass){
          return this.$store.getters[`dataStatus/${redDotType}`].total
        }else {
          return this.$store.getters[`dataStatus/${redDotType}`]
        }

      }
      return 0

    }
  }
}
</script>

<style scoped>

.red-dot{
  top: -5px;
  right: -5px;
}
.el-menu-item.is-active {
  background-color: #7983e4 !important;
  color: #fff;
}

.el-menu-item:hover {
  background-color: #7983e4 !important;
}

.el-submenu :hover {
  background-color: #7983e4 !important;
}

.el-submenu .el-menu-item {
  background-color: #525aba !important;
  color: #ffffff !important;
}

.el-submenu .el-menu-item:hover {
  background-color: #7983e4 !important;
}

.el-submenu .el-menu-item.is-active {
  background-color: #7983e4 !important;
  color: #fff;
}
</style>
