// 查询分析列表
import {deliveryApi} from "@/axios";

export function userList(role,params,data) {
    return deliveryApi({
        url: '/personnel/page/'+role,
        method: 'post',
        params: params,
        data: data
    })
}
