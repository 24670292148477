import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";

export const columnsData = (filteredInfos) => {
    let filteredInfo = changeFilteredInfo(filteredInfos)
    return [{
        title: '项目号',
        dataIndex: 'id',
        key: 'id',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            filtered: true,
        },
    }, {
        title: '合同号',
        dataIndex: 'contractNo',
        key: 'contractNo',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            filtered: true,
            customRender: 'foldInfo'
        },
        filteredValue: filteredInfo.contractNo || [],
    },
        {
            title: '类型',
            dataIndex: 'projectType',
            key: 'projectType',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'foldInfo'
            },
            filteredValue: filteredInfo.projectType || [],
        }, {
            title: '销售姓名',
            dataIndex: 'salesList',
            key: 'salesName',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'salesName'
            },
            filteredValue: filteredInfo.salesName || [],
        }, {
            title: '客户姓名',
            dataIndex: 'customerList',
            key: 'customerName',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'customerName'
            },
            filteredValue: filteredInfo.customerName || [],
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },{
            title: '释放方式',
            dataIndex: 'isHdd',
            key: 'isHdd',
            scopedSlots: {
                customRender: 'dataWay'
            },
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 200,
            scopedSlots: {
                customRender: 'remark'
            },
        },
        {
            title: '操作',
            width: 200,
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
        }
    ]
}
