<template>
  <div class="leftNav">
    <el-menu :default-active="activeMenu" class="el-menu-vertical-demo" :collapse="!showLeftMenu" router
             :unique-opened="true"
             text-color="#ffffff" active-text-color="#edfgfg"
             :collapse-transition="false">
      <div class="header">
        <img v-if="showLeftMenu" src="../assets/logo.png" class="logoImg">
        <h2 class="headerTitle">交付系统</h2>
      </div>
      <sidebar-item v-for="submenu in leftMenus"
                    :key="submenu.path"
                    :submenu="submenu">
      </sidebar-item>
    </el-menu>
  </div>
</template>

<script>

import {getDataInLocalStorage} from "@/utils/localStorage";
import {isNakedApprover} from "@/api/naked/user";
import SidebarItem from "@/components/leftNav/SidebarItem";
import {leftNavData, needRedDoltInfo} from "@/utils/data/basicData/leftNavData";

export default {
  name: "LeftNav",
  components: {
    SidebarItem
  },
  data() {
    return {
      isCollapse: false,
      leftMenus: leftNavData,
    };
  },
  computed: {
    showLeftMenu() {
      return this.$store.getters['dataStatus/getShowLeftMenu']
    },
    activeMenu() {
      const route = this.$route;
      const {meta, path} = {...route};
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  created() {
    this.getRedDotNum()
  },
  methods: {
    // 获取红点数量
    getRedDotNum() {
      needRedDoltInfo.forEach(item => {
        this.$store.dispatch(`dataStatus/getRedDoltNum`, item)
      })
    },
    changeLeftNav() {
      this.isCollapse = !this.isCollapse
    },
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 20px;

}

.logoImg {
  width: 20%;
  margin-left: 30px;
  border-radius: 50px;
}

/deep/ .el-menu {
  background-color: #525aba !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  height: 100vh;
  background-color: #525aba;
}


.leftNav {
  background-color: #5660d5;
  width: auto;
  height: 100%;
}

.headerTitle {
  margin-left: 10px;
  color: white;
  font-family: "Arial Rounded MT";
}
</style>
