<template>
  <div>
    <approve-user model="NAKED">
      <div slot="note"></div>
    </approve-user>

  </div>
</template>

<script>
import ApproveUser from "@/components/approve/ApproveUser";

export default {
  name: "NakedUser",
  components: {
    ApproveUser

  }
}
</script>

<style scoped>

</style>
