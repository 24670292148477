<template>
  <el-dialog title="修改失效时间" :visible.sync="showDialog" width="600px" :before-close="handleClose">
    <el-form label-width="100px" ref="form" :model="form">
      <el-form-item label-width="80px" label="失效时间">
        <div style="display: flex;align-items: center">
          <el-input id="setFont" type='text' style="width: 40% ;margin-bottom: 8px;" :disabled="true"
                    :placeholder="selectDetail.expirationTime.substring(0,10)">
          </el-input>
            <img src="../assets/right.png" style="width: 20px;margin: 0px 5px">
          <el-date-picker type='date' style=" width: 40%"
                          v-model.trim="form.newExpirationTime"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ModifyTimeDialog",
  props: ['showDialog', 'selectDetail'],
  data() {
    return {
      form: {
        newExpirationTime: '',
      },
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:showDialog')
    },
    // 确认
    handleConfirm() {
      if (this.form.newExpirationTime) {
        const data = {
          newExpirationTime:JSON.stringify(this.form.newExpirationTime+' 23:59:59'),
          id: this.selectDetail.id
        }
        this.$emit('submit',data)
      } else {
        this.$message.error('请输入新的失效时间')
      }
    }
  }
}
</script>

<style scoped>

</style>
