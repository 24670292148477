import {deliveryApi} from "@/axios";

export function reportUserList(params,data){
    return deliveryApi({
        url:'/report-reviewer/page',
        method:'post',
        data:data,
        params:params,
    })
}
export function reportUserAdd(data){
    return deliveryApi({
        url:'/report-reviewer/add',
        method:'post',
        data:data,
    })
}
export function reportUserDelete(ids){
    return deliveryApi({
        url:'/report-reviewer/delete/'+ids,
        method:'delete',
    })
}

