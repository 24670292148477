import {deliveryApi} from "@/axios";

// 取消发送邮件
export function cancelEmail(id) {
    return deliveryApi({
        url: '/mail-info/cancel/' + id,
        method: 'get'
    })
}
// 立即发送邮件
export function sendEmail(id) {
    return deliveryApi({
        url: '/mail-info/send/' + id,
        method: 'get'
    })
}
// 下载邮件附件
export function downloadAttachment(id) {
    return deliveryApi({
        url: '/mail/attachment/' + id,
        method: 'get',
        responseType: 'blob',
    })
}
// 查看邮件内容
export function lookEmail(id) {
    return deliveryApi({
        url: '/mail-info/preview/' + id,
        method: 'get'
    })
}
// 更换收件邮件，抄送邮箱
export function changeEmail(data) {
    return deliveryApi({
        url: '/mail-info/edit',
        method: 'put',
        data:data
    })
}
//邮件全部
export function emailData(param,data){
    return deliveryApi({
        url:'/mail-info/page',
        method:'post',
        data:data,
        params:param
    })
}
