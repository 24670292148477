<template>
  <div>
    <el-dialog
      title="删除"
      :visible.sync="showDialog"
      width="30%"
      :before-close="handleClose">
      <el-form ref="form" label-width="120px" :rules="rules" :model="form">
        <el-form-item label="转移人">
          <el-input :value="deleterInfo.realName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="负责对接人邮箱" prop="dockId">
          <el-select v-model="form.transferForMail"  filterable placeholder="请输入负责对接人邮箱"  remote
                     :remote-method="remoteMail">
            <el-option v-for="(item,index) in userList" :value="item" :label="item" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleConfirm">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {userList} from "@/api/dilivery/customer";

export default {
  name: "ReplaceByDelete",
  props: ['showDialog', 'userData', 'deleterInfo'],
  data() {
    return {
      userList:[],
      form: {
        transferForMail: '',
      },
      rules:
        {
          transferForMail: [{required: true, message: '请选择对应的负责人', trigger: 'change'}]
        }
    }
  },
  methods: {
    handleClose() {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.$emit('update:showDialog')
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if(valid){
          const data = {
            transferForMail: this.form.transferForMail,
            delId: this.deleterInfo.id
          }
          this.$emit('submit', data)
        }
      })
    },
    remoteMail(email){
        var param = {
          'page.current': 1,
          'page.size': 200,
        }
      userList(param, {emailAccount:email}).then(res => {
          this.loading = false
          if (res.code === 200) {
            this.userList = res.data.records.map(item => item.emailAccount)
          }
        })
    },
  }
}
</script>

<style scoped>

</style>
