<template>
  <div class="login-wrap m-content-display">
    <div class="login-div login-container m-router-view">
      <h1 class="system-title">凌恩生物交付系统</h1>
      <el-tabs v-model="activeTabName" @tab-click="changeTab()">
        <el-tab-pane label="验证码登录" name="code">
          <el-form label-width="70px" :model="codeLoginForm" :rules="codeLoginRule" ref="codeLoginForm"
                   class="login-form" @submit.native.prevent>
            <el-form-item label="邮箱账号" prop="userEmail">
              <el-input auto-complete="off" placeholder="请输入邮箱" prefix-icon="el-icon-user"
                        v-model="codeLoginForm.userEmail"></el-input>
            </el-form-item>
            <el-form-item label="图形验证" prop="captcha">
              <div style="display: flex;justify-content: space-between">
                <el-input style="width:130px " placeholder="请输入图形验证码" v-model="codeLoginForm.captcha">
                </el-input>
                <img :src="verifyImgUrl" style="width: 150px;cursor: pointer" @click="getVerifyImg()">
              </div>
            </el-form-item>
            <el-form-item label="验证码" prop="authCode">
              <div style="display: flex;justify-content: space-between">
                <el-input style="width:160px " placeholder="验证码" v-model="codeLoginForm.authCode"></el-input>
                <el-button type="primary" style="margin-left: 30px" size="small" @click="verAuthCode()" v-if="!showTime"
                           :disabled="showTime">点击发送验证码
                </el-button>
                <el-button type="info" style="margin-left: 30px" size="small" v-if="showTime" :disabled="showTime">
                  {{ btnText }}后重新发送
                </el-button>
              </div>
            </el-form-item>
            <br>
            <el-form-item style="width:80%;">
              <el-button type="primary" style="width:100%;" native-type="submit"
                         @click="submitFormVer('codeLoginForm')">登录
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="账户登录" name="pass">
          <el-form label-position="left" :model="loginForm" :rules="loginRules" ref="loginForm" label-width="60px"
                   prefix-icon="el-icon-user"
                   @submit.native.prevent
                   class="login-form">
            <el-form-item label="账号" prop="account">
              <el-input type="text" v-model="loginForm.account" auto-complete="off"
                        placeholder="请输入用户名/手机号/邮箱"></el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码">
              <el-input type="password" v-model="loginForm.password" auto-complete="off"
                        placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="记住我" class="form-item-remember">
              <div style="display: flex;justify-content: space-between">
                <div>
                  <el-switch
                      v-model="rememberAccountPwd"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                  </el-switch>
                </div>
                <div>
                  <el-checkbox v-model="isDemoAccount"><span style="font-size: 15px;color: #177bd3">游客登录</span>
                  </el-checkbox>
                </div>
              </div>
            </el-form-item>
            <el-form-item style="width:80%;">
              <el-button type="primary" style="width:100%;" native-type="submit" @click="submitForm('loginForm')">
                登录
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

      </el-tabs>
      <p style="font-size: 13px;color: #9b9b9f"><i class="el-icon-info"></i>温馨提示：为保障您的使用体验，请使用最新版chrome浏览器进行访问，其他浏览器可能会影响展示效果。
      </p>
    </div>

  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import {getCaptImage, login, sendCode} from "@/api/dilivery";
import {getDataInLocalStorage, removeDataInLocalStorage, saveDataToLocalStorage} from "@/utils/localStorage";
import {checkMail} from "@/utils/rules";
import {isNakedApprover} from "@/api/naked/user";

const demoAccount = {
  account: 'guest',
  password: 'guest'
}
export default {
  data() {
    return {
      accountSaveKey: 'accountInfo',
      rememberAccountPwd: true,
      captchaSing: '',//验证码的标识（后端传入的）
      verifyImgUrl: '',//图形验证图片路径
      btnText: '60',//按钮中显示的数字
      showTime: false, // 展示计时按钮
      oneMinutes: 0, // 倒计时60s
      timer: '', //,
      activeTabName:'code',
      //账号登录表单
      loginForm: {
        account: '',
        password: ''
      },
      // 账号登录表单验证
      loginRules: {
        account: [{required:true,message:'不能为空', trigger: 'blur'}],
        password: [{required:true,message:'不能为空', trigger: 'blur'}]
      },
      //验证码登录表单
      codeLoginForm: {
        userEmail: '',
        captcha: '',
        authCode: '',
      },
      //验证码登录表单验证
      codeLoginRule: {
        userEmail: [{validator: checkMail, trigger: 'blur'}],
        captcha: [{message: '请输入图形验证码', trigger: 'blur'}],
        authCode: [{message: '请输入短信验证码', trigger: 'blur'}],
      },
      isDemoAccount: false,//是否用demo账号
    }
  },

  created() {
    // 获取验证码照片
    this.getVerifyImg()
  },
  mounted() {
    this.getUserInfoByUrl()
  },
  watch: {
    isDemoAccount: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.loginForm.account = demoAccount.account
          this.loginForm.password = demoAccount.password
        } else {
          this.getUserInfoByUrl()
        }
      }
    }
  },
  methods: {
    /**
     * 登录功能
     */
    //通过url获取是否从用户管理系统点击或者是邮件，从而实现账户自动填充，没有的话则获取localStorage的值
    getUserInfoByUrl() {
      if(this.$route.fullPath.includes('#code')){
        this.activeTabName='code'
        let email=this.$route.hash.substring(9)
        if(email){
          this.codeLoginForm.userEmail=email
        }
      }else {
        const param = this.$route.query
        let username = param.user
        let password = param.pwd
        const withCode = param.withCode
        if (withCode) {
          let accountInfo = CryptoJS.AES.decrypt(decodeURIComponent(withCode), "personal_accountInfo").toString(CryptoJS.enc.Utf8)
          if (accountInfo) {
            accountInfo = JSON.parse(accountInfo)
            username = accountInfo.username
            password = accountInfo.password
          }
        }
        if (username && password) {
          this.activeTabName='pass'
          removeDataInLocalStorage("token")
          this.$store.commit('right/setUser', '')
          this.loginForm = {
            account: username,
            password: password,
          }
        } else {
          this.getLocalStorage()
        }
      }
    },
    //获取存储在本地的账户信息
    getLocalStorage() {
      var accountJson = getDataInLocalStorage(this.accountSaveKey)
      if (accountJson && accountJson !== {}) {
        this.activeTabName='pass'
        var accountInfo = JSON.parse(accountJson)
        this.loginForm.account = accountInfo.account
        this.loginForm.password = accountInfo.password
      }
    },
    // 获取验证码照片
    async getVerifyImg() {
      getCaptImage().then(res => {
        if (res.code === 200) {
          this.verifyImgUrl = res.img
          this.captchaSing = res.captchaKey
          this.codeLoginForm.captcha = ''
        }
      })
    },
    //验证图形验证码
    async verAuthCode() {
      var that = this
      this.$refs.codeLoginForm.validateField(["userEmail"], (valid, vv) => {
        if (!valid) {
          if (this.codeLoginForm.captcha) {
            that.getAuthCode()
          } else {
            that.$message.error("请输入图形验证码")
          }
        }
      })
    },
    //获取短信验证码
    async getAuthCode() {
      var that = this
      var sendData = {
        account: this.codeLoginForm.userEmail,
        value: this.codeLoginForm.captcha,
        captchaKey: this.captchaSing
      }
      const res = await sendCode(sendData)
      if (res.code === 200) {
        //防止60s内重复点击
        this.$message.success("验证码发送成功！")
        this.oneMinutes = 60;
        this.showTime = true;
        setInterval(() => {
          this.btnText = `${this.oneMinutes}s`;
          this.oneMinutes--;
          if (this.oneMinutes <= 0) {
            this.btnText = '';
            this.showTime = false;
            this.oneMinutes = 60;
          }
        }, 1000)
      } else {
        await this.getVerifyImg()
        this.codeLoginForm.captcha = ''
      }
    },
    //切换tab标签
    changeTab(tab, event) {
      if (!this.rememberAccountPwd) {
        this.$refs["loginForm"].resetFields()
      } else {
        this.$refs["loginForm"].clearValidate()
      }
      this.$refs["codeLoginForm"].resetFields()
    },
    // 点击账号登录页面的登录按钮
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.login(formName);
        }
      })
    },
    //点击登录按钮（账号登录）
    async login(formName) {
      var userData = {
        account: this.loginForm.account,
        password: this.loginForm.password,
        method: 'PASS'
      }
      login(userData).then(res => {
        if (res) {
          if (res.data.code === 200) {
            if (this.rememberAccountPwd) {
              //点记住我
              saveDataToLocalStorage(this.accountSaveKey, JSON.stringify(userData))
            } else {
              removeDataInLocalStorage(this.accountSaveKey)
            }
            this.$message.success("登录成功!");
            this.saveUserInfo(res)
          } else {
            this.$message.error(res.data.message);
            this.loginForm.password = ''
          }
        }
      }).catch((err) => {
      })
    },
    //点击验证码页面的登录按钮
    submitFormVer() {
      var that = this
      this.$refs.codeLoginForm.validate((value) => {
        if (value) {
          that.loginVer()
        } else {
          that.$message.error("有参数不合法")
        }
      });
    },
    //通过验证码登录（发送登录请求）
    loginVer() {
      var sendData = {
        account: this.codeLoginForm.userEmail,
        password: this.codeLoginForm.authCode,
        method: 'CODE'
      }
      login(sendData).then(res => {
        if (res.data.code === 200) {
          this.$message.success("登录成功！")
          this.saveUserInfo(res)
        } else {
          this.$message.error(res.data.message)
          this.codeLoginForm.authCode = ''
          this.getVerifyImg()
        }
      })

    },
    async saveUserInfo(res) {
      this.$store.commit("right/setRole", res.data.data.role)
      this.$store.commit("right/setToken", res.headers.authorization)
      const nakedViewer = await isNakedApprover()
      if (res.data.code === 200 && res.data&&nakedViewer.code===200){
        let userInfo = {
          ...res.data.data,
          'approve_report_viewer': res.data.data.reviewer,
          'approve_naked_viewer':nakedViewer.data
        }
        this.$store.commit("right/setUser", JSON.stringify(userInfo))
        await this.$router.push('/index')
      }

    }
  }
}
</script>

<style scoped>


.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 8%;
}

.login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 450px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}

.remember {
  margin: 0px 0px 35px 0px;
}

.code-box {
  text-align: right;
}

.codeimg {
  height: 40px;
}


/*v-input输入框placeholder字体*/
::v-deep input::-webkit-input-placeholder {
  -webkit-text-fill-color: #c6d3e5;
}

::v-deep input::-ms-input-placeholder {
  -webkit-text-fill-color: #c6d3e5;
}

::v-deep .form-item-remember .el-form-item__label {
  width: 55px !important;
}

.system-title {
  height: 40px;
  line-height: 40px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
}

/*.login-div {*/
/*  width: 450px;*/
/*  margin: 0px auto*/
/*}*/
::v-deep .el-tabs__nav-scroll {
  margin: 0 auto !important;
  width: 50%;

}

.head {
  display: flex;
  justify-content: space-between;
}

.login-form {
  margin-top: 10px !important;
}

.m-content-display {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/*.m-router-view {*/
/*  flex: 1;*/
/*}*/

</style>
