import {nakedApi} from '@/axios'
// 查询项目列表
export function listProject(params,data) {
    return nakedApi({
        url: '/project/page',
        method: 'post',
        params:params,
        data:data
    })
}
export function modifyExpTime(data) {
    return nakedApi({
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        url: '/project/delay/'+data.id,
        method: 'post',
        data:data.newExpirationTime
    })
}
// 获取原始数据连接
export function rawDataLink(projectId) {
    return nakedApi({
        url: '/project/raw-link/'+projectId,
        method: 'get',
    })
}
// 最后10条
export function projectLast_10_naked(){
    return nakedApi({
        url: '/project/lastTen',
        method: 'post',
        data:{}
    })
}
