<template>
  <el-dialog :destroy-on-close="true"
             title="原始数据路径"
             :visible.sync="showDialog"
             :before-close="cancel"
             width="500px">
    <el-form :model="form" label-width="80px">
      <el-form-item label="路径">
          <el-input v-model="form.dataPath" placeholder="请输入路径" type="textarea" :readonly="selectDetail.auditStatus!=='FILLING'"
                    :autosize="{ minRows: 3,}"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div v-if="selectDetail.auditStatus==='FILLING'">
         <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="handle">确认</el-button>
      </div>
      <el-button v-else @click="cancel">关 闭</el-button>

      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DataPathDialog",
  props: ['showDialog', 'selectDetail'],
  data() {
    return {
      form: {
        id:JSON.parse(JSON.stringify(this.selectDetail.id)),
        dataPath: JSON.parse(JSON.stringify(this.selectDetail.dataPath))
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('update:showDialog')
    },
    handle() {
      this.$emit('submit', this.form)
    }

  }
}
</script>

<style scoped>

</style>
