import Vue from 'vue'
import Vuex from 'vuex'
import dataStatus from "@/store/dataStatus";
import right from '@/store/right'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        dataStatus,
        right
    },
})
