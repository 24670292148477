// 用户管理
import {deliveryApi} from "@/axios";

export function userList(params,data){
    return deliveryApi({
        url:'/customer/page',
        method:'post',
        data:data,
        params:params,
    })
}
export function userAdd(data){
    return deliveryApi({
        url:'/customer/add',
        method:'post',
        data:data
    })
}
export function userEdit(data){
    return deliveryApi({
        url:'/customer/edit',
        method:'put',
        data:data
    })
}
export function userResetPass(id){
    return deliveryApi({
        url:'/customer/reset-password/'+id,
        method:'get',
    })
}
// 删除用户
export function userDelete(data) {
    return deliveryApi({
        url: '/customer/transfer',
        method: 'put',
        data: data
    })
}
