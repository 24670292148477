/**
 分片上传
 */

import sparkMd5 from 'spark-md5';
import {confirmUpload, getTask, sliceUpload} from "@/api/uploadFile";

const chunk_size = 5 * 1024 * 1024; // 10MB
export  function startUploadFile(file) {
    return new Promise(async(resolve, reject) => {
        let fileLength = file.size
        let fileName = file.name
        let fileMd5 = await calculateFileMD5(file)
        let response={
            fileMd5:fileMd5
        }
        let data = {
            fileMd5: fileMd5,
            fileLength: fileLength,
            fileName: fileName
        }
        getTasks(data).then(taskDetail => {
            console.log('获取任务')
            if (taskDetail.data.doneUpload) {
                response.flag=true
                resolve(response)
            } else {
                let remainingRanges = [[0, fileLength]]
                let uploadedRanges = []
                taskDetail.data.uploadedRange.ranges.map(item => {
                    if (item.start !== item.end) {
                        uploadedRanges.push([item.start, item.end])
                    }
                })
                if (uploadedRanges.length > 0) {
                    remainingRanges = getRemainingRanges(uploadedRanges, fileLength)
                }
                sliceFiles(file, fileMd5, remainingRanges).then(sliceFlag => {
                    console.log('分片')
                    if (sliceFlag) {
                        confirmUpload(fileMd5, fileLength).then(res => {
                            console.log('合并')
                            if (res.code === 200) {
                                response.flag=true
                                resolve(response)
                            }
                        }).catch(err => {
                            response.flag=false
                            resolve(response)
                        })
                    } else {
                        response.flag=false
                        resolve(response)
                    }
                })

            }
        })
    })
}
function getTasks(data) {
    return new Promise((resolve, reject) => {
        getTask(data).then(res => {
            console.log('请求任务内容')
            resolve(res)
        }).catch(err => reject(err))
    })
}
export function getRemainingRanges(uploadedRanges, fileLength) {
    const remainingRanges = [];
    let start = 0;
    let end = chunk_size - 1;
    for (const range of uploadedRanges) {
        if (start < range[0]) {
            remainingRanges.push([start, range[0] - 1]);
        }
        start = range[1] + 1;
        end = Math.min(start + chunk_size - 1, fileLength - 1);
    }
    if (start < fileLength) {
        remainingRanges.push([start, fileLength - 1]);
    }
    return remainingRanges;
}
function sliceFiles(file, file_md5, remainingRanges) {
    // 分割文件并上传
    return new Promise((resolve, reject) => {
        const promises = remainingRanges.map(range => {
            let length = range[1] - range[0];
            const chunks = Math.ceil(length / chunk_size);
            const uploadPromises = [];
            for (let i = 0; i < chunks; i++) {
                const start = range[0] + i * chunk_size;
                const end = Math.min(range[1], start + chunk_size);
                const chunk = file.slice(start, end);
                // 将 chunk 上传到后端服务器
                let formData = new FormData();
                formData.append('data', chunk);
                uploadPromises.push(sliceUpload(formData, file_md5, start));
            }
            return Promise.all(uploadPromises);
        });
        console.log('分片请求')
        Promise.all(promises)
            .then(results => {
                // 所有分片上传成功
                resolve(true);
            })
            .catch(error => {
                // 任何一个分片上传失败都会触发这里的错误处理
                reject(false);
            });
    });
}
export function calculateFileMD5(file) {
    return new Promise(resolve => {
        const chunk_size = 5 * 1024 * 1024; // 2MB
        const chunks = Math.ceil(file.size / chunk_size);
        const file_reader = new FileReader();
        let current_chunk = 0;
        const spark = new sparkMd5.ArrayBuffer();
        file_reader.onload = e => {
            spark.append(e.target.result);
            current_chunk++;

            if (current_chunk < chunks) {
                loadNext();
            } else {
                resolve(spark.end());
            }
        };

        function loadNext() {
            const start = current_chunk * chunk_size;
            const end = Math.min(file.size, start + chunk_size);

            file_reader.readAsArrayBuffer(file.slice(start, end));
        }

        loadNext();
    });
}
