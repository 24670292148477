import {deliveryApi} from "@/axios";
//系统
export function configData(param,data){
    return deliveryApi({
        url:'/sys-config/page',
        method:'post',
        params:param,
        data:data
    })
}
export function configAdd(data){
    return deliveryApi({
        url:'/sys-config',
        method:'post',
        data:data
    })
}
export function configEdit(data){
    return deliveryApi({
        url:'/sys-config',
        method:'put',
        data:data
    })
}
export function configDelete(ids){
    return deliveryApi({
        url:'/sys-config'+'?ids='+ids,
        method:'delete',
    })
}
