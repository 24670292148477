<template>
  <el-dialog :destroy-on-close="true"
             title="更改邮箱信息"
             :visible.sync="showDialog"
             :before-close="cancelEmail"
             width="750px">
    <el-form :model="emails" label-width="80px">
      <el-form-item label="收件邮箱">
        <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入收件邮箱"
            v-model="emails.toMails">
        </el-input>
      </el-form-item>
      <el-form-item label="抄送邮箱">
        <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入抄送邮箱"
            v-model="emails.ccMails">
        </el-input>
      </el-form-item>
      <el-form-item>
        <span style="color: #a4a2a2">注：可输入多个，一行为一个邮箱</span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
              <el-button @click="cancelEmail">取 消</el-button>
              <el-button type="primary" @click="handleEmail">确 定</el-button>
      </span>

  </el-dialog>
</template>

<script>
export default {
  name: "ChangeEmail",
  props: ['showDialog', 'email'],
  data() {
    return {
      emails: {
        id:'',
        ccMails:'',
        toMails:''
      }
    }
  },
  watch: {
    email: {
      handler: function (newVal, oldVal) {
        if(newVal){
          const email=JSON.parse(JSON.stringify(this.email))
          this.emails={
            id:email.id,
            ccMails:email.ccMails.join('\n'),
            toMails:email.toMails.join('\n')
          }
        }
      },
      immediate:true
    }
  },
  methods: {
    handleEmail() {
      this.$emit('submitEmail',this.emails,'submit')
    },
    cancelEmail(){
      this.$emit('submitEmail',this.emails,'cancel')
    }
  }
}
</script>

<style scoped>

</style>
