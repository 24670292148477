<template>
  <div>
    <el-dialog :destroy-on-close="true"
               title="项目共享人员"
               :visible.sync="showDialog"
               :before-close="cancel"
               width="750px">
      <div style="margin-bottom: 30px" v-if="isPermission('project_share')" >
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd()">添加</el-button>
        <el-button size="small" type="danger" icon="el-icon-delete" @click="handleDelete()">批量删除</el-button>
      </div>
      <el-table
          :data="tableData.slice((page.currentNum - 1) * page.pageSize, page.currentNum * page.pageSize)"
          style="width: 100%" @selection-change="rowSelectionChange">
        <el-table-column
            v-if="isPermission('project_share')"
            type="selection"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="realName"
            :show-overflow-tooltip="true"
            label="项目共享人姓名">
        </el-table-column>
        <el-table-column
            prop="emailAccount"
            :show-overflow-tooltip="true"
            label="邮箱账号">
        </el-table-column>
        <el-table-column
            prop="phoneNumber"
            :show-overflow-tooltip="true"
            label="手机号码">
        </el-table-column>
        <el-table-column
            v-if="isPermission('project_share')"
            label="操作"
            width="180">
          <template slot-scope="scope">
            <a><i class="el-icon-delete" style="color: #d54444" @click="handleDelete(scope.row)">删除</i></a>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-if="tableData.length>10" :child-msg="page"
          @callFather="handlePagination"/>
      <span slot="footer" class="dialog-footer">
              <el-button @click="cancel" type="primary">关 闭</el-button>
      </span>
    </el-dialog>
    <add-customer-dialog v-if="showAddSharerDialog" :show-dialog="showAddSharerDialog" :selectType="selectType"
                         :select-detail="selectDetail" @submit="closeAddSharerDialog">

    </add-customer-dialog>
  </div>


</template>

<script>
import {customerTableCol} from "@/utils/data/tableCol/customerTableCol";
import AddCustomerDialog from "@/components/AddCustomerDialog";
import Pagination from "@/components/customerTable/Pagination";
import {permission} from "@/utils/authority";
import {delProjectSharer, listProjectSharer} from "@/api/sharerCustomer";

export default {
  name: "ShareCustomerManagerDialog",
  props: ['showDialog', 'selectDetail','platform'],
  components: {
    AddCustomerDialog,
    Pagination
  },
  data() {
    return {
      ids: '',
      searchConditionTag: [],//搜索tag
      sortConditionTag: [],
      tableData: [],
      columns: customerTableCol,
      typeName: '',
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      showAddSharerDialog: false,//增加分享者
      selectType:{
        role:'sharer',
        type:'add',
        platform:this.platform
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      listProjectSharer(this.selectDetail.projectId,this.platform).then(res => {
        if (res.code === 200) {
          this.tableData = res.data
          this.page.total=res.data.length
        }
      })
    },
    handlePagination(page){
      this.page.currentNum = page.currentNum; //每次点击分页按钮，当前页发生变化
      this.page.pageSize = page.pageSize; //每次点击分页按钮，当前页发生变化
    },
    handleAdd() {
      this.showAddSharerDialog = true
    },
    cancel() {
      this.$emit('submit')
    },
    rowSelectionChange(row) {
      this.ids = row.map(item => item.id)
    },
    handleDelete(row) {
      let ids = []
      let name = []
      if (row && row.id) {
        ids.push(row.id)
        name.push(row.realName)
      } else {
        if (this.ids.length > 0) {
          ids = this.ids
        } else {
          this.$messageUi.error('请勾选要批量删除的数据')
          return
        }
      }
      this.$confirmUi(`确定要删除${name}吗?`, '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let sendData = {
          projectId: this.selectDetail.projectId,
          shareCustomerIds: ids
        }
        delProjectSharer(sendData,this.platform).then(res => {
          if (res.code === 200) {
            this.getData()
            this.$messageUi.success("删除成功")
          }
        })
      }).catch(()=>{

      })
    },
    closeAddSharerDialog(type) {
      if(type==='confirm'){
        this.getData()
      }
      this.showAddSharerDialog = false
    },
    isPermission(type){
      return  permission(type,this.selectDetail.customerList.map(item=>item.id))

    }

  }
}
</script>

<style scoped>

</style>
