export const updateTableMixin = {
    data() {
        return {}
    },
    methods: {
        updatePageDataBySearch() {
            this.getData('search')
        },
        updatePageData() {
            this.getData('deleteSecOrdTag')
        }
    }
}
