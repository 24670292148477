import {deliveryApi, nakedApi} from "@/axios";

export function approveReportList(params,data){
    return deliveryApi({
        url:'/report-approval/page',
        method:'post',
        data:data,
        params:params,
    })
}
export function approveReportDown(id){
    return deliveryApi({
        url:'/report-approval/report/download/'+id,
        method:'post',
    })
}
export function approveReportApprove(data){
    return deliveryApi({
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        url:'/report-approval/'+data.result+'/'+data.id,
        method:'put',
        data:data.msg,
    })
}
//件获取条数
export function redCountReport(data){
    return deliveryApi({
        url:'/report-approval/count',
        method:'post',
        data:data
    })
}
