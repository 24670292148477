<!--图片加载组件-->
<template>
  <div style=" position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9999;">
    <img src="images/loading-load.gif" style="z-index:100;margin-left:50%;margin-top:18%;width: 85px ">
  </div>
</template>

<script>
export default {
  name: "PictureLoading"
}
</script>

<style scoped>

</style>
