<template>
  <div>
    <approve-user model="REPORT"></approve-user>
  </div>
</template>

<script>
import ApproveUser from "@/components/approve/ApproveUser";

export default {
  name: "ApproveReportUser",
  components:{
    ApproveUser
  }

}
</script>
<style scoped>

</style>
