<template>
  <div>
    <w-table ref="wMyTable" :columns="columns" :table-data="tableData"
             @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
             :page="page" @pageChange="handlePagePagination">
      <span slot="projectId" slot-scope="{text,record}">
        <a @click="getProjectInfo(text)">{{ text }}</a>
      </span>
      <!--       折叠-->
      <div slot="foldInfo" slot-scope="{text, record, index}">
        <ex-fold-table-cell :scope="{text:text.split('|'), record, index}"></ex-fold-table-cell>
      </div>
      <span slot="passApproval" slot-scope="{text,record}" :style="text?'color:#66c03b':'color:#de9930' ">
        {{ text ? '通过' : '待审批' }}
      </span>
      <span slot="customerName" slot-scope="{text,record}">
        {{ text ? Array.from(new Set(text.map(item => item.realName))).join(';') : '' }}
      </span>
      <span slot="remark" slot-scope="{text,record}">
        <span v-if="text&&text.length>=20">
          {{ text.substring(0, 20) }}
          <el-tooltip :content="text">
            <span style="cursor: pointer">...</span>
          </el-tooltip>
        </span>
        <span v-if="!text||text.length<20">{{ text }}</span>
      </span>
      <span slot="salesName" slot-scope="{text,record}">
        {{ text ? text.map(item => item.name).join(';') : '' }}
      </span>
      <template slot="rawDataStatus" slot-scope="{text,record}">
          <span>
            <el-tag style="width: 80px;height: 28px;text-align: center;padding-top: 4px" round size="mini" effect="dark"
                    :type="getRawStatusColor(text)">{{ getRawStatus(text) }}</el-tag>
          </span>
      </template>
      <template slot="operation" slot-scope="{text,record}">
        <el-button v-has-permission="'project_detail'"
                   size="mini"
                   type="success"
                   @click="handleDetail(record)">查看详情
        </el-button>
        <el-button v-has-permission="'project_edit'"
                   size="mini"
                   type="primary"
                   @click="handleUpdate(record)">修改
        </el-button>
        <el-popconfirm
            confirm-button-text='同意释放'
            cancel-button-text='取消'
            title="你同意释放该项目的原始数据吗？"
            v-if="record.rawDataStatus==='NOT_RELEASED'&&record.soleKey&&record.soleKey.some(item=>item.includes('UNKNOW'))"
            v-has-permission="'project_raw_release'"
            @confirm="handleAudit(record)">
          <el-button slot="reference"
                     size="mini"
                     type="info"
                     style="margin-left: 10px">数据审核
          </el-button>
        </el-popconfirm>
        <el-button v-has-permission="'project_share'"
                   size="mini"
                   type="primary"
                   @click="handleShare(record)">分享
        </el-button>

        <el-dropdown size="mini" @command="(command) => handleCommand(command, record)"
                     v-if="['ADMIN','MANAGER'].includes($store.getters['right/getRole'])">
          <el-button size="mini" type="warning" style="margin-left: 10px">更多</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="handleChange" icon="el-icon-edit">更换用户</el-dropdown-item>
            <el-dropdown-item command="handleDown" icon="el-icon-download">下载结题报告</el-dropdown-item>
            <el-dropdown-item command="handleShare" icon="el-icon-share">分享</el-dropdown-item>
            <el-dropdown-item command="handleDelete" icon="el-icon-delete">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <div slot="filterRawDataStatus" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
           style="padding: 8px; position: relative; z-index: 9999 !important;">
        <div style="padding: 0px 15px">
          <el-radio-group v-model="selectedKeys[0]">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="无">无</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="未释放">未释放</el-radio>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="链接释放">链接释放</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="硬盘释放">硬盘释放</el-radio>
              </el-col>
            </el-row>
          </el-radio-group>
        </div>
        <div style="display: flex;padding: 15px">
          <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
          </a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
          </a-button>
        </div>
      </div>
    </w-table>
    <!--    查看项目详情-->
    <info v-if="openDetail" :formData="detailForm" @closeDetail="closeDetail"></info>
    <!--    编辑项目信息-->
    <edit-project v-if="editProjectDialog" :showDialog="editProjectDialog" :select-detail="selectDetail"
                  @submit="submitEditProject"></edit-project>
    <change-users v-if="showUserDialog" :show-dialog="showUserDialog" :select-detail="updateProjectUserData"
                  @submit="submitProjectUser"></change-users>
    <picture-loading v-if="loading"></picture-loading>
    <!--    xx新建中-->
    <LoadingDialog v-show="isShowAddLoading" :showDialog="isShowAddLoading"
                   :dialogMessage="showAddLoadingMess"></LoadingDialog>
    <!--    分享管理-->
    <share-customer-manager-dialog v-if="showShareCusDialog" :select-detail="selectDetail" platform="RAW"
                                   :show-dialog="showShareCusDialog"
                                   @submit="closeShareCusDialog"></share-customer-manager-dialog>
  </div>
</template>

<script>
import {
  addProjectFile,
  auditData,
  changeProjectUser,
  delProject,
  getProject,
  listProject,
  updateProject
} from "@/api/rawData";
import Info from "@/views/systems/project/rawData/Info";
import ChangeUsers from "@/components/ChangeUsers";
import {getDataInLocalStorage} from "@/utils/localStorage";
import EditProject from "@/components/rawData/EditProject";
import {startUploadFile} from "@/utils/uploadFile";
import PictureLoading from "@/components/customerTable/PictureLoading";
import LoadingDialog from "@/components/LoadingDialog";
import {columnsData} from "@/utils/data/tableCol/rawTableCol";
import ExFoldTableCell from "@/components/ExFoldTableCell";
import ShareCustomerManagerDialog from "@/components/ShareCustomerManagerDialog";
import {getRawDataStatusChByEn, getRawDataStatusEnByCh, rawDataStatusColor} from "@/utils/ch_en_trans";
import {tableOperationMixin} from "@/mixins/tableOperation";

export default {
  name: "rawData",
  mixins: [tableOperationMixin],
  components: {
    ShareCustomerManagerDialog,
    EditProject,
    ChangeUsers,
    Info,
    PictureLoading,
    LoadingDialog,
    ExFoldTableCell
  },
  data() {
    return {
      loading: true,
      isShowAddLoading: false,
      showAddLoadingMess: '文件上传中，请耐心等待.......',
      //分页
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      tableData: [],//表格数据
      searchConditionTag: [],
      sortConditionTag: '',
      showUserDialog: false,//展示更改用户的弹框
      updateProjectUserData: {
        projectId: '',
        selectedSalers: [],
        selectedAnalyze: '',
      }, //更改用户的数据（更换分析和销售）
      openDetail: false,//查看详情弹框
      detailForm: {},
      selectDetail: '',
      editProjectDialog: false,
      showShareCusDialog: false,//分享客户管理
    }
  },
  computed: {
    columns() {
      let column = JSON.parse(JSON.stringify(columnsData(this.searchConditionTag || {})))
      if (this.$store.getters["right/getRole"] === 'USER') {
        column.splice(column.length - 4, 1)
      }
      return column
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(isSearch) {
      this.loading = true
      var param = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize,
        'order': ''
      }
      if (isSearch === 'search') {
        param['page.size'] = 10
        param['page.current'] = 1
      }
      let sendData = {}
      let length = this.searchConditionTag.length
      if (length) {
        this.searchConditionTag.forEach(item => {
          let key = item.key
          if (key === 'rawDataStatus') {
            sendData[key] = getRawDataStatusEnByCh(item.value)
          } else {
            sendData[key] = item.value
          }
        })
      }
      listProject(param, sendData).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.loading = false
          this.tableData = res.data.records
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      })
    },
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      if (page.pageSize) {
        this.page.pageSize = page.pageSize
      } else {
        this.page.pageSize = this.page.total
      }
      this.getData()
    },
    //获取原始数据状态
    getRawStatus(status) {
      return getRawDataStatusChByEn(status)
    },
    //获取原始数据状态tag颜色
    getRawStatusColor(status) {
      return rawDataStatusColor[status]
    },
    //项目详情
    handleDetail(row) {
      const id = row.id
      getProject(id).then(response => {
        this.detailForm = response.data
        this.detailForm.soleKey = this.detailForm.soleKey ? this.detailForm.soleKey.join(',') : ''
        this.openDetail = true
      });
    },
    // 关闭查看详情弹框
    closeDetail() {
      this.openDetail = false
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const id = row.id
      getProject(id).then(response => {
        this.selectDetail = response.data
        this.editProjectDialog = true
      });

    },
    //提交修改项目信息
    submitEditProject(data, file, type) {
      this.editProjectDialog = false
      if (type !== 'cancel') {
        this.isShowAddLoading = true
        if (file) {
          startUploadFile(file).then(res => {
            if (res.flag) {
              this.addResZip(file, data.id, res.fileMd5).then(flag => {
                if (flag) {
                  data.reportFileHash = res.fileMd5
                  data.reportPath = file.name
                  this.updateProject(data)
                } else {
                  this.$message.error("修改失败")
                }
              })
            } else {
              this.isShowAddLoading = false
              this.$message.error('文件上传失败')
            }
          })
        } else {
          this.updateProject(data)
        }
      }
    },
    //添加结题报告
    addResZip(file, id, hash) {
      return new Promise((resolve, reject) => {
        let sendData = {
          fileMd5: hash,
          fileName: file.name
        }
        addProjectFile(sendData, id).then(res => {
          if (res.code === 200) {

            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    //请求更新项目信息
    updateProject(data) {
      this.isShowAddLoading = false
      console.log('请求更新数据')
      data.salesList = null
      data.creator = null
      updateProject(data).then(response => {
        if (response.code === 200) {
          this.isUpdate = false
          this.open = false;
          this.$message.success("修改成功");
          this.getData();
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirmUi('您确认要删除项目编号为"' + ids + '"的数据项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return delProject(ids);
      }).then(() => {
        this.getData();
        this.$message.success("删除成功");
      }).catch(() => {
      });
    },
    /** 更换用户按钮操作 */
    handleChange(row) {
      this.updateProjectUserData.selectedSalers = row.salesList.map(item => {
        return item.uuid
      })
      this.updateProjectUserData.selectedAnalyze = row.creator ? row.creator.uuid : ''
      this.updateProjectUserData.projectId = row.id
      this.showUserDialog = true
    },
    // 请求释放原始数据
    handleAudit(row) {
      if (row.auditStatus === 0) {
        auditData(row.id).then(response => {
          if (response.code === 200) {
            this.getData()
            this.$message.success("释放成功");
          }
        })
      } else {
        this.$messageUi.error("释放失败，该数据已被释放");
      }
    },
    //下载报告
    handleDown(row) {
      const url = `/prod-api/raw/project/download/${row.id}?Authorization=${getDataInLocalStorage('token')}`
      const elink = document.createElement('a')
      elink.href = url
      document.body.appendChild(elink)
      elink.click()
    },
    //更多下拉框
    handleCommand(command, row) {
      switch (command) {
        case "handleDown":
          this.handleDown(row);
          break;
        case "handleDetail":
          this.handleDetail(row);
          break;
        case "handleDelete":
          this.handleDelete(row);
          break;
        case "handleChange":
          this.handleChange(row);
          break;
        case "handleShare":
          this.handleShare(row);
          break;
        default:
          break;
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    //提交更换用户的信息
    submitProjectUser(data, type) {
      this.showUserDialog = false
      if (type !== 'cancel') {
        let sendData = {
          "projectId": data.projectId,
          "analystUuid": data.selectedAnalyze,
          "salesUuids": data.selectedSalers,
          "customerIds": []
        }
        changeProjectUser(sendData).then(res => {
          if (res) {
            if (res.code === 200) {
              this.$message.success('修改成功')
              this.getData()
            }
          }
        })
      }
    },
    //跳转到具体的项目
    getProjectInfo(id) {
      window.open('/raw/data/info?id=' + id)
    },
    //分享项目
    handleShare(row) {
      this.selectDetail = {
        projectId: row.id,
        customerList: row.customerList
      }
      this.showShareCusDialog = true
    },
    //关闭分享
    closeShareCusDialog() {
      this.showShareCusDialog = false
    },


  }

}
</script>

<style scoped>

</style>
