/**
 * v-hasPermission 是否有操作权限，针对于按钮
 */

const permit={
    'ADMIN':['project_del','project_export','project_changer_user','project_raw_release','project_edit','project_detail','projects_share','project_modifyExpTime'],
    'MANAGER':['project_del','project_export','project_changer_user','project_raw_release','project_edit','project_detail','projects_share','project_modifyExpTime'],
    'SALES':['project_share','projects_share'],
    'SALES_ADMIN':['project_share','projects_share'],
    'AUDIT':['project_raw_release','project_detail'],
    'ANALYST':['project_detail'],
    'USER':['project_share','projects_share'],
    'TECH':['project_detail','projects_share'],
}
import store from '@/store'
export default {
    inserted(el, binding, vnode) {
        const role = store.getters && store.getters["right/getRole"]
        const { value } = binding
        if(role&&!permit[role].includes(value)){
            el.parentNode && el.parentNode.removeChild(el)
        }
    }
}
