import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";
export const apprReportTabCol=(filteredInfos)=>{
    let filteredInfo=changeFilteredInfo(filteredInfos)
    return [{
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    }, {
        title: '系统',
        dataIndex: 'platform',
        key: 'platform',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdownPlatform',
            filtered: true,
            customRender: 'platform'
        },
        filteredValue: filteredInfo.platform || [],
    }, {
        title: '合同号',
        dataIndex: 'contractNo',
        key: 'contractNo',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdown',
            filtered: true,
            customRender: 'foldInfo'
        },
        filteredValue: filteredInfo.contractNo || [],
    }, {
        title: '类型',
        dataIndex: 'projectType',
        key: 'projectType',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdown',
            filtered: true,
            customRender: 'foldInfo'
        },
        filteredValue: filteredInfo.projectType || [],
    },
    //     {
    //     title: '样品数量',
    //     dataIndex: 'sampleNum',
    //     key: 'sampleNum',
    // },
        {
        title: '销售',
        dataIndex: 'salesList',
        key: 'salesName',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdown',
            filtered: true,
            customRender: 'salesName'
        },
        filteredValue: filteredInfo.salesName || [],
    }, {
        title: '分析员',
        dataIndex: 'analyst.name',
        key: 'analystName',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdown',
            filtered: true,
        },
        filteredValue: filteredInfo.analystName || [],
    },
        {
            title: '发起时间',
            dataIndex: 'createTime',
            key: 'createTime',
        }, {
            title: '加急状态',
            dataIndex: 'urgent',
            key: 'urgent',
            scopedSlots: {
                customRender: 'urgentStatus'
            }
        }, {
            title: '是否纯分析项目',
            dataIndex: 'isPure',
            key: 'isPure',
            scopedSlots: {
                customRender: 'pureStatus'
            }
        }, {
            title: '备注',
            dataIndex: 'submitRemark',
            key: 'submitRemark',
            scopedSlots: {
                customRender: 'remark'
            }
        },
        {
            title: '审批状态',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            scopedSlots: {
                customRender: 'approvalStatus'
            }
        },
        {
            title: '操作',
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 300,

        }
    ]
}
