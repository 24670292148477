
import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";
export const columnsData=(filteredInfos)=> {
    let filteredInfo = changeFilteredInfo(filteredInfos)
    return [
        {
            title: '项目号',
            dataIndex: 'projectsRow.projectId',
            key: 'projectId',
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'projectId',
            },
        },
        {
            title: '合同号',
            dataIndex: 'projectsRow.contractNumber',
            key: 'contractNumber',
            width: 200,
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'foldInfo'
            },
        },
        {
            title: '类型',
            dataIndex: 'projectsRow.projectType',
            key: 'projectType',
            sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'foldInfo'
            },
        },
        {
            title: '销售姓名',
            dataIndex: 'salerList',
            key: 'salesName',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'salesName'
            },
        },
        {
            title: '客户姓名',
            dataIndex: 'customerList',
            key: 'customerName',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customerName'
            },
        },
        {
            title: '分析员姓名',
            dataIndex: 'analyst.name',
            key: 'analystName',
            // sorter: true,
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
            },
        },
        {
            title: '创建时间',
            dataIndex: 'projectsRow.createTime',
            key: 'createTime',
        },
        {
            title: '报告审批状态',
            dataIndex: 'projectsRow.passApproval',
            key: 'projectsRow.passApproval',
            scopedSlots: {
                customRender: 'passApproval'
            },
        },
        {
            title: '原始数据状态',
            dataIndex: 'projectsRow.rawDataStatus',
            key: 'rawDataStatus',
            width: 200,
            scopedSlots: {
                filterDropdown: 'filterRawDataStatus',
                filterIcon: 'filterIcon',
                customRender: 'rawDataStatus',
            },
        },
        {
            title: '备注',
            dataIndex: 'projectsRow.note',
            key: 'note',
            width: 200,
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customNote',
            },
        },
        {
            title: '操作',
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
        },
    ]
}
