<template>
  <el-dialog :destroy-on-close="true"
             title="命令行"
             :visible.sync="showDialog"
             append-to-body
             :before-close="beforeClose"
             width="700px"
  >
    <div class="noteText">
      注：运行方法:1、点击 " 复制文本 " 按钮后，将文本复制到终端，回车执行;
      <div style="margin-left: 90px">
        2、点击 " 下载sh文件 " 按钮后到终端执行;<br>
      </div>
    </div>
    <div style="display: flex;justify-content:space-between;margin:20px 0px">
      <div>
        <el-button type="success" size="small" @click="copyCommand">复制文本</el-button>
        <el-button type="primary" size="small" @click="downSh">下载sh文件</el-button>
      </div>
      <el-link :underline="false" type="primary" size="small" @click="help">帮助文档<i class="el-icon-question"
                                                                                       style="margin-left: 5px"
      ></i></el-link>
    </div>
    <div class="code-block">
      <div class="code-container">
        <pre class="language-javascript"><code>{{ command }}
        </code></pre>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('update:showDialog')">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {copyText} from "@/utils/windowsUtils";

export default {
  name: 'CommandDialog',
  props: ['showDialog', 'selectDetail'],
  data() {
    return {
      command: JSON.parse(JSON.stringify(this.selectDetail.split('&&'))).join('&&\n')
    }
  },
  methods: {
    copyCommand() {
      copyText(this.selectDetail)
      this.$emit('update:showDialog')
    },
    downSh() {
      var blob = new Blob([this.selectDetail], {type: 'application/x-sh'})
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'run.sh'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    help() {
      // 创建要填充到新窗口中的内容
      var content = `<html><body><h4> 安装运行环境方法：终端执行以下3种命令中的一种<br>
      <div style="margin-left: 125px">
        1、sudo yum install curl<br>
        2、sudo apt install curl<br>
        3、conda install curl<br></div>
  </h4></body></html>`
      var newWindow = window.open()
      newWindow.document.write(content)
    },
    beforeClose() {
      this.$emit('update:showDialog', false)
    }
  }
}
</script>
<style scoped>
/* 代码块样式 */
.code-block {
  background-color: #f4f5f6;
  color: #abb2bf;
  border-radius: 5px;
  overflow: auto;
  padding: 20px;
  max-height: 500px;
  min-width: 94%;
}

.code-container {
  padding: 10px;
}

.code-block pre {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 3px;
}

.code-block pre code {
  font-family: 'Consolas', 'Courier New', monospace;
}

/* 语法高亮 */
.code-block .language-javascript {
  color: #5c5e5e;
  width: 100%;
  box-sizing: border-box; /* 使用 border-box 盒模型 */
  padding-right: 20px;
}

.noteText {
  line-height: 25px;
  color: red
}
</style>
