export const leftNavData = [
    {title: '客户管理', icon: 'el-icon-user', path: '/customer', role: ['ADMIN', 'MANAGER', 'AUDIT'], sonMenu: []},
    {title: '项目信息总览', icon: 'el-icon-s-grid', path: '/index', role: [], sonMenu: []},
    {title: '项目交付系统', icon: 'el-icon-c-scale-to-original', path: '/projectRawData', role: [], sonMenu: []},
    {title: '多样性交付系统', icon: 'el-icon-c-scale-to-original', path: '/projectBz', role: [], sonMenu: []},
    {title: '宏基因组交付系统', icon: 'el-icon-c-scale-to-original', path: '/projectMetag', role: [], sonMenu: []},
    {title: '转录组交付系统', icon: 'el-icon-c-scale-to-original', path: '/projectTrans', role: [], sonMenu: []},
    {title: '代谢组交付系统', icon: 'el-icon-c-scale-to-original', path: '/projectMetabo', role: [], sonMenu: []},
    {
        title: '邮件管理',
        icon: 'el-icon-message',
        path: '/email',
        role: ['ADMIN', 'MANAGER', 'ANALYST', 'AUDIT'],
        sonMenu: []
    },
    {
        title: '报告审批管理',
        icon: 'el-icon-edit-outline position-relative',
        path: '/approve',
        role: ['ADMIN', 'MANAGER', 'AUDIT'],
        redDotFc: 'getReportRedDoltNum',
        viewer: {
            content: 'approve_report_viewer'
        },
        sonMenu: [
            {
                title: '审批人员管理',
                icon: 'el-icon-user',
                path: '/approveUser',
                role: ['ADMIN', 'MANAGER', 'AUDIT'],
                sonMenu: []
            },
            {
                title: '报告审批',
                icon: 'el-icon-tickets position-relative',
                path: '/approveReport',
                role: [],
                sonMenu: [],
                redDotFc: 'getReportRedDoltNum',
                viewer: {
                    content: 'approve_report_viewer'
                },
            },
        ]
    },
    {
        title: '裸测-原始数据交付系统',
        icon: 'el-icon-tickets position-relative',
        path: '/naked',
        role: [],
        redDotFc: 'getNakedRedDoltNum',
        isInClass: true,
        sonMenu: [
            {
                title: '审批人员管理',
                icon: 'el-icon-user',
                path: '/nakedUser',
                role: ['ADMIN', 'MANAGER', 'AUDIT'],
                sonMenu: []
            },
            {
                title: '审批',
                icon: 'el-icon-edit-outline position-relative',
                path: '/nakedApproval',
                role: ['ADMIN', 'MANAGER',],
                sonMenu: [],
                redDotFc: 'getNakedRedDoltNum',
                isInClass: true,
                viewer: {
                    content: 'approve_naked_viewer'
                },
            }, {
                title: '原始数据',
                icon: 'el-icon-tickets',
                path: '/nakedRawData',
                role: [],
                sonMenu: []
            },
        ]
    },
    {title: '系统配置', icon: 'el-icon-setting', path: '/config', role: ['ADMIN'], sonMenu: []},
    {title: '个人中心', icon: 'el-icon-user', path: '/info', role: ['CUSTOMER'], sonMenu: []},
]


export const needRedDoltInfo = [
    {
        sys: 'NAKED',
        setRedDoltName: 'setNakedRedDoltNum',
        type: 'pending',
        viewer: {
            content: 'approve_naked_viewer'
        },
        data: {
            auditStatus: ["PENDING"]
        }
    },
    {
        sys: 'NAKED',
        setRedDoltName: 'setNakedRedDoltNum',
        type: 'filling',
        viewer: {
            content: 'approve_naked_viewer'
        },
        data:

            {
                auditStatus: ["FILLING"]
            }
    }, {
        sys: 'REPORT',
        setRedDoltName: 'setReportRedDoltNum',
        viewer: {
            content: 'approve_report_viewer'
        },
        data:
            {
                approvalStatus: ["PENDING"]
            },
    },
]
