import {getDataInLocalStorage, removeDataInLocalStorage, saveDataToLocalStorage} from "@/utils/localStorage";

const state = {
    user:{},//登录的所有信息
    token:'',//token
    role:'',//角色
}
const getters = {
    getToken: (state) => {
        if (!state.token) {
            state.token = getDataInLocalStorage('token')
        }
        return state.token
    }  ,
    getUser: (state) => {
        if (!state.user||!state.user.size) {
            state.user =JSON.parse(getDataInLocalStorage('user'))
        }
        return state.user
    } ,
    getRole: (state) => {
        if (!state.role) {
            state.role =getDataInLocalStorage('role')
        }
        return state.role
    } ,

}
const mutations = {
    setToken: (state, token) => {
        saveDataToLocalStorage('token', token)
        state.token = token
    },
    setUser: (state, user) => {
        saveDataToLocalStorage('user', user)
        state.user = user
    },
    setRole:(state, role) => {
        saveDataToLocalStorage('role', role)
        state.role = role
    },
    setInit(state){
        removeDataInLocalStorage('user')
        removeDataInLocalStorage('token')
    }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
