import Vue from "vue";
import App from './App.vue'
import router from "@/router";
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/css/global.css'
import '@/plugins/index'
import  directive from './directive/index'
import cytoscape from 'cytoscape';
import wDesignUi from 'w-design-ui/w-design-ui.umd.min'
import 'w-design-ui/w-design-ui.css'
Vue.prototype.$cytoscape = cytoscape;
Vue.use(wDesignUi)
Vue.use(ElementUI)
Vue.use(Antd);
Vue.use(directive)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
