<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane name="pending">
        <template slot="label">
          <span>
           待确认<i v-if="tabRedDotNum('pending')" class="red-dot1">{{ tabRedDotNum('pending') }}</i>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane  name="filling">
        <template slot="label">
          <span>
           待修正<i v-if="tabRedDotNum('filling')" class="red-dot1">{{ tabRedDotNum('filling') }}</i>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="上传中" name="consented"></el-tab-pane>
      <el-tab-pane label="已失败" name="failed"></el-tab-pane>
      <el-tab-pane label="已成功" name="success"></el-tab-pane>
    </el-tabs>
    <div style="width:100%;">
      <w-table :columns="columns" :table-data="tableData"
               @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
               :page="page" @pageChange="handlePagePagination">
        <span slot="platform" slot-scope="{text,record}">
          {{ getPlatform(text) }}
        </span>
        <!--       折叠-->
        <div slot="foldInfo" slot-scope="{text, record, index}">
          <ex-fold-table-cell :scope="{text:text.split('|'), record, index}"></ex-fold-table-cell>
        </div>
        <!--        路径-->
        <div slot="dataPath" slot-scope="{text,record}">
          <div v-if="text">
            <el-tooltip content="查看详情">
              <a-icon type="file-search" style="color: #177bd3;font-size: 17px" @click="lookDataPath(record)"/>
            </el-tooltip>
            <span>{{ text.substring(0, 20) }}...</span>
          </div>
          <div v-else>
          </div>
        </div>
        <!--        备注-->
        <span slot="remark" slot-scope="{text,record}">
        <span v-if="text&&text.length>=20">
          {{ text.substring(0, 20) }}
          <el-tooltip :content="text">
            <span style="cursor: pointer">...</span>
          </el-tooltip>
        </span>
        <span v-if="!text||text.length<20">{{ text }}</span>
      </span>
        <!--        审批状态-->
        <span slot="approvalStatus" slot-scope="{text,record}">
          <el-tag :type="approvalStatusColor(text)">  {{
              getApprovalStatus(text)
            }}</el-tag>
        </span>
        <span slot="salesName" slot-scope="{text,record}">
            {{ text ? text.map(item => item.name).join(';') : '' }}
        </span>
        <span slot="customerName" slot-scope="{text,record}">
            {{ text ? Array.from(new Set(text.map(item => item.realName))).join(';') : '' }}
        </span>
        <span slot="approveMsg" slot-scope="{text, record}">
              <div class="descriptionTxt">{{ text }}</div>
        </span>
        <div slot="operation" slot-scope="{text,record}">
          <el-button size="mini" type="success" v-if="record.auditStatus==='PENDING'" @click="handleApprove(record)">
            审批
          </el-button>
          <el-button size="mini" type="primary" v-if="record.auditStatus==='FILLING'" @click="handleModify(record)">
            修改路径
          </el-button>
          <el-button size="mini" type="primary" v-if="record.auditStatus==='FAILED'" @click="handleFailLog(record)">
            查看失败日志
          </el-button>
        </div>
      </w-table>
    </div>
    <picture-loading v-if="loading"></picture-loading>
    <!--    审批弹框-->
    <approve-dialog v-if="showApproveDialog" :show-approve-dialog="showApproveDialog" :select-detail="selectDetail"
                    @submit="submitApprove">
      <template slot="resultInfo">
        <el-radio label="pass">路径正确</el-radio>
        <el-radio label="refuse">路径错误</el-radio>
      </template>
    </approve-dialog>
    <!--    报告路径弹框-->
    <data-path-dialog v-if="showDataPathDialog" :show-dialog.sync="showDataPathDialog" :select-detail="selectDetail"
                      @submit="submitDataPath">
    </data-path-dialog>
    <!--    审批结果弹框-->
    <approve-detail-dialog v-if="showApproveDetailDialog" :showDialog="showApproveDetailDialog"
                           :select-detail="selectDetail" @submit="closeApproveDetail"></approve-detail-dialog>
    <!--    失败日志信息-->
    <log-detail v-if="showFailLogDialog" :showDialog.sync="showFailLogDialog"
                :log-content="selectDetail"></log-detail>

  </div>
</template>

<script>
import {tableOperationMixin} from "@/mixins/tableOperation";
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import PictureLoading from "@/components/customerTable/PictureLoading";
import {approvalStatusColor, getApprovalStatusChByEn, getPlatformChByEn, getPlatformEnByCh} from "@/utils/ch_en_trans";
import ApproveDialog from "@/components/approve/ApproveDialog";
import ApproveDetailDialog from "@/components/approve/ApproveDetailDialog";
import ExFoldTableCell from "@/components/ExFoldTableCell";
import FileHostPathDialog from "@/components/FileHostPathDialog";
import {nakedApproval, nakedApprovalList, nakedApprovalModify} from "@/api/naked/rawData/approval";
import {nakedApprovalTableCol} from "@/utils/data/tableCol/nakedApprovalTableCol";
import LogDetail from "@/components/approve/LogDetail";
import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";
import DataPathDialog from "@/components/approve/DataPathDialog";
import {needRedDoltInfo} from "@/utils/data/basicData/leftNavData";

const baseURL = process.env.VUE_APP_BASE_API
export default {
  name: "NakedApproval",
  components: {
    DataPathDialog,
    LogDetail,
    ApproveDetailDialog,
    ApproveDialog,
    PictureLoading,
    ExFoldTableCell,
    FileHostPathDialog
  },
  mixins: [tableOperationMixin],
  data() {
    return {
      locale: zhCN,
      loading: true, //是显示加载
      title: '添加客户',
      searchConditionTag: [],//搜索tag
      sortConditionTag: '',
      tableData: [],
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      activeName: 'pending',
      showApproveDialog: false,//审批弹框
      showApproveDetailDialog: false,//审批结果弹框
      showFailLogDialog: false,//失败日志弹框
      showDataPathDialog: false,//路径弹框
      selectDetail: '',
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    columns: {
      get() {
        let operation = {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 300,
        }
        let reviewer = [{
          title: '审批人',
          dataIndex: 'auditInfo.name',
          key: 'auditName',
        },
          {
            title: '审批时间',
            dataIndex: 'operateTime',
            key: 'operateTime',
          }, {
            title: '审批理由',
            dataIndex: 'remark',
            key: 'remark',
            width: 300,
            scopedSlots: {
              customRender: 'approveMsg'
            }
          },]
        let columns = nakedApprovalTableCol(this.searchConditionTag || {})
        if (this.activeName !== 'pending') {
          columns.splice(columns.length, 0, ...reviewer)
          if (!['consented', 'success'].includes(this.activeName)) {
            columns.splice(columns.length, 0, operation)
          }
        } else {
          columns.splice(columns.length, 0, operation)
        }
        return columns
      }
    },
  },
  methods: {
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //获取数据
    getData(type) {
      this.loading = true
      let params = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize,
        order: this.activeName !== 'pending' ? 'createTime.desc' : 'createTime.asc'
      }
      if (type) {
        params['page.current'] = 1
        params['page.size'] = 10
      }
      let sendData = {}
      sendData.auditStatus = [this.activeName.toLocaleUpperCase()]
      sendData = this.getSearch(sendData)
      nakedApprovalList(params, sendData).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      }).catch(() => {
      })
    },
    getSearch(sendData) {
      this.searchConditionTag.forEach(item => {
        if (item.key === 'platform') {
          if (item.value !== '交付系统') {
            sendData[item.key] = getPlatformEnByCh(item.value)
          }
        } else {
          sendData[item.key] = item.value
        }
      })
      return sendData
    },
    //表格的选择框
    rowSelection(selectedRows) {
      this.ids = selectedRows.map(item => item.id)
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      if (page.pageSize) {
        this.page.pageSize = page.pageSize
      } else {
        this.page.pageSize = this.page.total
      }
      this.getData()
    },
    getPlatform(text) {
      return getPlatformChByEn(text)
    },
    getApprovalStatus(text) {
      return getApprovalStatusChByEn(text)
    },
    approvalStatusColor(text) {
      return approvalStatusColor[text]
    },
    handleClickTab(tab) {
      this.getData('change')
    },
    // 审批
    handleApprove(val) {
      this.selectDetail = val
      this.selectDetail.modifyPath = true
      this.showApproveDialog = true
    },
    //提交审批
    submitApprove(data, type) {
      this.showApproveDialog = false
      if (type !== 'cancel') {
        nakedApproval(data).then(res => {
          if (res.code === 200) {
              this.$store.dispatch(`dataStatus/getRedDoltNum`,  needRedDoltInfo[0])
            this.getData('search')
            this.$messageUi.success('审批成功')
          }
        })
      }
    },
    handleModify(record) {
      this.selectDetail = record
      this.showDataPathDialog = true
    },
    // 查看路径
    lookDataPath(record) {
      this.selectDetail = record
      this.showDataPathDialog = true
    },
    //修改路径
    submitDataPath(data) {
      nakedApprovalModify(data.id, data.dataPath).then(res => {
        if (res.code === 200) {
          this.showDataPathDialog = false
          this.$store.dispatch(`dataStatus/getRedDoltNum`,  needRedDoltInfo[1])
          this.getData()
          this.$messageUi.success('修改成功')
        }
      }).catch(() => {
        this.showDataPathDialog = false
      })
    },
    //打开审批结果详情
    handleApproveDetail(row) {
      this.selectDetail = row
      this.showApproveDetailDialog = true
    },
    //关闭审批结果详情
    closeApproveDetail() {
      this.showApproveDetailDialog = false
    },

    //查看失败日志
    handleFailLog(record) {
      this.selectDetail = record.logInfo.split('\n')
      this.showFailLogDialog = true
    },
    tabRedDotNum(key) {
      return this.$store.getters[`dataStatus/getNakedRedDoltNum`][key]
    },
  }
}
</script>

<style scoped>
.descriptionTxt {
  width: 200px;
  word-wrap: break-word;
  /*padding: 2px !important;*/
  white-space: pre-wrap;
}
.red-dot1{
  font-family: Consolas, Courier New, monospace;
  top: 3px;
  right:8px;
}
</style>
