<template>
  <div>
    <div style="margin-bottom: 20px;margin-top: 30px">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
      <el-button size="small" type="danger" icon="el-icon-delete" @click="handleDelete()">批量删除</el-button>
    </div>
    <slot name="note">
      <div  style="color: #8f8f93;font-size: 15px;margin-bottom: 20px">
        注：审批人上传的项目将会自动审批通过
      </div>
    </slot>
    <div style="width:100%;">
      <w-table :columns="columns" :table-data="tableData" @rowSelection="rowSelection"
               @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
               :page="page" @pageChange="handlePagePagination" :isShowRowSelection="true">
        <div slot="personType" slot-scope="{text,record}">
          {{ getPersonType(text) }}
        </div>
        <div slot="operation" slot-scope="{text,record}">
          <el-button size="mini" type="danger" @click="handleDelete(record)">删除</el-button>
        </div>
      </w-table>
      <!-- 添加界面 -->
      <el-dialog :title="title" :visible.sync="editFormVisible" width="45%" :destroy-on-close="true">
        <el-form label-width="80px" ref="editForm" :model="editForm" :rules="rules">
          <el-form-item label="姓名" prop="realName">
            <Cascader @getSelectedOptions='getSelectedOptions'/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click='closeDialog("edit")'>取消</el-button>
          <el-button size="small" type="primary" class="title" @click="submitForm('editForm')">保存
          </el-button>
        </div>
      </el-dialog>
    </div>
    <picture-loading v-if="loading"></picture-loading>

  </div>
</template>

<script>
import PictureLoading from "@/components/customerTable/PictureLoading";
import Cascader from "@/components/Cascader";
import {getRoleChByEn} from "@/utils/ch_en_trans";
import {apprUserTabCol} from "@/utils/data/tableCol/approveUserTableCol";
import {addApproveUser, delApproveUser, listApproveUser} from "@/api/sharerCustomer";

export default {
  name: "ApproveUser",
  props:['model'],
  components: {PictureLoading, Cascader},
  data() {
    return {
      loading: true, //是显示加载
      title: '添加审批人',
      searchConditionTag: [],//搜索tag
      sortConditionTag: [],
      tableData: [],
      ids: [],
      selectedOptions: [],//添加的审批人
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      editFormVisible: false, //控制编辑页面显示与隐藏
      // 编辑与添加
      editForm: {
        id: '',
        realName: '',
        emailAccount: '',
        phoneNumber: '',
      },
      rules: {
        realName: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        emailAccount: [
          {required: true, message: '请输入邮箱账号', trigger: 'blur'}
        ],
        phoneNumber: [
          {message: '请输入电话号码', trigger: 'blur'}
        ],
      },
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    columns() {
      return JSON.parse(JSON.stringify(apprUserTabCol(this.searchConditionTag || {})))
    }
  },
  methods: {
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //获取数据
    getData(type) {
      let params = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize
      }
      if (type) {
        params['page.current'] = 1
        params['page.size'] = 10
      }
      let sendData = {}
      sendData = this.getSearch(sendData)
      listApproveUser(params, sendData,this.model).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      }).catch(() => {
      })
    },
    getSearch(sendData) {
      this.searchConditionTag.forEach(item => {
        sendData[item.key] = item.value
      })
      return sendData
    },
    //表格的选择框
    rowSelection(selectedRows) {
      this.ids = selectedRows.map(item => item.id)
    },
    getPersonType(val) {
      let peronType = []
      val.forEach(item => {
        peronType.push(getRoleChByEn(item))
      })
      return peronType.join(';')
    },
    //编辑
    handleEdit(row) {
      this.editFormVisible = true
      this.title = '添加审批人'
      this.editForm.realName = ''
      this.editForm.emailAccount = ''
      this.editForm.phoneNumber = ''
      this.editFormVisible = true
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      if (page.pageSize) {
        this.page.pageSize = page.pageSize
      } else {
        this.page.pageSize = this.page.total
      }
      this.getData('changePage')
    },
    // 关闭编辑、增加、批量添加弹出框
    closeDialog() {
      this.editFormVisible = false
    },
    // 编辑、添加提交方法
    submitForm() {
      if (this.selectedOptions.length > 0) {
        addApproveUser(this.selectedOptions,this.model).then(res => {
          if (res.code === 200) {
            this.editFormVisible = false
            this.getData()
            this.$messageUi.success('添加成功')
          }
        }).catch(err => {
          this.$messageUi.error('添加失败，请稍后再试！')
        }).finally(() => {
          this.editFormVisible = false
        })
      } else {
        this.$messageUi.error('请选择您要添加的审批人')
      }
    },
    //删除
    handleDelete(row) {
      let ids = []
      if (row && row.id) {
        ids.push(row.id)
      } else {
        if (this.ids.length > 0) {
          ids = this.ids
        } else {
          this.$messageUi.error('请勾选要批量删除的数据')
          return
        }
      }
      this.$confirmUi('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除
        delApproveUser(ids.join(','),this.model)
            .then(res => {
              if (res.code === 200) {
                this.$messageUi({
                  type: 'success',
                  message: '删除成功!'
                })
                this.ids = []
                const totalPage = Math.ceil((this.page.total - 1) / this.page.pageSize)
                this.page.currentNum = this.page.currentNum > totalPage ? totalPage : this.page.currentNum
                this.page.currentNum = this.page.currentNum < 1 ? 1 : this.page.currentNum
                this.getData()
              } else {
                this.$messageUi({
                  type: 'error',
                  message: res.msg
                })
              }
            })
            .catch(err => {
              this.$messageUi.error('数据删除失败，请稍后再试！')
            })
      })
          .catch(() => {
          })
    },
    getSelectedOptions(val) {
      this.selectedOptions = val.map(item => item[1])
    }
  }
}
</script>

<style scoped>

</style>
