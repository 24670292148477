import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";

export const nakedApprovalTableCol = (filterInfo) => {
    filterInfo = changeFilteredInfo(filterInfo)
    return [{
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    }, {
        title: '模块',
        dataIndex: 'projectInfo.projectModel',
        key: 'projectModel',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdown',
            filtered: true,
        },
        filteredValue: filterInfo.projectModel || [],
    }, {
        title: '合同号',
        dataIndex: 'projectInfo.contractNo',
        key: 'contractNo',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdown',
            filtered: true,
            customRender: 'foldInfo'
        },
        filteredValue: filterInfo.contractNo || [],
    }, {
        title: '类型',
        dataIndex: 'projectInfo.projectType',
        key: 'projectType',
        scopedSlots: {
            filterIcon: 'filterIcon',
            filterDropdown: 'filterDropdown',
            filtered: true,
            customRender: 'foldInfo'
        },
        filteredValue: filterInfo.projectType || [],
    }, {
        title: '合同数据量',
        dataIndex: 'projectInfo.contractNum',
        key: 'contractNum',
    },
        {
            title: '销售',
            dataIndex: 'projectInfo.salesList',
            key: 'salesName',
            scopedSlots: {
                filterIcon: 'filterIcon',
                filterDropdown: 'filterDropdown',
                filtered: true,
                customRender: 'salesName'
            },
            filteredValue: filterInfo.salesName || [],
        }, {
            title: '客户',
            dataIndex: 'projectInfo.customerList',
            key: 'customerName',
            scopedSlots: {
                filterIcon: 'filterIcon',
                filterDropdown: 'filterDropdown',
                filtered: true,
                customRender: 'customerName'
            },
            filteredValue: filterInfo.customerName || [],
        },
        {
            title: '发起时间',
            dataIndex: 'createTime',
            key: 'createTime',
        }, {
            title: '原始数据路径',
            dataIndex: 'dataPath',
            key: 'dataPath',
            scopedSlots: {
                customRender: 'dataPath'
            }
        },
        {
            title: '状态',
            dataIndex: 'auditStatus',
            key: 'auditStatus',
            scopedSlots: {
                customRender: 'approvalStatus'
            }
        }, {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
            scopedSlots: {
                customRender: 'remark'
            }
        },

    ]
}
