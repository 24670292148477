import {metaboApi} from "@/axios";

// 查询项目共享人列表
export function listProjectSharerMetabo(projectId) {
    return metaboApi({
        url: '/project/share/list/'+projectId,
        method: 'get',
    })
}
// 删除项目共享人
export function delProjectSharerMetabo(data) {
    return metaboApi({
        url: '/project/share/delete',
        method: 'post',
        data:data
    })
}
export function addProjectSharerMetabo(data){
    return metaboApi({
        url:'/project/share/add',
        method:'post',
        data:data
    })
}
