import {deliveryApi} from "@/axios";
//通用api
//登录
export function login(data) {
    return deliveryApi({
        url: '/login',
        method: 'post',
        data: data
    })
}
//登出
export function logout() {
    return deliveryApi({
        url: '/logout',
        method: 'get',
    })
}
//获取验证码
export function sendCode(data) {
    return deliveryApi({
        url: '/sendCode',
        method: 'post',
        data: data
    })
}

//图形验证码
export function getCaptImage() {
    return deliveryApi({
        url: '/captchaImage',
        method: 'get',
        headers: {'Content-type': 'image/png'}
    })
}
//系统版本号
export function getSysUiVersion(){
    return deliveryApi({
        url: '/version/delivery',
        method: 'get',
    })
}
