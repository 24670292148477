const dataManager = "delivery";
const keyPrefix = dataManager + "_"
export const saveDataToLocalStorage = (key, value) => {
    if (!key || !value) {
        return
    }
    localStorage.setItem(AddFix(key), value)
}

export const getDataInLocalStorage = (key) => {
    if (!key) {
        return ''
    }
    var value = localStorage.getItem(AddFix(key))
    if (!value) {
        return ''
    }
    return value
}
export const removeDataInLocalStorage = (key) => {
    localStorage.removeItem(AddFix(key))
}
export const AddFix = (value) => {
    value = keyPrefix + value
    return value

}
