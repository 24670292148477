<template>
  <div>
    <div style="margin-bottom: 20px;margin-top: 30px">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
      <el-button size="small" type="danger" @click="handleDelete">批量删除</el-button>
    </div>
    <w-table :columns="columns" :table-data="tableData" @rowSelection="rowSelection" :is-show-row-selection="true"
                       @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
                       :page="page" @pageChange="handlePagePagination">
      <span slot="protect" slot-scope="{text, record}">{{ text ? '是' : '否' }}</span>
      <div slot="operation" slot-scope="{text, record}">
        <el-button
            size="mini"
            @click="handleEdit(record)">编辑
        </el-button>
        <el-button
            size="mini"
            type="danger"
            @click="handleDelete(record)">删除
        </el-button>
      </div>
    </w-table>
      <!-- 编辑界面 -->
      <el-dialog :title="title" :visible.sync="editFormVisible" width="500px" @click='closeDialog("edit")'>
        <el-form label-width="80px" ref="editForm" :model="editForm" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input size="small" v-model="editForm.name" auto-complete="off" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="配置键" prop="confKey">
            <el-input size="small" v-model="editForm.confKey" placeholder="请输入配置键"></el-input>
          </el-form-item>
          <el-form-item label="配置值" prop="confValue">
            <el-input size="small" type="textarea"
                      :rows="5" v-model="editForm.confValue" placeholder="请输入配置值"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click='closeDialog("edit")'>取消</el-button>
          <el-button size="small" type="primary" class="title" @click="submitForm('editForm')">保存
          </el-button>
        </div>
      </el-dialog>
    <picture-loading v-if="loading"></picture-loading>
  </div>
</template>

<script>
import {configAdd, configData, configDelete, configEdit} from "@/api/dilivery/config";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import PictureLoading from "@/components/customerTable/PictureLoading";
import {columnsData} from "@/utils/data/tableCol/sysTableCol";

export default {
  name: "Configuration",
  components: {PictureLoading},
  data() {
    return {
      locale: zhCN,
      loading: false, //是显示加载
      tableData: [],
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      ids:[],
      title: '添加',
      editFormVisible: false,
      editForm: {
        name: '',
        confKey: '',
        confValue: '',
        protect: 'false'
      },
      rules: {},
      searchConditionTag: [],//搜索tag
      sortConditionTag: [],
    }
  },
  computed: {
    columns() {
      return JSON.parse(JSON.stringify(columnsData(this.searchConditionTag || {})))
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(type) {
      this.loading=true
      let params = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize
      }
      if (type) {
        params['page.current'] = 1
        params['page.size'] = 10
      }
      let sendData = {}
      sendData = this.getSearch(sendData)
      configData(params,sendData).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      }).catch(() => {
      })
    },
    /**
     * 通过组件传递过来的
     * @param searchConditionTag 搜索条件
     * @param sortConditionTag 排序条件
     */
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    getSearch(sendData) {
      this.searchConditionTag.forEach(item => {
        sendData[item.key] = item.value
      })
      return sendData
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      this.page.pageSize = page.pageSize
      this.getData()
    },
    //表格的选择框
    rowSelection(selectedRows) {
      this.ids = selectedRows.map(item => item.id)
    },
    //编辑
    handleEdit(row) {
      if (row !== undefined && row !== 'undefined') {
        this.title = '编辑'
        this.editForm.id = row.id
        this.editForm.name = row.name
        this.editForm.confKey = row.confKey
        this.editForm.confValue = row.confValue
        this.editForm.project = row.project
      } else {
        this.title = '添加'
        this.editForm.id = ''
        this.editForm.name = ''
        this.editForm.confKey = ''
        this.editForm.confValue = ''
        this.editForm.project = ''
      }
      this.editFormVisible = true
    },
    //删除
    handleDelete(row) {
      let ids = []
      if (row.id) {
        ids.push(row.id)
      } else {
        if (this.ids.length > 0) {
          ids = this.ids
        } else {
          this.$messageUi.error('请勾选要批量删除的数据')
          return
        }
      }
      this.$confirmUi('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            // 删除
            configDelete(ids)
                .then(res => {
                  if (res.code === 200) {
                    this.$messageUi({
                      type: 'success',
                      message: '删除成功!'
                    })
                    this.ids = []
                    const totalPage = Math.ceil((this.page.total - 1) / this.page.pageSize)
                    this.page.currentNum = this.page.currentNum > totalPage ? totalPage :  this.page.currentNum
                    this.page.currentNum =  this.page.currentNum < 1 ? 1 :  this.page.currentNum
                    this.getData()
                  } else {
                    this.$messageUi({
                      type: 'error',
                      message: res.msg
                    })
                  }
                })
                .catch(err => {
                  this.$messageUi.error('数据删除失败，请稍后再试！')
                })
          })
          .catch(() => {
          })
    },
    closeDialog(dialog) {
      //单个添加或者编辑
      if (dialog === 'edit') {
        this.editFormVisible = false
      }
    },
    // 编辑、添加提交方法
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          // 请求方法
          if (this.title === '添加') {
            configAdd(this.editForm)
                .then(res => {
                  this.editFormVisible = false
                  if (res.code === 200) {
                    this.getData()
                    this.$messageUi.success('添加成功')
                  }
                })
                .catch(err => {
                  this.editFormVisible = false
                  this.$messageUi.error('添加失败，请稍后再试！')
                })
          } else {
            configEdit(this.editForm)
                .then(res => {
                  this.editFormVisible = false
                  if (res.code === 200) {
                    this.getData(true)
                    this.$messageUi({
                      type: 'success',
                      message: '修改成功！'
                    })
                  }
                })
                .catch(err => {
                  this.editFormVisible = false
                  this.$messageUi.error('修改失败，请稍后再试！')
                })
          }
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
