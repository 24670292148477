import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import {getDataInLocalStorage} from "@/utils/localStorage";
import Home from "@/views/home/Home";
import OverView from "@/views/systems/OverView";
import Info from "@/views/systems/Info";
import Customer from "@/views/systems/Customer";
import Email from "@/views/systems/Email";
import Configuration from "@/views/systems/Configuration";
import RawDataProject from "@/views/systems/project/rawData/rawDataProject";
import MetagProject from "@/views/systems/project/metag/MetagProject";
import MetaboProject from "@/views/systems/project/metabo/MetaboProject";
import BZProject from "@/views/systems/project/bz/BZProject";
import {logout} from "@/api/dilivery";
import store from "@/store";
import ApproveIndex from "@/views/systems/report/ApproveIndex";
import ApproveReport from "@/views/systems/report/ApproveReport";
import Test from "@/test/Test";
import NakedIndex from "@/views/systems/naked/NakedIndex";
import NakedRawData from "@/views/systems/naked/NakedRawData";
import NakedUser from "@/views/systems/naked/NakedUser";
import NakedApproval from "@/views/systems/naked/NakedApproval";
import TransProject from "@/views/systems/project/trans/TransProject";
import ApproveReportUser from "@/views/systems/report/ApproveReportUser";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        children: [{
            path: '/index',
            name: '项目信息总览',
            component: OverView
        }, {
            path: '/projectRawData',
            name: '项目交付系统',
            component: RawDataProject
        }, {
            path: '/projectBz',
            name: '多样性交付系统',
            component: BZProject
        }, {
            path: '/projectMetag',
            name: '宏基因组交付系统',
            component: MetagProject
        },{
            path: '/projectTrans',
            name: '转录组交付系统',
            component: TransProject
        },{
            path: '/projectMetabo',
            name: '代谢交付系统',
            component: MetaboProject
        },{
            path: '/info',
            name: '个人中心',
            component: Info
        },{
            path: '/customer',
            name: '客户管理',
            component: Customer
        },{
            path: '/email',
            name: '邮件管理',
            component: Email
        },{
            path: '/config',
            name: '系统配置',
            component:Configuration
        },{
            path: '/approve',
            name: '审批',
            component: ApproveIndex,
           children:[
               {
                   path: '/approveUser',
                   name: '审批/审批人员管理',
                   component:ApproveReportUser
               }, {
                   path: '/approveReport',
                   name: '报告审批管理',
                   component:ApproveReport
               }
           ]
        },{
            path: '/naked',
            name: '裸测-原始数据',
            component: NakedIndex,
           children:[
               {
                   path: '/nakedUser',
                   name: '裸测-原始数据/审批人员管理',
                   component:NakedUser
               }, {
                   path: '/nakedApproval',
                   name: '裸测-原始数据/审批管理',
                   component: NakedApproval
               }, {
                   path: '/nakedRawData',
                   name: '裸测-原始数据/原始数据',
                   component:NakedRawData
               }
           ]
        },
        ],
    },
    {
        path: '/login',
        name: Login,
        component: Login
    },  {
        path: '/test',
        name: Test,
        component: Test
    },
]
Vue.use(VueRouter)
const router = new VueRouter({
    routes,
    mode: 'history',
});
// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
// 路由全局前置守卫
router.beforeEach((to, from, next) => {
    // 在路由跳转前执行的逻辑
    const hasToken = getDataInLocalStorage('token')
    if (to.path === '/login') {
        logout().then(res => {
            if (res.code === 200) {
                store.commit('right/setInit')
            }
        })
        next()
    } else {
        if (hasToken) {
            if (to.path !== "/" && to.path !== "/login") { //判断是否要跳到登录界面
                next();
            } else {
                next({
                    path: '/index'
                })
            }
        } else {
            next('/login')
            // next()
        }
    }
});
router.afterEach((to, from) => {
    // 在每次路由跳转完成后清空 Vuex 状态
    if(to.path==='/login'){
        store.commit('dataStatus/setInit');
    }

});
export default router
