<template>
  <div>
    <!-- 主体部分 -->
    <template>
      <w-table ref="wMyTable" :columns="columns" :table-data="projectData"
                         @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
                         :page="page" @pageChange="handlePagePagination">
        <span slot="projectId" slot-scope="{text,record}">
          <a @click="getProjectInfo(text)">{{ text }}</a>
        </span>
        <span slot="customerName" slot-scope="{text,record}">
            {{ text ? Array.from(new Set(text.map(item => item.realName))).join(';') : '' }}
          </span>
        <span slot="salesName" slot-scope="{text,record}">
            {{ text ? text.map(item => item.name).join(';') : '' }}
        </span>
        <span slot="projectSign" slot-scope="{text,record}">
          宏基因组-{{ text }}
        </span>
        <!--       折叠-->
        <div slot="foldInfo" slot-scope="{text, record, index}">
          <ex-fold-table-cell :scope="{text:text.split('|'), record, index}"></ex-fold-table-cell>
        </div>
        <!--        报告审批状态-->
        <span slot="passApproval" slot-scope="{text,record}" :style="text?'color:#66c03b':'color:#de9930' ">
        {{ text ? '通过' : '待审批' }}
      </span>
        <div slot="customNote" slot-scope="{text, record}">
          <div style="display: flex;align-items: center">
            <el-tooltip content="修改备注">
              <i class="el-icon-edit" style="color: green;cursor: pointer" v-has-permission="'project_edit'"
                 @click="handleShowUpdateDialog(record,'note')"></i>
            </el-tooltip>
            <div>
                <span v-html="computerNote(text)">
                </span>
              <el-tooltip content="显示更多信息">
                <span v-if="text?text.length>30:false" @click="handleShowUpdateDialog(record,'note')"
                      style="font-size: 11px;cursor: pointer">......</span>
              </el-tooltip>
            </div>
          </div>
        </div>

        <div slot="rawDataStatus" slot-scope="{text, record}">
          <span>
            <el-tag style="width: 80px;height: 28px;text-align: center;padding-top: 4px" round size="mini" effect="dark"
                    :type="getRawStatusColor(text)">{{ getRawStatus(text) }}</el-tag>
          </span>
        </div>
        <div slot="operation" slot-scope="{text, record}">
          <el-button type="warning" size="mini" v-has-permission="'project_changer_user'" @click="handleChange(record)">
            更改用户
          </el-button>
          <el-popconfirm
              confirm-button-text='同意释放'
              cancel-button-text='取消'
              icon-color="#d07b7b"
              title="你同意释放该项目的原始数据吗？"
              @confirm="releaseRowData(record)">
            <el-button v-if="record.projectsRow.rawDataStatus==='NOT_RELEASED'&&record.soleKeyList.length===0"
                       v-has-permission="'project_raw_release'"
                       type="success" size="mini"
                       slot="reference" style="margin-left: 10px">释放数据
            </el-button>
          </el-popconfirm>
          <el-button v-has-permission="'projects_share'" style="margin-left: 10px"
                     size="mini"
                     type="primary"
                     @click="handleShare(record)">分享
          </el-button>
          <el-button style="margin-left: 10px" type="danger" size="mini" v-has-permission="'project_del'"
                     @click="handleDeleteProject(record)">删除
          </el-button>
        </div>
        <div slot="filterProjectType" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
             style="padding: 8px; position: relative; z-index: 9999 !important;">
          <div style="padding: 0px 15px">
            <el-radio-group v-model="selectedKeys[0]">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-radio label="宏基因组-normal">宏基因组-normal</el-radio>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-radio label="宏基因组-binning">宏基因组-binning</el-radio>
                </el-col>
              </el-row>
            </el-radio-group>
          </div>
          <div style="display: flex;padding: 15px">
            <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
            </a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
            </a-button>
          </div>
        </div>
        <div slot="filterRawDataStatus" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
             style="padding: 8px; position: relative; z-index: 9999 !important;">
          <div style="padding: 0px 15px">
            <el-radio-group v-model="selectedKeys[0]">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-radio label="无">无</el-radio>
                </el-col>
                <el-col :span="12">
                  <el-radio label="未释放">未释放</el-radio>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-radio label="链接释放">链接释放</el-radio>
                </el-col>
                <el-col :span="12">
                  <el-radio label="硬盘释放">硬盘释放</el-radio>
                </el-col>
              </el-row>
            </el-radio-group>
          </div>
          <div style="display: flex;padding: 15px">
            <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
            </a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
            </a-button>
          </div>
        </div>

      </w-table>
    </template>
    <el-dialog :destroy-on-close="true"
               title="编辑信息"
               :visible.sync="showUpdateDialog"
               width="500px">
      <el-form :model="updateProjectData" ref="updateProjectInfoForm" label-width="70px">
        <el-form-item label="备注" v-if="showEditNote">
          <el-input type="textarea" v-model="updateProjectData.note" maxlength="100"
                    placeholder="请输入备注内容" show-word-limit rows="4" @input="limitLength"></el-input>
          <span style="color: red" v-if="exceedMax">字数已达上限</span>
        </el-form-item>
        <el-form-item label="类型" v-else>
          <el-input v-model="updateProjectData.category" placeholder="请输入类型"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
              <el-button @click="showUpdateDialog = false">取 消</el-button>
              <el-button type="primary" @click="updateProject">确 定</el-button>
            </span>
    </el-dialog>
    <!--    图片，加载中-->
    <picture-loading v-if="loadingProject"></picture-loading>
    <!--    xx新建中-->
    <LoadingDialog v-if="isShowAddLoading" :showDialog="isShowAddLoading"
                   :dialogMessage="showAddLoadingMess"></LoadingDialog>
    <!--    更换用户-->
    <change-users v-if="showUserDialog" :show-dialog="showUserDialog" :select-detail="updateProjectUserData"
                  @submit="submitProjectUser"></change-users>
    <!--    分享管理-->
    <share-customer-manager-dialog v-if="showShareCusDialog" :select-detail="selectDetail" platform="METAG"
                                   :show-dialog="showShareCusDialog"
                                   @submit="closeShareCusDialog"></share-customer-manager-dialog>
  </div>
</template>


<script>
import {addT} from '@/utils/utils'
import Upload from '@/components/Upload'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import PictureLoading from "@/components/customerTable/PictureLoading";
import LoadingDialog from "@/components/LoadingDialog";
import {tableOperationMixin} from "@/mixins/tableOperation";
import {projectExport, projectChangeUser, projectList, projectRelease, projectDel, projectEdit} from "@/api/metag";
import ChangeUsers from "@/components/ChangeUsers";
import {columnsData} from "@/utils/data/tableCol/metagTableCol";
import ExFoldTableCell from "@/components/ExFoldTableCell";
import ShareCustomerManagerDialog from "@/components/ShareCustomerManagerDialog";
import {getRawDataStatusChByEn, getRawDataStatusEnByCh, rawDataStatusColor} from "@/utils/ch_en_trans";
import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";

const baseURL = process.env.VUE_APP_BASE_API
export default {
  components: {
    ShareCustomerManagerDialog,
    LoadingDialog,
    PictureLoading,
    'upload': Upload,
    ChangeUsers,
    ExFoldTableCell
  },
  mixins: [tableOperationMixin],
  data() {
    return {
      searchConditionTag: [],//搜索tag
      sortConditionTag: [],
      addProjectDialogVisible: false,//新增项目
      isShowAddLoading: false,//新建项目中的弹框
      showAddLoadingMess: '',//新建项目中的信息
      showEditNote: false,//修改备注信息弹框
      showUserDialog: false,//更换用户弹框
      exceedMax: false,//字数达上限
      showUpdateDialog: false,
      showShareCusDialog: false,//分享客户管理
      locale: zhCN,
      updateProjectData: {  //编辑项目信息
        projectId: '',
        category: '',
        note: '',
      },
      updateProjectUserData: {//更换用户
        projectId: '',
        selectedSalers: [],
        selectedAnalyze: '',
      },
      downloadUrl: '../',
      loadingProject: true,
      addProjectLock: false,
      page: {
        total: 0,
        pageSize: 8,
        currentNum: 1
      },
      projectData: [],
      selectDetail: '',
    }
  },
  computed: {
    columns() {
      let column = JSON.parse(JSON.stringify(columnsData(  changeFilteredInfo(this.searchConditionTag || {}))))
      if (['ANALYST', 'TECH'].includes(this.$store.getters["right/getRole"])) {
        column.splice(column.length - 1, 1)
      } else {
        if (this.$store.getters["right/getRole"] === 'USER') {
          column.splice(column.length - 4, 1)
        }
      }
      console.log(column)
      return column
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    //获取项目列表
    async getData(type) {
      this.loadingProject = true
      var sendData = {
        page: {
          current: this.page.currentNum,
          size: this.page.pageSize,
        }
      }
      if (type) {
        sendData.page.current = 1
        sendData.page.size = 10
      }
      let length = this.searchConditionTag.length
      if (length) {
        sendData.condition = {}
        this.searchConditionTag.forEach(item => {
          let key = item.key
          if (key === 'createTime') {
            let value = item.value
            let index = value.indexOf('至')
            let value1 = value.substring(0, index)
            let value2 = value.substring(index + 1, value.length)
            sendData.condition.createTime = [addT(value1), addT(value2)]
          } else if(key === 'projectSign') {
            sendData.condition.projectSign=item.value.includes('normal')?'normal':'binning'

          }else if (key === 'rawDataStatus') {
            sendData.condition.rawDataStatus = getRawDataStatusEnByCh(item.value)
          } else {
            sendData.condition[key] = item.value
          }
        })
      } else {
        sendData.condition = {}
      }
      sendData.sorts = this.sortConditionTag
      projectList(sendData).then(res => {
        if (res.code === 200) {
          this.loadingProject = false
          var pageData = res.data
          this.projectData = pageData.data
          this.page.total = pageData.totalNum
          this.page.pageSize = pageData.pageSize
          this.page.currentNum = pageData.currentNum
        }
      })

    },
    //获取tag的搜索排序的内容
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      if (page.pageSize) {
        this.page.pageSize = page.pageSize
      } else {
        this.page.pageSize = this.page.total
      }
      this.getData()
    },
    //根据备注的长度调整展示的内容
    computerNote(text) {
      if (text) {
        if (text.length <= 30) {
          return text
        } else {
          return text.substring(0, 30)
        }
      } else {
        return ''
      }
    },
    //备注框的长度限制
    limitLength(value) {//value 为获取到的输入框的值
      this.exceedMax = value.length === 100
    },
    //获取原始数据状态
    getRawStatus(status) {
      return getRawDataStatusChByEn(status)
    },
    //获取原始数据状态tag颜色
    getRawStatusColor(status) {
      return rawDataStatusColor[status]
    },
    // 提交释放原始数据申请
    releaseRowData(record) {
      const projectId = record.projectsRow.projectId
      projectRelease(projectId).then(res => {
        if (res.code === 200) {
          this.$message.success("释放成功")
          this.getData()
        }
      })
    },
    //点击删除项目
    async handleDeleteProject(record) {
      const projectId = record.projectsRow.projectId
      this.$confirmUi('此操作将会永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        projectDel(projectId).then(res => {
          if (res.code === 200) {
            this.$message.success("删除成功")
            this.avoidPageByDel()
            this.getData()
          }
        })
      }).catch(() => {
      })
    },
    //防止删除时该页面只有一条数据，删除后页面不回上一页
    avoidPageByDel() {
      this.$message.success("删除成功")
      const totalPage = Math.ceil((this.page.total - 1) / this.page.pageSize)
      this.page.currentNum = this.page.currentNum > totalPage ? totalPage : this.page.currentNum
      this.page.currentNum = this.page.currentNum < 1 ? 1 : this.page.currentNum
    },
    /** 更换用户按钮操作 */
    handleChange(row) {
      this.updateProjectUserData.selectedSalers = row.salerList.map(item => {
        return item.uuid[0]
      })
      this.updateProjectUserData.selectedAnalyze = row.analyst ? row.analyst.uuid[0] : ''
      this.updateProjectUserData.projectId = row.projectsRow.projectId
      this.showUserDialog = true
    },
    //提交更换用户的信息
    submitProjectUser(data, type) {
      this.showUserDialog = false
      if (type !== 'cancel') {
        let sendData = {
          "projectId": data.projectId,
          "analystId": data.selectedAnalyze,
          "salesIds": data.selectedSalers,
          "customerIds": []
        }
        projectChangeUser(sendData).then(res => {
          if (res) {
            if (res.code === 200) {
              this.$message.success('修改成功')
              this.getData()
            }
          }
        })
      }
    },
    //点击修改项目类型
    handleShowUpdateDialog(project, type) {
      project = project.projectsRow
      this.updateProjectData.projectId = project.projectId
      this.updateProjectData.note = project.note
      this.updateProjectData.category = project.category
      this.showEditNote = type === 'note';
      this.showUpdateDialog = true
    },
    //提交项目信息的修改
    async updateProject() {
      const res = await projectEdit(this.updateProjectData)
      this.showUpdateDialog = false
      if (res) {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.getData()
        } else {
          this.$message.error('修改失败')
        }
      }
    },
    //跳转到具体的项目
    getProjectInfo(projectId) {
      window.open('/metagWeb/desc?projectId=' + projectId)
    },
    //分享项目
    handleShare(row) {
      this.selectDetail = {
        projectId: row.projectsRow.projectId,
        customerList: row.customerList
      }
      this.showShareCusDialog = true
    },
    //关闭分享
    closeShareCusDialog() {
      this.showShareCusDialog = false
    },

    //mixin
    addLoading() {
      this.loadingProject = true
    },
    updatePageDataBySearch() {
      this.page.currentNum = 1
      this.getData('search')
    },
    updatePageData() {
      this.getData('search')
    }
  }
}
</script>

<style scoped>
::v-deep .ant-table-empty .ant-table-body {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

::v-deep .ant-table-body {
  overflow-x: auto !important;
}

::v-deep.ant-table td {
  white-space: nowrap;
}

</style>

