<!--xxx加载中-->
<template>
  <el-dialog
    :append-to-body="true"
    title="提示"
    :visible.sync="showDialog"
    width="400px"
    height="200px"
    :showClose="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    top="30vh"
  >
    <div >{{dialogMessage}}<img src="images/running2.gif"></div>
  </el-dialog>
</template>

<script>
export default {
  name: "LoadingDialog",
  props:{
    showDialog:{
      type:Boolean ,
      default: false
    },
    dialogMessage:{
      type: String,
      default: ""
    }
  },
  data(){
    return{

    }
  }
}
</script>

<style scoped>
.el-form-item__label {
  font-family: "Microsoft YaHei";
}

/deep/ .el-dialog__header {
  background-color: #b3b7ee !important;
}
</style>
