import {rawDataApi} from '@/axios'
// 查询项目列表
export function listProject(params,data) {
    return rawDataApi({
        url: '/raw/project/page',
        method: 'post',
        params:params,
        data:data
    })
}

// 查询项目详细
export function getProject(id) {
    return rawDataApi({
        url: '/raw/project/' + id,
        method: 'get'
    })
}
// 修改项目
export function updateProject(data) {
    return rawDataApi({
        url: '/raw/project',
        method: 'put',
        data: data
    })
}

// 删除项目
export function delProject(id) {
    return rawDataApi({
        url: '/raw/project/' + id,
        method: 'delete'
    })
}
// 更改项目的用户
export function changeProjectUser(data) {
    return rawDataApi({
        url: '/raw/project/personnel',
        method: 'put',
        data:data
    })
}
//结果文件与项目绑定
export function addProjectFile(data,id){
    return rawDataApi({
        url: '/raw/project/'+id,
        method: 'post',
        data:data
    })
}
export function auditData(id){
    return rawDataApi({
        url: '/raw/project/audit/'+id,
        method: 'get',
    })
}
// 最后10条
export function projectLast_10_raw(){
    return rawDataApi({
        url: '/raw/project/last-10',
        method: 'get',
    })
}

