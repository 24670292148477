import {metaboApi} from '@/axios'
// 查询项目列表
export function projectList(sendData) {
    return metaboApi({
        url: '/project/page',
        method: 'post',
        data: sendData
    })
}
// 编辑项目信息（类型+备注）
export function projectEdit(data) {
    return metaboApi({
        url: '/project',
        method: 'put',
        data:data
    })
}
// / 更改项目的用户
export function projectChangeUser(data) {
    return metaboApi({
        url: '/project/editUsers',
        method: 'put',
        data:data
    })
}
// 删除项目
export function projectDel(id) {
    return metaboApi({
        url: '/project/' + id,
        method: 'delete'
    })
}

// 释放原始数据
export function projectRelease(id) {
    return metaboApi({
        url: '/project/verifyRawData/'+id,
        method: 'get',
    })
}
// 最后10条
export function projectLast_10_metabo(){
    return metaboApi({
        url: '/project/last-10',
        method: 'get',
    })
}
