<template>
  <el-dialog :destroy-on-close="true"
             title="报告路径"
             :visible.sync="showDialog"
             :before-close="handle"
             width="500px">
    <el-form :model="selectDetail" label-width="120px">
      <el-form-item label="服务器主机">
        <el-input v-model="selectDetail.hostName" readonly></el-input>
      </el-form-item>
      <el-form-item label="具体路径">
        <el-input v-model="selectDetail.dirPath" type="textarea" :rows="4" readonly></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="handle">关 闭</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "FileHostPathDialog",
  props:['showDialog','selectDetail'],
  methods:{
    handle(){
      this.$emit('submit')

    }
  }
}
</script>

<style scoped>

</style>
