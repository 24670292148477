<template>
  <div class="cascaderArea">
    <el-cascader
        :props="props"
        v-model="selectedOptions"
        placeholder="请选择"
        @change="handleChange"
        style="width: 300px"
    ></el-cascader>
  </div>
</template>
<script>
import {userList} from '@/api/dilivery/user'
import {ch_en_role} from "@/utils/ch_en_trans";

export default {
  name: 'Cascader',
  data() {
    return {
      selectedOptions: [],
      props: {
        lazy: true,
        multiple: true,
        lazyLoad: (node, resolve) => {
          const {level} = node;
          let stop_level = 2; //设置需要几级数据，
          // list 初始数据，我的是写死的，你们可以根据你们的实际情况去修改，
          let list = ch_en_role
          // 超过最大层级，停止向下加载
          let callback = () => {
            resolve(
                (list || []).map((ele) => {
                  return {
                    value: ele.en,
                    label: ele.ch,
                    leaf: level >= stop_level,
                  }
                })
            )
          };
          if (level === 1) {
            const {value} = node;
            let user = []
            this.getUserByRole(value).then(res => {
              res.data.records.map((item) => {
                user.push({
                  value: item.uuid,
                  label: item.name,
                  leaf: node.level >= 1,
                })
              });
              resolve(user)
            })
          } else {
            callback();
          }
        },
      }
    }
  },
  methods: {
    getUserByRole(role) { // 获取省市区街道
      if (role === '0') {
        return new Promise(resolve => {
          resolve([
            {
              ch: '销售',
              en: 'SALES'
            }, {
              ch: '分析',
              en: 'ANALYST'
            }, {
              ch: '审核',
              en: 'AUDITOR'
            }, {
              ch: '技术支持',
              en: 'TECH'
            }])
        })
      }
      return userList(role, {'page.current': 1, 'page.size': 200}, {})
    },
    handleChange(value) { // 选择的行政区
      this.$emit('getSelectedOptions', value)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
