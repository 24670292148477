
import {transApi} from "@/axios";

// 查询项目共享人列表
export function listProjectSharerTrans(projectId) {
    return transApi({
        url: '/project/share/list/'+projectId,
        method: 'get',
    })
}
// 删除项目共享人
export function delProjectSharerTrans(data) {
    return transApi({
        url: '/project/share/delete',
        method: 'post',
        data:data
    })
}
export function addProjectSharerTrans(data){
    return transApi({
        url:'/project/share/add',
        method:'post',
        data:data
    })
}
