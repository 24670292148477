/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router'
import {Message, MessageBox} from "element-ui";
import {getDataInLocalStorage, removeDataInLocalStorage} from "@/utils/localStorage";
import {isVersion} from "@/utils/utils";
import {modelInfo} from "@/utils/data/basicData/projectData";

let tokenExpiredStatus = false;
const common = {
    timeout: 0//不超时
}
// 登录过期提示
function tokenExpPrompt(){
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        router.push('/login')
    }).catch(() => {
        tokenExpiredStatus = false
    });
}
// 添加拦截器的响应处理
const responseHandler = response => {
    const {code} = {...response.data}
    let version = response.headers['delivery-client-version']
    if (version) {
        isVersion(version)
    }
    if (response.status === 200) {
        if (code === 200) {
            if (response.config.url.includes("/login")) {
                return Promise.resolve(response); //进行中
            } else {
                return Promise.resolve(response.data); //进行中
            }
        } else {
            if (code === 401) {
                removeDataInLocalStorage('token')
                if (!tokenExpiredStatus) {
                    tokenExpiredStatus = true
                    tokenExpPrompt()
                }
                return {
                    code: ''
                }
            }
            Message.error(response.data.msg)
            return Promise.resolve({code: ''}); //进行中
        }
    } else {
        return Promise.resolve({code: ''}); //失败
    }
}
// 服务器状态码不是200的情况
const responseHandlerError = error => {
    // 对响应错误做点什么
    let {message} = error;
    if (message === "Request failed with status code 401") {
        if (!tokenExpiredStatus) {
            tokenExpiredStatus = true
            tokenExpPrompt()
        }
    } else if (message === "Request failed with status code 500") {
        Message.error('服务器后端异常，请稍后重试')
    } else {
        Message.error('网络异常，请稍后重试')
    }
}
const handleRequest = request => {
    request.headers.Authorization = getDataInLocalStorage('token')
    return request;
}
const handleRequestError = error => {
    return Promise.error(error);
}
let platApi={}
modelInfo.forEach(item=>{
    platApi[item.api.name]=axios.create(common)
    platApi[item.api.name].defaults.baseURL = item.api.url
    platApi[item.api.name].interceptors.request.use(handleRequest, handleRequestError)
    platApi[item.api.name].interceptors.response.use(responseHandler, responseHandlerError)
})

export const {deliveryApi, rawDataApi, metagApi, bzApi,nakedApi,transApi,metaboApi}= platApi
