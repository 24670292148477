import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";

export const columnsData = (filteredInfos) => {
    let filteredInfo = changeFilteredInfo(filteredInfos)
    return [{
        title: '项目号',
        dataIndex: 'id',
        key: 'id',
        scopedSlots: {
            customRender: 'projectId'
        },
    }, {
        title: '合同号',
        dataIndex: 'contractNumber',
        key: 'contractNumber',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            filtered: true,
            customRender: 'foldInfo'
        },
        filteredValue: filteredInfo.contractNumber || [],
    },
        {
            title: '类型',
            dataIndex: 'projectType',
            key: 'projectType',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'foldInfo'
            },
            filteredValue: filteredInfo.projectType || [],
        },
        {
            title: '样本数量',
            dataIndex: 'sampleNumber',
            key: 'sampleNumber',
        }, {
            title: '销售姓名',
            dataIndex: 'salesList',
            key: 'salesName',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'salesName'
            },
            filteredValue: filteredInfo.salesName || [],
        }, {
            title: '客户姓名',
            dataIndex: 'customerList',
            key: 'customerName',
            scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                filtered: true,
                customRender: 'customerName'
            },
            filteredValue: filteredInfo.customerName || [],
        }, {
            title: '分析员姓名',
            dataIndex: 'creator.name',
            key: 'creator',
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            sortDirections: ["descend", "ascend", "descend"],
        },
        {
            title: '报告审批状态',
            dataIndex: 'passApproval',
            key: 'passApproval',
            scopedSlots: {
                customRender: 'passApproval'
            },
        },
        {
            title: '原始数据状态',
            dataIndex: 'rawDataStatus',
            key: 'rawDataStatus',
            sortDirections: ["descend", "ascend", "descend"],
            scopedSlots: {
                filterDropdown: 'filterRawDataStatus',
                filterIcon: 'filterIcon',
                customRender: 'rawDataStatus',
            },
            filteredValue: filteredInfo.rawDataStatus || [],
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 200,
            scopedSlots: {
                customRender: 'remark'
            },

        },
        {
            title: '操作',
            width: 200,
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
        }
    ]
}
