<template>
  <div>
    <!--    v-bind="$attrs" v-on="$listeners"-->
    <el-dialog :visible.sync="open" width="850px" append-to-body :destroy-on-close="true" title="查看项目详情"
               ref="detail" @close="close">
      <el-row :gutter="15">
        <el-form ref="elForm" :model="formData" size="medium" label-width="100px">
          <el-form-item label="项目识别码" prop="soleKey">
            <el-input v-model="formData.soleKey" placeholder="请输入项目识别码"/>
          </el-form-item>
          <el-col :span="12">
            <el-form-item label="项目类型">
              <el-input v-model="formData.projectType" placeholder="无" :maxlength="11" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同号">
              <el-input v-model="formData.contractNumber" placeholder="无" :maxlength="11" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分析员">
              <el-input v-model="formData.creator.name" placeholder="无" :maxlength="11" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="样本数量">
              <el-input v-model="formData.sampleNumber" placeholder="无" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目主管邮箱">
              <el-input v-model="formData.leaderEmail" placeholder="无" :maxlength="11" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上传时间">
              <el-input v-model="formData.createTime" placeholder="无" :maxlength="11" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
          <el-col :span="12">
            <el-form-item label="结题报告">
              <el-input v-model="formData.reportPath" placeholder="无" :maxlength="11" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原始数据状态">
              <el-input :value="getRawStatus(formData.rawDataStatus)" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据释放时间">
              <el-input :value="formData.auditTime === null ? '无' : formData.auditTime.substring(0, 10)"
                        placeholder="无" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="提取码">
              <el-input v-model="formData.linkCode" placeholder="无" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="点击数">
              <el-input v-model="formData.linkHit" placeholder="无" :maxlength="11" readonly
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <div v-for="(item,index) in formData.salesList" :key="index+'sale'">
            <el-col :span="12">
              <el-form-item :label="'销售'+(index+1)+'姓名'">
                <el-input v-model="item.name" placeholder="无" :maxlength="11" readonly
                          :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="'销售'+(index+1)+'邮箱'">
                <el-input v-model="item.email" placeholder="无" :maxlength="11" readonly
                          :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
          </div>
          <div v-for="(item,index) in formData.customerList" :key="index+'customer'">
            <el-col :span="12">
              <el-form-item :label="'客户'+(index+1)+'姓名'">
                <el-input v-model="item.realName" placeholder="无" readonly
                          :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="'客户'+(index+1)+'邮箱'">
                <el-input v-model="item.emailAccount" placeholder="无" :maxlength="11" readonly
                          :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
            <div style="width: 100%">
              <el-col :span="12">
                <el-form-item :label="'客户'+(index+1)+'单位'">
                  <el-input v-model="item.unit" placeholder="无" :maxlength="11" readonly
                            :style="{width: '100%'}"></el-input>
                </el-form-item>
              </el-col>
              <br/>
              <el-col :span="18">
                <span></span>
              </el-col>
            </div>
          </div>
          <el-col :span="24">
            <el-form-item label="原始数据链接" v-if="formData.rawDataStatus!=='NO_DATA'">
              <el-input v-model="formData.rawLink" type="textarea" placeholder="无" readonly
                        :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
            </el-form-item>
<!--            <el-form-item label="原始数据" v-if="formData.rawDataStatus==='NO_DATA'">-->
<!--              <el-input value="未上传" type="textarea" placeholder="无" readonly-->
<!--                        :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>-->
<!--            </el-form-item>-->
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input v-model="formData.remark" type="textarea" placeholder="无" readonly
                        :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getRawDataStatusChByEn} from "@/utils/ch_en_trans";

export default {
  inheritAttrs: false,
  props: ['formData'],
  data() {
    return {
      open: true
    }
  },
  methods: {
    close() {
      this.$emit("closeDetail")
    },
    //获取原始数据状态
    getRawStatus(status) {
      return getRawDataStatusChByEn(status)
    },
  }
}

</script>
<style>
</style>
