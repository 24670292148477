<template>
  <el-container class="container">
    <el-aside class="aside" style="width: auto">
      <LeftNav></LeftNav>
    </el-aside>
    <el-container>
      <el-header class="header">
        <Header></Header>
      </el-header>
      <el-main class="main">
        <div class="main-div">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import LeftNav from "@/components/LeftNav";
import Header from "@/components/Header";
import {copyText} from "@/utils/windowsUtils";

export default {
  name: "Home",
  components: {Header, LeftNav},
  mounted() {
    var userAgent = navigator.userAgent.toLowerCase();//判断是否在微信中打开
    if (userAgent.indexOf('micromessenger') !== -1) {
      this.$confirm(`用微信内置的浏览器打开网页可能会出现样式错位，影响操作效果。建议用其他浏览器打开。`, '提示', {
        confirmButtonText: `点击复制网页地址`,
        showCancelButton: false,
        closeOnClickModal: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
        // 关键
        copyText(window.location.href)
      })
    }
  },
}
</script>

<style scoped>
.aside {
  height: 100vh;
}

.header {
  z-index: 99;
  padding: 0px;
}

.main {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 60px);
  margin: auto;
}

.container {
  height: 100vh; /* 设置容器高度为视口高度 */
  overflow: auto; /* 隐藏容器的溢出内容 */
}
</style>
