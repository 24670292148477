// import {rawDataApi} from "@/axios";

export const modelInfo = [

    {
        name: '交付系统',
        webUrl: '/',
        api:{
            name:'deliveryApi',
            url:'/delivery_api',
        },
        isOverview:false,
    }, {
        name: '项目交付系统',
        webUrl: '/projectRawData',
        api:{
            name:'rawDataApi',
            url:'/raw/prod-api',
        },
        isOverview:true,
    }, {
        name: '多样性交付系统',
        webUrl: '/projectBz',
        api:{
            name:'bzApi',
            url:'/bz_api',
        },
        isOverview:true,
    }, {
        name: '宏基因组系统',
        webUrl: '/projectMetag',
        api:{
            name:'metagApi',
            url:'/metag_api',
        },
        isOverview:true,
    },
    {
        name: '转录组系统',
        webUrl:'/projectTrans',
        api:{
            name:'transApi',
            url:'/trans_api',
        },
        isOverview:true,
    }
    , {
        name: '代谢组系统',
        webUrl: '/projectMetabo',
        api:{
            name:'metaboApi',
            url:'/metabo_api',
        },
        isOverview:true,
    },{
        name: '裸测-原始数据',
        webUrl:'/nakedRawData',
        api:{
            name:'nakedApi',
            url:'/naked_api',
        },
        isOverview:true,
    }
]
