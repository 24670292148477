import {deliveryApi, rawDataApi} from "@/axios";
// 获取上传信息
export function getTask(data){
    return deliveryApi({
        url: '/upload/getTask',
        method: 'post',
        data: data,
    })
}
// 分片上传
export function sliceUpload(file,md5,pointer){
    return deliveryApi ({
        url: '/upload/slice/'+md5+'/'+pointer,
        method: 'post',
        data:file
    })

}
// 确认上传
export function confirmUpload(md5,fileLength){
    return deliveryApi({
        url: '/upload/confirm-upload/'+md5+'/'+fileLength,
        method: 'get',
    })

}
