<template>
  <div>
    <el-card>
      <div class="headerDiv">
        <div style="display: flex">
          <div style="margin-right: 20px">
            <el-tooltip content="折叠菜单栏" v-if="showLeftMenu">
              <i class="el-icon-s-fold foldIcon" @click="changeLeftNav"></i>
            </el-tooltip>
            <el-tooltip content="展开菜单栏" v-else>
              <i class="el-icon-s-unfold foldIcon" @click="changeLeftNav"></i>
            </el-tooltip>
          </div>
<!--          <el-tag size="small" style="margin-left: 20px">{{ currentNav }}</el-tag>-->
          <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px">
            <el-breadcrumb-item v-for="item in currentNav"> <el-tag size="small">{{ item }}</el-tag></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">{{ user.realName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user"  command="info" v-if="user.type==='CUSTOMER'">个人中心</el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" command="exit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-card>
  </div>
</template>

<script>

import {getDataInLocalStorage} from "@/utils/localStorage";
import {logout} from "@/api/dilivery";

export default {
  name: "Header",
  data() {
    return {
      user: '',
      isCollapse: false

    }
  },
  computed: {
    showLeftMenu() {
      return this.$store.getters['dataStatus/getShowLeftMenu']
    },
    currentNav() {
      return this.$route.name.split('/')
    }
  },
  created() {
    this.user = JSON.parse(getDataInLocalStorage('user'))
  },
  methods: {
    changeLeftNav() {
      this.$store.commit('dataStatus/setShowLeftMenu')
    },
    handleCommand(command) {
      if (command === 'info') {
        this.$router.push('/info')
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped>
.headerDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  padding: 0px;
}

.el-dropdown-link {
  color: #707583;
  /*margin-right: 30px;*/
  cursor: pointer;
}

.foldIcon {
  cursor: pointer;
  font-size: 23px;
  color: #b4b5b6
}
</style>
