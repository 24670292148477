<template>
  <div v-loading="loading">
    <div style="display: flex;flex-wrap: wrap;gap:50px">
      <el-card style="width:330px;" v-for="(project,index) in projectData" v-if="projectSys[index].isOverview">
        <div style="display: flex;align-items: center;justify-content: space-between">
          <div
              style="font-weight: bolder;color: #333434;  border-left: 5px solid #0060be;line-height: 15px;height: 15px">
            <h3 style="margin-left: 10px">{{ projectSys[index].name }}</h3>
          </div>
          <a v-if="project&&project.length===10" style="font-size: 14px;" @click="toProject(projectSys[index])">查看更多></a>
        </div>
        <div v-if="project&&project.length>0" style="font-size: 14px;margin-top: 15px">
          <li class="projectItem" v-for="item in project">
            <span @click="getProjectInfo(item,projectSys[index])">
              {{getCon(projectSys[index], item)}}
            </span>
          </li>
        </div>
        <div v-else style="text-align: center;margin-top: 50px;color: #9b9b9f">
          暂无项目......
        </div>
      </el-card>
    </div>
    <Vx></Vx>
    <el-divider></el-divider>
    <div class="footer">
      <div style="display: flex;justify-content: space-between">
        <p><i class="el-icon-phone"></i> 联系方式：021-31021022；13032137192</p>
        <p><i class="el-icon-link"></i> 凌波微课：
          <el-link :underline="false" href="http://www.biomicroclass.com/" target="_blank">
            http://www.biomicroclass.com/
          </el-link>
        </p>
        <p><i class="el-icon-s-promotion"></i> 公司官网：
          <el-link :underline="false" href="http://www.biozeron.com/" target="_blank">http://www.biozeron.com/</el-link>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import {getDataInLocalStorage} from "@/utils/localStorage";
import Vx from "@/components/overView/Vx";
import {getProjectLast_10, projectLast_10_raw} from "@/api/rawData";
import {projectLast_10_bz} from "@/api/bz";
import {projectLast_10_metag} from "@/api/metag";
import {modelInfo} from "@/utils/data/basicData/projectData";
import {projectLast_10_trans} from "@/api/trans";
import {projectLast_10_naked} from "@/api/naked/rawData/project";
import {projectLast_10_metabo} from "@/api/metabo";

export default {
  name: "OverView",
  components: {
    Vx
  },
  data() {
    return {
      loading: true,
      userData: '',
      projectData: [],
      projectSys:modelInfo.filter(item=>item.isOverview)
    }
  },
  created() {
    this.userData = JSON.parse(getDataInLocalStorage('user'))
    if (!this.userData.initialized) {
      this.$confirmUi('该账号还未设置密码, 是否立即设置密码?', '提示', {
        confirmButtonText: '设置密码',
        cancelButtonText: '暂不',
        type: 'warning'
      }).then(() => {
        this.$router.push('/info')
      }).catch(() => {
      });
    }
  },
  mounted() {
    this.getProjectLast_10()
  },
  methods: {
    //最后10条
    getProjectLast_10() {
      let raw = projectLast_10_raw()
      let bz = projectLast_10_bz()
      let metag = projectLast_10_metag()
      let trans = projectLast_10_trans()
      let metabo = projectLast_10_metabo()
      let naked = projectLast_10_naked()
      Promise.all([raw, bz, metag,trans,metabo,naked]).then(allRes => {
        allRes.forEach(item => {
          this.projectData.push(item.data)
          this.loading = false
        })
      })
    },
    getProjectInfo(item, sys) {
      let url = ''
      switch (sys.webUrl) {
        case '/projectRawData':
          url = '/raw/data/info?id=' + item.id;
          break
        case '/projectBz':
          url = '/bzWeb/desc?projectId=' + item.projectId;
          break
        case '/projectMetag':
          url = '/metagWeb/desc?projectId=' + item.projectId;
          break
        case '/projectTrans':
          url = '/transWeb/desc?projectId=' + item.projectId;
          break
        case '/projectMetabo':
          url = '/metaboWeb/desc?projectId=' + item.projectId;
          break
        case '/nakedRawData':
          url = '/nakedRawData?id=' + item.id;
          break
      }
      if (url) {
        window.open(url)
      }
    },
    getCon(project, item) {
      if (project.name === '宏基因组系统') {
        return `${item.contractNumber} ---宏基因组 - ${item.projectSign}`
      } else {
        return `${item.contractNumber?item.contractNumber:item.contractNo} --- ${item.projectType}`
      }
    },
    toProject(sys) {
      this.$router.push(sys.webUrl)
    }

  }
}
</script>

<style scoped>
.projectItem {
  cursor: pointer;
  color: #58595b;
  margin-bottom: 3px;

}

.projectItem::before {
  color: rgb(148, 148, 155);
  content: '◆ ';
  width: 6px;
  margin-right: 6px;
}

.projectItem:hover {
  color: #177bd3;
}
</style>
