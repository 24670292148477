<template>
  <div>
    <el-dialog :title="selectDetail.fileName+'文件下载记录'"
               :visible.sync="showDialog" width="800px"
               :before-close="handleClose" :append-to-body="true">

      <el-table size="small" :data="selectDetail.downloadRecord"  >
        <el-table-column align="center" prop="ipAddress" label="IP地址" width="250"></el-table-column>
        <el-table-column align="center" label="地址" prop="location" width="250"></el-table-column>
        <el-table-column align="center" label="下载时间" prop="createTime" width="250"></el-table-column>
      </el-table>
      <div style="display: flex;justify-content: flex-end;margin-top: 15px;color: rgba(20,51,152,0.91);">共 计：
        {{ selectDetail.downloadTimes }} 条记录</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "downTimeRecordDialog",
  props:['showDialog','selectDetail'],
  methods:{
    // 取消
    handleClose() {
      this.$emit('update:showDialog')
    },
  }
}
</script>

<style scoped>
.el-table{
  max-height: 700px;
  overflow-y: auto;
  font-size: 13px;
}
</style>
