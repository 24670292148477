<template>
  <div style="width: 100%;height: 100%;">
   <div v-for="item in arr" style="width: 100%;height: 100%;" :key="item">
     <test1 ></test1>

   </div>
  </div>

</template>
<script>
import Test1 from "@/test/Test1";
export default {
  components: {Test1},
  data(){
    return{
      arr:[1,2,3]
    }
  }
}
</script>
