<template>
  <el-dialog :destroy-on-close="true"
             :title="title"
             :visible.sync="showDialog"
             :before-close="cancel"
             width="500px">
    <el-form :model="form" label-width="80px">
      <slot name="formInfo" v-bind="selectDetail">
      </slot>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button  @click="cancel">关 闭</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "LookInfoDialog",
  props: ['showDialog', 'selectDetail','title'],
  data() {
    return {
      form: {...this.selectDetail}
    }
  },
  methods: {
    cancel() {
      this.$emit('update:showDialog')
    },
  }
}
</script>

<style scoped>

</style>
