<template>
  <el-dialog title="审批" :visible.sync="showApproveDialog" width="500px" :destroy-on-close="true" :before-close="cancel">
    <el-form :model="approveForm" label-width="100px">
      <el-form-item v-if="selectDetail.modifyPath" label="原始数据路径">
        <el-input type="textarea" :value="selectDetail.dataPath"  :autosize="{ minRows: 2, }">
        </el-input>
      </el-form-item>
      <el-form-item label="审批结果" :required="true">
        <el-radio-group v-model="approveForm.result">
          <slot name="resultInfo">
            <el-radio label="pass">同意</el-radio>
            <el-radio label="refuse"> 不同意</el-radio>
          </slot>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="审批理由" >
        <el-input v-model="approveForm.msg" autocomplete="off" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submitApprove">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ApproveDialog",
  props:['showApproveDialog','selectDetail'],
  data(){
    return{
      approveForm:{
        id:this.selectDetail.id,
        result:'pass',
        msg:'',
      }
    }
  },
  methods:{
    submitApprove(){
      this.$emit('submit',this.approveForm,'submit')
    },
    cancel(){
      this.$emit('submit',this.approveForm,'cancel')
    }
  }
}
</script>

<style scoped>

</style>
