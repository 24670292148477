<template>
  <!-- 编辑、添加界面 -->
  <el-dialog :title="title" :visible.sync="showDialog" width="600px" :destroy-on-close="true"  :before-close="cancel">
    <el-form label-width="80px" ref="form" :model="form" :rules="rules">
      <el-form-item label="用户名" prop="username" v-if="selectType.role==='customer'">
        <div style="display: flex;flex-direction: column">
          <el-input size="small" v-model="form.username" auto-complete="off" @blur="addUserCus"
                    placeholder="请输入用户名"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="姓名" prop="realName">
        <el-input size="small" v-model="form.realName" auto-complete="off"
                  placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="电话号码" prop="phoneNumber">
        <el-input size="small" v-model="form.phoneNumber" auto-complete="off"
                  placeholder="请输入电话号码"></el-input>
      </el-form-item>
      <el-form-item label="邮箱账号" prop="emailAccount">
        <el-input size="small" v-model="form.emailAccount" auto-complete="off"
                  placeholder="请输入邮箱账号"></el-input>
      </el-form-item>
      <span v-if="selectType.role==='customer'"
          style="line-height: 1;padding-top:4px;font-size: 13px;color: #818080">注：用户名必须以_cus结尾</span>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button size="small" type="primary" class="title" @click="handle">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {checkMail} from "@/utils/rules";
import {userAdd, userEdit} from "@/api/dilivery/customer";
import {addProjectSharer} from "@/api/sharerCustomer";

export default {
  name: "AddCustomerDialog",
  props:['showDialog','selectType','selectDetail'],
  data(){
    return{
      title:'',
      form:{
        username: '',
        realName: '',
        emailAccount: '',
        phoneNumber: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        realName: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        emailAccount: [
          {required: true, message: '请输入邮箱账号', trigger: 'blur'},
          {validator: checkMail, trigger: 'blur'}
        ],
        phoneNumber: [
          {message: '请输入电话号码', trigger: 'blur'}
        ],
      },
    }
  },
  watch:{
   'selectType':{
     handler(newVal){
       if (newVal){
         if(newVal.type==='edit'){
           this.title='编辑客户信息'
         }else {
           if(newVal.role==='customer'){
             this.title='添加客户'
           }else {
             this.title='添加共享者'
           }
         }
       }
     },
     immediate:true
   },
    'selectDetail':{
     handler(newVal){
       if(newVal&&Object.keys(newVal)){
           this.form.id = newVal.id
           this.form.realName = newVal.realName
           this.form.username = newVal.username
           this.form.emailAccount = newVal.emailAccount
           this.form.phoneNumber = newVal.phoneNumber
       }
     },
      immediate:true
    },
    'form.emailAccount': {
      handler(newVal, oldVal) {
        if (oldVal && newVal && newVal !== oldVal) {
          this.form.username = newVal.substring(0, newVal.indexOf('@')).replaceAll("[^A-Za-z0-9]", "_") + '_cus'
        }
      },
    },
  },
  methods:{
    //为用户名自动加上_cus后缀
    addUserCus() {
      if (!this.form.username.endsWith('_cus')) {
        this.form.username = this.form.username + '_cus'
      }
    },
    cancel(){
      this.$emit('submit','cancel')
    },
    handle(){
      this.$refs.form.validate(valid=>{
        if(valid){
          if(this.selectType.role==='customer'){
                // 请求方法
                if (this.title === '添加客户') {
                  userAdd(this.form)
                      .then(res => {
                        if (res.code === 200) {
                          this.$emit('submit','confirm')
                          this.$messageUi.success('添加成功')
                        }
                      }).catch(err => {
                    this.$messageUi.error('添加失败，请稍后再试！')
                  })
                } else {
                  userEdit(this.form).then(res => {
                    if (res.code === 200) {
                      this.$emit('submit','confirm')
                      this.$messageUi.success('修改成功')
                    }
                  }).catch(err => {
                    this.$messageUi.error('修改失败，请稍后再试！')
                  })
                }
          }else {
            let sendData={
              projectId:this.selectDetail.projectId,
              shareCustomerList:Array.of({...this.form})
            }
            addProjectSharer(sendData,this.selectType.platform).then(res=>{
              if(res.code===200){
                this.$emit('submit','confirm')
                this.$messageUi.success('添加成功')
              }
            })
          }

        }else {
          this.$message.error('填写参数不规范，请检查')
        }
      })

    }
  },

}
</script>

<style scoped>

</style>
