import { render, staticRenderFns } from "./NakedIndex.vue?vue&type=template&id=dea09910&scoped=true"
import script from "./NakedIndex.vue?vue&type=script&lang=js"
export * from "./NakedIndex.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dea09910",
  null
  
)

export default component.exports