<template>
  <div>
    <el-dialog :destroy-on-close="true"
               title="下载原始数据"
               append-to-body
               :visible.sync="showDialog"
               :before-close="cancel"
               width="750px">
      <div style="display: flex;justify-content: space-between ;margin-bottom: 20px">
         <span style="color: #dc3131">
            失效时间：{{ this.downRawFilesInfo.expirationTime }}
        </span>
        <div style="display: flex;flex-direction:row-reverse;gap: 20px">
          <el-button type="success" size="small" @click="downFiles">批量下载</el-button>
          <el-dropdown type="primary" @command="($event) => handleCommand($event)">
            <el-button type="primary" size="small">下载md5<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="file">下载md5文件</el-dropdown-item>
              <el-dropdown-item command="tool">下载md5验证工具</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="info" size="small" @click="openCommand">命令行下载</el-button>
        </div>


      </div>
      <el-table
          :data="tableData.slice((page.currentNum - 1) * page.pageSize, page.currentNum * page.pageSize)"
          style="width: 100%">
        <el-table-column
            prop="fileName"
            :show-overflow-tooltip="true"
            label="文件名">
        </el-table-column>
        <el-table-column
            label="大小"
            width="180">
          <template slot-scope="scope">
            {{ getFileSize(scope.row.fileLength) }}
          </template>
        </el-table-column>
        <el-table-column
            label="总下载次数"
            width="180">
          <template slot-scope="scope">
           <span v-if="scope.row.downloadTimes">
             {{ scope.row.downloadTimes }}
             <el-tooltip content="查看下载次数详情">
             <i class="el-icon-info" style="color: #f89a3c;margin-left: 3px;cursor: pointer"
                @click="lookDownTimesDetail(scope.row)"></i>
           </el-tooltip>
           </span>
            <span v-else style="color: #da3132">NA</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="180">
          <template slot-scope="scope">
            <el-tooltip content="下载">
              <a><i class="el-icon-download" style="color: #1c84c6" @click="downFile(scope.row.url)"></i></a>
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-if="tableData.length>10"
          :total="tableData.length"
          :childMsg="page"
          @callFather="handlePagination"/>
      <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="cancel">关闭</el-button>
    </span>
    </el-dialog>
    <command-dialog v-if="showCommandDialog" :show-dialog.sync="showCommandDialog"
                    :select-detail="selectDetail"></command-dialog>
<!--    下载次数详情-->
    <down-time-record-dialog v-if="showDownTimeRecordDialog" :show-dialog.sync="showDownTimeRecordDialog"
                             :select-detail="selectDetail"></down-time-record-dialog>
  </div>
</template>

<script>
import '../../utils/protocolCheck/alter_noUrl'
import '../../utils/protocolCheck/protocolcheck'
import {renderSize} from "@/utils/utils";
import Pagination from "@/components/downRawData/Pagination";
import CommandDialog from "@/components/downRawData/CommandDialog";
import {getCommand} from '@/utils/utils'
import DownTimeRecordDialog from "@/components/downRawData/downTimeRecordDialog";

export default {
  name: 'Index',
  props: ['showDialog', 'downLoadUrl', 'downRawFilesInfo'],
  components: {DownTimeRecordDialog, CommandDialog, Pagination},
  data() {
    return {
      page: {
        total: this.downRawFilesInfo.rawDataList.length,
        currentNum: 1,
        pageSize: 10
      },
      showCommandDialog: false,
      showDownTimeRecordDialog: false,//下载次数详情弹框
      selectDetail: null,
      tableData: this.downRawFilesInfo.rawDataList,
    }
  },
  methods: {

    getFileSize(size) {
      return renderSize(size)

    },
    cancel() {
      this.$emit('update:showDialog')

    },
    handlePagination(page) {
      this.page.currentNum = page.currentNum //每次点击分页按钮，当前页发生变化
      this.page.pageSize = page.pageSize //每次点击分页按钮，当前页发生变化
    },
    downFile(url) {
      const elink = document.createElement('a')
      elink.href = url
      document.body.appendChild(elink)
      elink.click()
      document.body.removeChild(elink)

    },
    downFiles() {
      let url = `linenrawdownload://downloadUrl=` + this.downLoadUrl
      //判断本地是否能启动某个命令程序
      window.protocolCheck(url,
          function () {
            alert(`<div style="text-align: left;color: #5a5e66"><i class="el-icon-warning" style="color: #e4a13d;font-size: 16px;margin-right: 5px" ></i>检测到您未安装原始数据下载器，辛苦您下载安装后再批量下载原始数据。<br>下载地址：<a class="aLink"  href="http://58.247.16.28:63306/prod-api/client/downloader">http://58.247.16.28:63306/prod-api/client/downloader</a></div>`)
          })
    },
    handleCommand(command) {
      if (command === 'file') {
        this.downMd5()
      } else {
        window.open('https://pan.baidu.com/s/10fO47Zt18VPI_e0CT-EBXQ?pwd=nmu7', '_target')
      }
    },

    //下载md5
    downMd5() {
      // 生成要写入的文本内容
      let md5FileName = this.tableData.map(item => `${item.fileMd5}  ${item.fileName}`).join('\n');
      var blob = new Blob([md5FileName], {type: 'text/plain'})
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'md5文件'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

    },
    //命令行
    openCommand() {
      let command = ''
      this.tableData.forEach(item => {
        command = command ? command + '&&' + getCommand(item.fileName, item.url) : getCommand(item.fileName, item.url)
      })
      this.selectDetail = command
      this.showCommandDialog = true
    },
    //打开下载次数详情弹框
    lookDownTimesDetail(row) {
      this.selectDetail=row
      this.showDownTimeRecordDialog=true
    }
  }
}
</script>

<style scoped>

</style>

