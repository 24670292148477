<template>
  <el-dialog title="审批结果详情" :visible.sync="showDialog" width="600px" :destroy-on-close="true"
             :before-close="submit">
    <el-form :model="selectDetail" label-width="100px">
      <el-form-item label="审批结果">
        <div v-if="selectDetail.approvalStatus==='PASSED'" style="display: flex;align-items: center">
             <i class="el-icon-success" style="font-size:22px;color: #66c03b;margin-bottom:5px;;margin-right: 5px"></i>
          <label style="color: #9b9b9f;margin-bottom: 5px">同意</label>
          </div>
        <div v-else style="display: flex;align-items: center">
             <i class="el-icon-error" style="font-size:22px;margin-bottom:5px;color: #f36b6c;margin-right: 5px"></i>
          <label style="color: #9b9b9f;margin-bottom: 5px">拒绝</label>
          </div>
      </el-form-item>
      <el-form-item label="审批时间">
        <el-date-picker v-model="selectDetail.approvalTime" type="datetime" readonly></el-date-picker>
      </el-form-item>
      <el-form-item label="审批人">
        <el-input v-model="selectDetail.reviewerName" readonly></el-input>
      </el-form-item>
      <el-form-item label="审批理由">
        <el-input v-model="selectDetail.approvalRemark" autocomplete="off" type="textarea" readonly
                  :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">关 闭</el-button>
    </div>
  </el-dialog>

</template>

<script>

export default {
  name: "ApproveDetail",
  props: ['showDialog', 'selectDetail'],
  data() {
    return {}
  },
  methods: {
    submit() {
      this.$emit('submit')
    },
  }
}
</script>

<style scoped>

</style>
