import {getDataInLocalStorage, saveDataToLocalStorage} from "@/utils/localStorage";

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (('' + time).length === 10) time = parseInt(time) * 1000
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}
// 日期格式转换
export function DataConvert(d) {
    d = new Date(d)
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    if (hours < 0) hours = '0' + hours;
    if (min < 10) min = '0' + min;
    if (seconds < 10) seconds = '0' + seconds;
    return year+'-'+month+'-'+day+'\t'+hours+':'+min+':'+seconds;
}
// 加T
export function addT(d){
    let index=d.indexOf('\t')
    let d1=d.substring(0,index)
    let d2=(d.substring(index+1))
    return d1+'T'+d2
}

//判断是否为最新系统版本号
export function isVersion(sysUiVersion){
    const localVersion = getDataInLocalStorage('version');
    if (!localVersion || (localVersion && localVersion !== sysUiVersion)) {
        saveDataToLocalStorage('version', sysUiVersion);
        window.location.reload();
    }
}

// 计算文件的大小
export function renderSize(filesize){
    if(null==filesize||filesize==''){
        return "0 Bytes";
    }
    var unitArr = new Array("Bytes","KB","MB","GB","TB","PB","EB","ZB","YB");
    var index=0;
    var srcsize = parseFloat(filesize);
    index=Math.floor(Math.log(srcsize)/Math.log(1024));
    var size =srcsize/Math.pow(1024,index);
    size=size.toFixed(2);//保留的小数位数
    return size+unitArr[index];
}
//判断是否为最新系统版本号
export function isNewVersion() {
    const version = process.env.VUE_APP_VERSION;
    const localVersion = getDataInLocalStorage('version');
    if (localVersion && localVersion !== version) {
        saveDataToLocalStorage('version', version);
        window.location.reload();
    } else {
        if(!localVersion){
            saveDataToLocalStorage('version', version);
            window.location.reload();
        }
    }
}
//获取下载原始数据的命令行
export function  getCommand(name,link,){
    return `(curl -C - -f -o ${name} -H 'Command-Download:1' ${link} || curl -C - -H 'Command-Download:1' ${link})`
}
