<template>
  <div>
    <p style="color: #e74040;font-size: 15px;text-indent: 2em">邮件信息默认保存30天</p>

    <!--列表-->
    <w-table ref="wMyTable" :columns="columns" :table-data="tableData"
             @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
             :page="page" @pageChange="handlePagePagination">
      <span slot="mailType" slot-scope="{text, record}">
        <span>{{ getEmailType(text) }}</span>
      </span>
      <span slot="mailStatus" slot-scope="{text, record}">
        <span>{{ getEmailStatus(record) }}</span>
      </span>
      <!--       折叠-->
      <div slot="foldInfo" slot-scope="{text, record, index}">
        <ex-fold-table-cell :scope="{text:text.split('|'), record, index}"></ex-fold-table-cell>
      </div>
      <!--        收件邮箱-->
      <div slot="toMail" slot-scope="{text, record, index}">
        <ex-fold-table-cell :scope="{text, record, index}"></ex-fold-table-cell>
      </div>
      <!--          抄送邮箱-->
      <div slot="ccMail" slot-scope="{text, record,index}">
        <ex-fold-table-cell :scope="{text, record, index}"></ex-fold-table-cell>
      </div>
      <!--          //操作-->
      <div slot="operation" slot-scope="{text, record}">
        <el-button icon="el-icon-reading"
                   size="mini" type="text"
                   @click="handleLook(record)">查看邮件内容
        </el-button>
        <el-dropdown size="mini" style="margin-left: 5px" @command="(command) => handleCommand(command, record)">
          <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="handleChangeEmail" icon="el-icon-message">更换邮箱
            </el-dropdown-item>
            <el-dropdown-item command="handleFailLog" icon="el-icon-chat-line-square" v-if="record.failLog">失败日志
            </el-dropdown-item>
            <el-dropdown-item command="handleSendEmail" icon="el-icon-s-promotion">立即发送邮件
            </el-dropdown-item>
            <el-dropdown-item command="handleCancelEmail" icon="el-icon-video-pause"
                              v-if="record.mailStatus==='IN_QUEUE'||record.mailStatus==='CREATED'">取消发送邮件
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div slot="filterDropdownMailType"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
           style="padding: 8px;width:250px ">
        <div style="margin: 0px 15px;">
          <el-radio-group v-model="selectedKeys[0]">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="上传">上传</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="释放">释放</el-radio>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="催款">催款</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="超时">超时</el-radio>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="报告驳回">报告驳回</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="报告审批超时">报告审批超时</el-radio>
              </el-col>
            </el-row>
          </el-radio-group>
        </div>
        <div style="text-align: center;margin-top:5px">
          <a-button type="primary" icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">
            搜索
          </a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
          </a-button>
        </div>
      </div>

      <div slot="filterMailStatus" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
           style="padding: 8px; position: relative; z-index: 9999 !important;">
        <div style="padding: 0px 15px">
          <el-radio-group v-model="selectedKeys[0]">
            <div style="display: flex;justify-content: space-around">
              <el-radio label="已取消发送">已取消发送</el-radio>
              <el-radio label="未发送">未发送</el-radio>
            </div>
            <div style="margin-top:5px;display: flex;justify-content: space-around">
              <el-radio label="发送失败">发送失败</el-radio>
              <el-radio label="发送成功">发送成功</el-radio>
            </div>
          </el-radio-group>
        </div>
        <div style="display: flex;padding: 15px">
          <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
          </a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
          </a-button>
        </div>
      </div>
      <div slot="filterDropdownPlatform" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
           style="padding: 15px;width: 380px; position: relative; z-index: 9999 !important;">
        <div style="padding: 0px 15px">
          <el-radio-group v-model="selectedKeys[0]">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="交付系统"> 交付系统</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="项目交付系统">项目交付系统</el-radio>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="多样性交付系统">多样性交付系统</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="宏基因组交付系统">宏基因组交付系统</el-radio>
              </el-col>

            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="转录组交付系统">转录组交付系统</el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio label="裸测-原始数据交付系统">裸测-原始数据交付系统</el-radio>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-radio label="代谢组交付系统">代谢组交付系统</el-radio>
              </el-col>
            </el-row>
          </el-radio-group>
        </div>
        <div style="display: flex;padding: 15px;justify-content: center;gap: 10px">
          <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
          </a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
          </a-button>
        </div>
      </div>
    </w-table>
    <!--    //更改邮箱 -->
    <change-email v-if="showMailDialog" :show-dialog="showMailDialog" :email="selectMail"
                  @submitEmail="submitEmail"></change-email>
    <el-dialog
        :destroy-on-close="true"
        :title="emailDialogTitle"
        width="750px"
        :visible.sync="emailDialog">
      <div v-if="emailDialogTitle==='邮件内容'" v-html="emailDialogCon"></div>
      <div v-else>
        {{ emailDialogCon }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="emailDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <picture-loading v-if="loading"></picture-loading>
  </div>

</template>

<script>
import {tableOperationMixin} from "@/mixins/tableOperation";
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {cancelEmail, changeEmail, downloadAttachment, emailData, lookEmail, sendEmail} from "@/api/dilivery/email";
import ChangeEmail from "@/components/ChangeEmail";
import PictureLoading from "@/components/customerTable/PictureLoading";
import {
  getMailSendStatusChByEn,
  getMailSendStatusEnByCh,
  getMailTypeChByEn,
  getMailTypeEnByCh
} from "@/utils/ch_en_trans";
import ExFoldTableCell from "@/components/ExFoldTableCell";
import {columnsData} from "@/utils/data/tableCol/emailTableCol";

const baseURL = process.env.VUE_APP_BASE_API
export default {
  name: "Email",
  components: {ExFoldTableCell, ChangeEmail, PictureLoading},
  mixins: [tableOperationMixin],
  data() {
    return {
      locale: zhCN,
      loading: true,
      tableData: [],
      emailDialog: false,
      emailDialogCon: '',
      emailDialogTitle: '',
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      initialConditions: {},
      //更换邮件信息
      selectMail: [],
      showMailDialog: false,
      sortConditionTag: [],
      searchConditionTag: [],

    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    columns() {
      return JSON.parse(JSON.stringify(columnsData(this.searchConditionTag || {})))
    }
  },
  methods: {
    getData(isSearch) {
      this.loading = true
      var param = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize,
      }
      if (isSearch === 'search') {
        param['page.current'] = 1
        param['page.size'] = 10
      }
      let sendData = {}
      let length = this.searchConditionTag.length
      if (length) {
        this.searchConditionTag.forEach(item => {
          let key = item.key
          if (key === 'sendTime') {
            let value = item.value.split('至')
            sendData['sendTime'] = {
              min: value[0],
              max: value[1]
            }
          } else if (key === 'mailStatus') {
            sendData[key] = getMailSendStatusEnByCh(item.value)
          } else if (key === 'mailType') {
            sendData[key] = getMailTypeEnByCh(item.value)
          } else {
            sendData[key] = item.value
          }
        })
      }
      param.order = this.sortConditionTag.length > 0 ? `${this.sortConditionTag[0].colName}.${this.sortConditionTag[0].order}` : ''
      emailData(param, sendData).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.loading = false
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      })
    },
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      if (page.pageSize) {
        this.page.pageSize = page.pageSize
      } else {
        this.page.pageSize = this.page.total
      }
      this.getData()
    },
    //获取邮件的状态
    getEmailStatus(record) {
      return getMailSendStatusChByEn(record.mailStatus)
    },
    //获取邮件的类型
    getEmailType(mailType) {
      return getMailTypeChByEn(mailType)
    },
    //查看邮件内容
    handleLook(row) {
      this.emailDialogTitle = '邮件内容'
      lookEmail(row.id).then(res => {
        if (res.code === 200) {
          this.emailDialogCon = res.data
          this.emailDialog = true
        }
      }).catch(() => {

      })

    },
    handleCommand(command, row) {
      switch (command) {
        case "handleSendEmail":
          this.handleSendEmail(row);
          break;
        case "handleCancelEmail":
          this.handleCancelEmail(row);
          break;
        case "handleFailLog":
          this.handleFailLog(row);
          break;
        case "handleChangeEmail":
          this.handleChangeEmail(row);
          break;
        default:
          break;
      }
    },
    //修改邮件的发送状态
    handleSendEmail(row) {
      this.$confirmUi('此操作将立即发送该邮件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sendEmail(row.id).then(res => {
          if (res.code === 200) {
            this.$messageUi.success('邮件已立刻发送')
            this.getData();
          }
        })
      }).catch(() => {

      })
    },
    handleCancelEmail(row) {
      this.$confirmUi('此操作将取消发送该邮件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancelEmail(row.id).then(res => {
          if (res.code === 200) {
            this.$messageUi('已取消该邮件的发送')
            this.getData();
          }
        })
      })
    },
    //失败日志
    handleFailLog(row) {
      this.emailDialog = true
      this.emailDialogTitle = '失败日志'
      this.emailDialogCon = row.failLog
    },
    //更换收件箱和抄送邮箱
    handleChangeEmail(row) {
      this.showMailDialog = true
      this.selectMail = {
        ccMails: row.ccMails,
        toMails: row.toMails,
        id: row.id
      }
    },
    submitEmail(emails, type) {
      this.showMailDialog = false
      if (type !== 'cancel') {
        const sendData = {
          ccMails: emails.ccMails.split('\n'),
          toMails: emails.toMails.split('\n'),
          id: emails.id
        }
        changeEmail(sendData).then(res => {
          if (res.code === 200) {
            this.$messageUi.success('邮箱更换成功')
            this.getData()
          }
        }).catch(() => {

        })
      }

    },
  }
}
</script>

<style scoped>
::v-deep .ant-table-empty .ant-table-body {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.el-row {
  margin-bottom: 5px;
}

.iconMore {
  color: #4eaad4;
  cursor: pointer;
}

/deep/ .tableHeadNote p {
  margin-bottom: 0px !important;
}
</style>
