<!--
 分页组件
  -->
<template>
  <el-pagination class="page-box"
                 @size-change="handleSizeChange"
                 @current-change="handleCurrentChange"
                 background :current-page="childMsg.currentNum"
                 :page-sizes="[10,50,100]"
                 :page-size="childMsg.pageSize"
                 layout="total, sizes, prev, pager, next, jumper"
                 :total="childMsg.total">
  </el-pagination>
</template>
<script>
export default {
  name: 'Pagination',
  /**
   * childMsg: {
   *      currentNum
   *      pageSize
   *      total
   * }
   *
   * */
  props: ['childMsg'],
  data () {
    return {
      pageparm: {
        currentNum: this.childMsg.currentNum,
        pageSize: this.childMsg.pageSize
      }
    }
  },
  created () {
  },
  methods: {
    handleSizeChange (val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.pageSize = val
      this.$emit('callFather', this.pageparm)
    },
    handleCurrentChange (val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.currentNum = val
      this.$emit('callFather', this.pageparm)
    }
  }
}
</script>

<style scoped>
.page-box {
  margin: 10px auto;
}
</style>
