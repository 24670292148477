<template>
  <div class="app-container home" v-loading="loading">
<!--    <el-row :gutter="20">-->
<!--      <el-col :sm="12" :lg="12" style="padding-left: 50px">-->
<!--        <h2>凌恩生物项目交付系统</h2>-->
<!--        <b>当前版本:</b> <span>v{{ version }}</span>-->
<!--      </el-col>-->
<!--      <el-col :span="11">-->
<!--        <el-card class="update-log">-->
<!--          <div slot="header" class="clearfix">-->
<!--            <span>联系信息</span>-->
<!--          </div>-->
<!--          <div class="body">-->
<!--            <p>-->
<!--              <i class="el-icon-s-promotion"></i> 官网：-->
<!--              <el-link href="http://www.biozeron.com/" target="_blank">http://www.biozeron.com/</el-link>-->
<!--            </p>-->
<!--            <p>-->
<!--              <i class="el-icon-user-solid"></i> 联系方式：021-31021022；13032137192-->
<!--            </p>-->
<!--          </div>-->
<!--        </el-card>-->
<!--      </el-col>-->
<!--    </el-row>-->
    <el-divider/>
    <div class="wcContainer">
      <div v-for="(item ,index) in weiChatCon" :key="index" class="wcItem">
        <img :src=" item.thumb_url" alt="image" width="85%" @click="clickWeiChatLink(item.url)" referrerpolicy="no-referrer"
             :title="'点击了解更多：'+item.title" style="cursor: pointer">

        <p class="wcTitle">{{ item.title }}</p>
      </div>
    </div>

  </div>
</template>
<script>
import {weiChatCon} from "@/api/dilivery/vx";

export default {
  name: "Index",
  data() {
    return {
      // 版本号
      version: "1.0.0",
      weiChatCon: [],
      loading: true,
    };
  },
  created() {
    this.getWeiChatHref()
    // this.getVersion()
  },
  methods: {
    goTarget(href) {
      window.open(href, "_blank");
    },
    getWeiChatHref() {
      weiChatCon().then(res => {
        if(res.code===200){
          this.weiChatCon = res.data
          this.loading = false
        }

      })
    },
    clickWeiChatLink(url) {
      window.open(url)
    },
  }
};
</script>

<style scoped>
/*.wcContainer {*/
/*  display: flex;*/
/*  margin: auto;*/
/*  flex-wrap: wrap;*/
/*}*/

/*.wcItem {*/
/*  flex-basis: 33.33%;*/
/*  margin-top: 60px;*/
/*  text-align: center*/
/*}*/
.wcContainer {
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  gap:20px
}

.wcItem {
  /*flex-basis: 33.33%;*/
  width: 30%;
  min-width: 350px;
  margin-top: 60px;
  text-align: center
}

P {
  text-align: center;
  width: 100%;
  font-size: 15px;
}
</style>

