import store from "@/store";
export function permission(type,datas) {
    //排除管理员
    const role = store.getters && store.getters["right/getRole"]
    if (role === 'ADMIN' || role === 'MANAGER') {
        return true
    }
    if(['project_share'].find(item=>item===type) ){
        if(role === 'SALES' || role === 'SALES_ADMIN'){
            return true
        }
        const permissions = store.getters && store.getters["right/getUser"]
        console.log(permissions,datas)
        if (permissions) {
            if (datas && permissions.customer) {
                const hasPermission = datas.some(data => {
                    return permissions.customer.id.includes(data)
                })
                return hasPermission
            } else {
                return false
            }
        }else {
            return false
        }
    }else {
        return  false
    }
}
