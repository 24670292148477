<template>
  <el-dialog
    title="日志信息"
    :visible.sync="showDialog"
    :before-close="handleCloseTaskLog"
    width="50%">
   <div ref="logContent">
     <p v-for="logItem in logContent">{{logItem}}</p>
   </div>
    <span slot="footer" class="dialog-footer">
     <el-button type="primary" @click="handleCloseTaskLog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: ['showDialog', 'logContent'],
  data() {
    return {
    }
  },
  watch:{
    logContent(){
      this.$nextTick(() => {
        const container = this.$refs.logContent
        container.scrollTop = container.scrollHeight;
      })
    }
  },
  methods: {
    handleCloseTaskLog () {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.$emit('update:showDialog',false)
    },
  }

}


</script>

<style scoped>
.el-dialog div {
  max-height: 350px;
  overflow: auto;
}
</style>
