//修改密码
import {deliveryApi} from "@/axios";

export function updatePass(data) {
    return deliveryApi({
        url: '/user/rePass',
        method: 'post',
        data: data

    })
}

//个人信息
export function userInfo() {
    return deliveryApi({
        url: '/user/info',
        method: 'get',

    })
}

//个人信息的修改
export function updateUserInfo(data) {
    return deliveryApi({
        url: '/user/edit',
        method: 'put',
        data: data

    })
}
