export const columnsData=(filteredInfo)=>[
    {
        title: '项目号',
        dataIndex: 'projectsRow.projectId',
        key: 'projectId',
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'projectId',
        },
        filteredValue: filteredInfo.projectId || [],
    },
    {
        title: '合同号',
        dataIndex: 'projectsRow.contractNumber',
        key: 'contractNumber',
        width: 200,
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'foldInfo'
        },
        filteredValue: filteredInfo.contractNumber || [],
    },
    {
        title: '类型',
        dataIndex: 'projectsRow.projectSign',
        key: 'projectSign',
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        scopedSlots: {
            filterDropdown: 'filterProjectType',
            filterIcon: 'filterIcon',
            customRender: 'projectSign'
        },
        filteredValue: filteredInfo.projectSign || [],
    },
    {
        title: '销售姓名',
        dataIndex: 'salerList',
        key: 'salesName',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'salesName'
        },
        filteredValue: filteredInfo.salesName || [],
    },
    {
        title: '客户姓名',
        dataIndex: 'customerList',
        key: 'customerName',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customerName'
        },
        filteredValue: filteredInfo.customerName || [],
    },
    {
        title: '分析员姓名',
        dataIndex: 'analyst.name',
        key: 'analystName',
        // sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        filteredValue: filteredInfo.analystName || [],
    },
    {
        title: '创建时间',
        dataIndex: 'projectsRow.createTime',
        key: 'createTime',
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
    },
    {
        title: '报告审批状态',
        dataIndex: 'projectsRow.passApproval',
        key: 'projectsRow.passApproval',
        scopedSlots: {
            customRender: 'passApproval'
        },
    },
    {
        title: '原始数据状态',
        dataIndex: 'projectsRow.rawDataStatus',
        key: 'rawDataStatus',
        width: 200,
        scopedSlots: {
            filterDropdown: 'filterRawDataStatus',
            filterIcon: 'filterIcon',
            customRender: 'rawDataStatus',
        },
        filteredValue: filteredInfo.rawDataStatus || [],
    },
    {
        title: '备注',
        dataIndex: 'projectsRow.note',
        key: 'note',
        width: 200,
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customNote',
        },
        filteredValue: filteredInfo.projectId || [],
    },
    {
        title: '操作',
        key: 'operation',
        scopedSlots: {customRender: 'operation'},
    },
]
