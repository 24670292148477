<template>
  <div class="app-container">
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="10" :xs="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div>
            <ul>
              <li class="list-group-item">
                <span style="width: 100px;flex: none"><i class="el-icon-user-solid"/>登录账号</span>
                <div class="pull-right">{{ userData.userName }}</div>
              </li>
              <div>
                <li class="list-group-item">
                  <span style="width: 50px;flex: none"><i class="el-icon-user-solid"/>姓名</span>
                  <div>{{ user.name }}</div>
                </li>
                <li class="list-group-item">
                  <span style="width: 100px;flex: none"><i class="el-icon-phone"/>电话号码</span>
                  <div>{{ user.telephone }}
                    <el-tooltip content="修改手机号码">
                      <a><i class="el-icon-edit" @click="handleEdit"></i></a>
                    </el-tooltip>
                  </div>
                </li>
                <li class="list-group-item">
                  <span style="width: 100px;flex: none"><i class="el-icon-s-promotion"/>邮箱账号</span>
                  <div>{{ user.email }}</div>
                </li>
              </div>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="14" :xs="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>{{ activeName }}</span>
          </div>
              <el-form v-if="isShowFormPwd" ref="formPwd" :model="userPwd" label-width="80px" :rules="rulesPwd">
                <el-form-item label="旧密码" prop="pass">
                  <el-input v-model="userPwd.pass" placeholder="请输入旧密码" type="password" show-password/>
                </el-form-item>
                <el-form-item label="新密码" prop="newPass">
                  <el-input v-model="userPwd.newPass" placeholder="请输入新密码" type="password" show-password/>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                  <el-input v-model="userPwd.confirmPassword" placeholder="请确认新密码" type="password" show-password/>
                </el-form-item>
                <el-form-item>
                  <div style="display: flex;justify-content: space-between">
                    <el-button type="primary" size="mini" @click="submitPwd('PASS')">保存</el-button>
                    <a @click="changePwdMethod">使用验证码修改</a>
                  </div>
                </el-form-item>
              </el-form>
              <div v-else>
                <el-form label-width="100px" :model="codeForm" ref="codeForm" :rules="codeFormRules"
                         class="login-form" @submit.native.prevent>
                  <el-form-item label="邮箱账号" prop="account">
                    <el-input auto-complete="off" placeholder="请输入邮箱账号" prefix-icon="el-icon-user" readonly
                              v-model="codeForm.account"></el-input>
                  </el-form-item>
                  <el-form-item label="图形验证" prop="captcha">
                    <div style="display: flex;">
                      <el-input style="width:160px " placeholder="请输入图形验证码" v-model="codeForm.captcha">
                      </el-input>
                      <img :src="verifyImgUrl" style="height: 40px;cursor: pointer;margin-left: 40px"
                           @click="getVerifyImg()">
                    </div>
                  </el-form-item>
                </el-form>
                <el-form label-width="100px" :model="userPwd" ref="formPwd" :rules="rulesPwd">
                  <el-form-item label="验证码" prop="pass">
                    <div style="display: flex;">
                      <el-input style="width:160px " placeholder="请输入验证码" v-model="userPwd.pass"></el-input>
                      <el-button type="primary" style="margin-left: 30px" size="small" @click="verAuthCode()"
                                 v-if="!showTime"
                                 :disabled="showTime">点击发送验证码
                      </el-button>
                      <el-button type="info" style="margin-left: 30px" size="small" v-if="showTime"
                                 :disabled="showTime">
                        {{ btnText }}后重新发送
                      </el-button>
                    </div>
                  </el-form-item>
                  <el-form-item label="新密码" prop="newPass">
                    <el-input v-model="userPwd.newPass" placeholder="请输入新密码" type="password" show-password/>
                  </el-form-item>
                  <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input v-model="userPwd.confirmPassword" placeholder="请确认新密码" type="password"
                              show-password/>
                  </el-form-item>
                  <el-form-item>
                    <div style="display: flex;justify-content: space-between">
                      <el-button type="primary" size="mini" @click="submitPwd('CODE')">保存</el-button>
                      <a @click="changePwdMethod">使用密码修改</a>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog :destroy-on-close="true"
               title="修改电话号码"
               :visible.sync="showTelDialog"
               width="500px">
      <el-form :model="editUserInfoForm" label-width="80px" :rules="rules" ref="editUserInfoForm">
        <el-form-item label="手机号码" prop="telephone">
          <el-input
              placeholder="手机号码"
              v-model="editUserInfoForm.telephone">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
              <el-button @click="showTelDialog=false">取 消</el-button>
              <el-button type="primary" @click="submitEdit">确 定</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import {getDataInLocalStorage, removeDataInLocalStorage} from "@/utils/localStorage";
import {updatePass, updateUserInfo, userInfo} from "@/api/dilivery/info";
import {getCaptImage, sendCode} from "@/api/dilivery";
import {checkMail} from "@/utils/rules";

export default {
  name: "UserInfo",

  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.userPwd.newPass !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      userData: {},
      user: {
        name: '',
        telephone: '',
        email: '',
      },
      userPwd: {
        pass: '',
        newPass: '',
        confirmPassword: ''
      },
      oldPassWord: '',
      activeName: "修改密码",
      editUserInfoForm:{
        telephone:''
      },
      showTelDialog:false,
      //个人信息的规则
      rules: {
        telephone: [
          {pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的电话号码', trigger: 'blur'}
        ],
      },
      //重置密码的规则
      rulesPwd: {
        pass: [
          {required: true, message: "不能为空", trigger: "blur"}
        ],
        newPass: [
          {required: true, message: "新密码不能为空", trigger: "blur"},
          {min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur"}
        ],
        confirmPassword: [
          {required: true, message: "确认密码不能为空", trigger: "blur"},
          {required: true, validator: equalToPassword, trigger: "blur"}
        ]
      },
      codeFormRules: {
        account: [{validator: checkMail, trigger: 'blur'},],
        captcha: [{required: true, message: "图形验证不能为空", trigger: "blur"},]
      },
      userName: '',
      verifyImgUrl: '',
      btnText: '60s',
      showTime: false,
      codeForm: {
        account: '',
        captcha: ''
      },
      isShowFormPwd: true,
    };

  },
  created() {
    this.userData = JSON.parse(getDataInLocalStorage('user'))
    if (!this.userData.initialized) {
      this. activeName='修改密码'
      this.isShowFormPwd=false
      this.getVerifyImg()
    }
    this.getUserInfo()
  },
  methods: {
    //获取个人信息
    getUserInfo() {
      userInfo().then(res => {
        if (res.code === 200) {
          this.user.uid = res.data.uid
          this.user.name = res.data.realName
          this.user.telephone = res.data.customer.phoneNumber
          this.user.email = res.data.customer.emailAccount
          this.codeForm.account=this.user.email
        }
      })
    },
    handleEdit(){
      this.showTelDialog=true
      this.editUserInfoForm.telephone=this.user.telephone
    },
    //提交个人信息的修改
    submitEdit() {
      const data = {
        phoneNumber: this.editUserInfoForm.telephone,
      }
      this.$refs.editUserInfoForm.validate(valid => {
        if (valid) {
          this.showTelDialog=false
          updateUserInfo(data).then(res => {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.getUserInfo()
            } else {
              this.$message.error('保存失败')
            }
          })
        }
      })
    },
    //密码的修改
    submitPwd(method) {
      this.$refs['formPwd'].validate(valid => {
        if (valid) {
          const data = {
            pass: this.userPwd.pass,
            newPass: this.userPwd.newPass,
            method: method
          }
          updatePass(data).then(res => {
            if (res.code === 200) {
              this.$message.success('密码修改成功,请重新登录')
              removeDataInLocalStorage('userInfo')
              this.$router.push('/login')
            } else {
              this.$message.error('密码修改失败')
            }
          })
        }
      })
    },
    //密码、验证码的切换
    changePwdMethod() {
      this.isShowFormPwd = !this.isShowFormPwd
      if (!this.isShowFormPwd) {
        this.getVerifyImg()
      }
    },
    // 获取验证码照片
    async getVerifyImg() {
      getCaptImage().then(res => {
        if (res.code === 200) {
          this.verifyImgUrl = res.img
          this.captchaSing = res.captchaKey
          this.userPwd.pass = ''
        }
      })
    },
    //验证图形验证码
    async verAuthCode() {
      this.$refs.codeForm.validate(valid => {
        if (valid) {
          this.getAuthCode()
        }
      })
    },
    //获取短信验证码
    async getAuthCode() {
      var sendData = {
        account: this.codeForm.account,
        value: this.codeForm.captcha,
        captchaKey: this.captchaSing
      }
      const res = await sendCode(sendData)
      if (res.code === 200) {
        //防止60s内重复点击
        this.$message.success("验证码发送成功！")
        this.oneMinutes = 60;
        this.showTime = true;
        setInterval(() => {
          this.btnText = `${this.oneMinutes}s`;
          this.oneMinutes--;
          if (this.oneMinutes <= 0) {
            this.btnText = '';
            this.showTime = false;
            this.oneMinutes = 60;
          }
        }, 1000)
      } else {
        await this.getVerifyImg()
        this.codeForm.captcha = ''
      }
    },
  }
};
</script>
<style scoped>

.list-group-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7eaec;
  padding: 11px 0px;

}


</style>
