<template>
  <div>
    <w-table :columns="columns" :table-data="tableData"
             @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true" :initialConditions="initialConditions"
             :page="page" @pageChange="handlePagePagination">
      <!--       折叠-->
      <div slot="foldInfo" slot-scope="{text, record, index}">
        <ex-fold-table-cell :scope="{text:text.split('|'), record, index}"></ex-fold-table-cell>
      </div>
      <!--备注-->
      <span slot="remark" slot-scope="{text,record}">
        <span v-if="text&&text.length>=20">
          {{ text.substring(0, 20) }}
          <el-tooltip :content="text">
            <span style="cursor: pointer">...</span>
          </el-tooltip>
        </span>
        <span v-if="!text||text.length<20">{{ text }}</span>
      </span>
      <!--      释放方式-->
      <div slot="dataWay" slot-scope="{text,record}">
        <div v-if="text" style="display: flex;justify-items: center">
         <div style="display: block;padding-top: 5px;">硬盘释放</div>
          <el-tooltip content="查看快递单号">
            <span v-html="carIcon"  @click="lookCourierNo(record.courierNo)"></span>
          </el-tooltip>

        </div>
        <span v-else>
         网盘释放
        </span>
      </div>
      <span slot="salesName" slot-scope="{text,record}">
        {{ text ? text.map(item => item.name).join(';') : '' }}
      </span>
      <span slot="customerName" slot-scope="{text,record}">
           {{ text ? Array.from(new Set(text.map(item => item.realName))).join(';') : '' }}
      </span>
      <template slot="operation" slot-scope="{text,record}">
        <el-button
            size="mini"
            type="success"
            @click="handleRawData(record)">原始数据
        </el-button>
        <el-button
            size="mini"
            type="primary"
            @click="handleShare(record)">分享
        </el-button>
      </template>
    </w-table>
    <!--    分享管理-->
    <share-customer-manager-dialog v-if="showShareCusDialog" :select-detail="selectDetail" platform="NAKED"
                                   :show-dialog="showShareCusDialog"
                                   @submit="closeShareCusDialog"></share-customer-manager-dialog>
    <!--    修改失效时间-->
    <modify-time-dialog v-if="showModifyTimeDialog" :show-dialog.sync="showModifyTimeDialog"
                        :select-detail="selectDetail" @submit="submitModify"></modify-time-dialog>
    <!--    原始数据文件下载-->
    <down-files-dialog v-if="downRawFilesDialog" :show-dialog.sync="downRawFilesDialog"
                       :down-raw-files-info="downRawFilesInfo"
                       :downLoadUrl="downLoadRawUrl"></down-files-dialog>
<!--    快递单号-->
    <look-info-dialog v-if="courierNoDialog" :show-dialog.sync="courierNoDialog"
                      :select-detail="selectDetail" title="快递单号">
      <el-form-item slot="formInfo" slot-scope="formInfo" label="快递单号" >
        <el-input v-model="formInfo.courierNo" placeholder="请输入快递单号" type="textarea" readonly
                  :autosize="{ minRows: 3,}"></el-input>
      </el-form-item>
    </look-info-dialog>
  </div>
</template>

<script>
import {
  listProject, modifyExpTime, rawDataLink,
} from "@/api/naked/rawData/project";
import Info from "@/views/systems/project/rawData/Info";
import ChangeUsers from "@/components/ChangeUsers";
import EditProject from "@/components/rawData/EditProject";
import PictureLoading from "@/components/customerTable/PictureLoading";
import LoadingDialog from "@/components/LoadingDialog";
import {columnsData} from "@/utils/data/tableCol/nakedTableCol";
import ExFoldTableCell from "@/components/ExFoldTableCell";
import ShareCustomerManagerDialog from "@/components/ShareCustomerManagerDialog";
import ModifyTimeDialog from "@/components/ModifyTimeDialog";
import downFilesDialog from "@/components/downRawData/DownFilesDialog";
import {carIcon} from "@/utils/iconUtils";
import LookInfoDialog from "@/components/approve/LookInfoDialog";

export default {
  name: "NakedRawData",
  components: {
    LookInfoDialog,
    downFilesDialog,
    ModifyTimeDialog,
    ShareCustomerManagerDialog,
    EditProject,
    ChangeUsers,
    Info,
    PictureLoading,
    LoadingDialog,
    ExFoldTableCell
  },
  data() {
    return {
      loading: true,
      isShowAddLoading: false,
      showAddLoadingMess: '文件上传中，请耐心等待.......',
      //分页
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      tableData: [],//表格数据
      searchConditionTag: [],
      sortConditionTag: '',
      showUserDialog: false,//展示更改用户的弹框
      updateProjectUserData: {
        projectId: '',
        selectedSalers: [],
        selectedAnalyze: '',
      }, //更改用户的数据（更换分析和销售）
      openDetail: false,//查看详情弹框
      detailForm: {},
      selectDetail: '',
      showModifyTimeDialog: false,//修改时间
      showShareCusDialog: false,//分享客户管理
      downRawFilesDialog: false,//原始数据下载弹框
      downLoadRawUrl: '',//原始数据下载链接
      downRawFilesInfo: {},
      initialConditions: {},//初始搜索条件
      carIcon: carIcon,
      courierNoDialog:false
    }
  },
  computed: {
    columns() {
      let column = JSON.parse(JSON.stringify(columnsData(this.searchConditionTag || {})))
      if (this.$store.getters["right/getRole"] === 'USER') {
        column.splice(column.length - 4, 1)
      }
      return column
    }
  },
  created() {
  },
  mounted() {
    this.getData('first')
  },
  methods: {
    getData(isSearch) {
      this.loading = true
      var param = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize,
        'order': ''
      }
      if (isSearch === 'search') {
        param['page.size'] = 10
        param['page.current'] = 1
      }
      let sendData = {}
      // 判断是否从总览跳转过来的
      if (isSearch === 'first' && this.$route.query.id) {
        //从审批跳转过来的
        this.initialConditions = {
          searchConditionTag: [{
            title: '项目号',
            key: 'id',
            value: this.$route.query.id
          }]
        }
        this.searchConditionTag = this.initialConditions.searchConditionTag
      }
      let length = this.searchConditionTag.length
      if (length) {
        this.searchConditionTag.forEach(item => {
          let key = item.key
          sendData[key] = item.value
        })
      }
      listProject(param, sendData).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.loading = false
          this.tableData = res.data.records
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      })
    },
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      if (page.pageSize) {
        this.page.pageSize = page.pageSize
      } else {
        this.page.pageSize = this.page.total
      }
      this.getData()
    },
    handleModify(record) {
      this.selectDetail = record
      this.showModifyTimeDialog = true
    },
    submitModify(data) {
      modifyExpTime(data).then(res => {
        if (res.code === 200) {
          this.getData()
          this.showModifyTimeDialog = false
          this.$messageUi.success('修改成功')
        }
      }).catch(() => {
        this.showModifyTimeDialog = false
      })
    },
    // 原始数据
    handleRawData(record) {
      rawDataLink(record.id).then(res => {
        if (res.code === 200) {
          this.downLoadRawUrl = res.data
          fetch(this.downLoadRawUrl, {
            method: 'GET', // 或者 'POST', 'PUT', 'DELETE' 等
            headers: {
              'Content-Type': 'application/json',
              'LingenRawDownloader': 'rawDownload',
            },
          }).then(res => res.json())
              .then(res1 => {
                if (res1.code === 200) {
                  this.downRawFilesInfo = res1.data
                  this.downRawFilesDialog = true
                } else {
                  this.$messageUi.error(res1.msg)
                }
              }).catch((error) => {
            this.$messageUi.error(error)
          })


        }
      })
    },
    //分享项目
    handleShare(row) {
      this.selectDetail = {
        projectId: row.id,
        customerList: row.customerList
      }
      this.showShareCusDialog = true
    },
    //关闭分享
    closeShareCusDialog() {
      this.showShareCusDialog = false
    },
    lookCourierNo(courierNo){
      this.selectDetail={courierNo:courierNo}
      this.courierNoDialog=true



    }

  }

}
</script>

<style scoped>

</style>
