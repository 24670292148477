import {nakedApi} from "@/axios";

export function nakedApprovalList(params,data){
    return nakedApi({
        url:'/data-approval/page',
        method:'post',
        data:data,
        params:params,
    })
}
export function nakedApproval(data){
    return nakedApi({
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        url:'/data-approval/'+data.result+'/'+data.id,
        method:'put',
        data:data.msg,
    })
}
export function nakedApprovalModify(id,data){
    return nakedApi({
        headers: {
            'Content-Type': 'application/text; charset=utf-8'
        },
        url:'/data-approval/amendment/'+id,
        method:'put',
        data:data,
    })
}
//件获取条数
export function redCountNaked(data){
    return nakedApi({
        url:'/data-approval/count',
        method:'post',
        data:data
    })
}
