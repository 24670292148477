import {changeFilteredInfo} from "@/utils/data/tableCol/tableUtils";
export const columnsData=(filteredInfos)=>{
    let filteredInfo=changeFilteredInfo(filteredInfos)
    return [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            // sorter: true,
            sortDirections: ["descend", "ascend", 'descend'],
            scopedSlots: {
                filterIcon: 'filterIcon',
                filterDropdown: 'filterDropdown',
                filtered: true,
            },
            filteredValue: filteredInfo.name || [],
        },
        {
            title: '配置键',
            dataIndex: 'confKey',
            key: 'confKey',
            scopedSlots: {
                filterIcon: 'filterIcon',
                filterDropdown: 'filterDropdown',
                filtered: true,
            },
            filteredValue: filteredInfo.confKey || [],
        },
        {
            title: '配置值',
            dataIndex: 'confValue',
            width: 300,
            key: 'confValue',
        },
        {
            title: '操作',
            width: 200,
            key: 'operation',
            scopedSlots: {customRender: 'operation'},
        }
    ]}
