<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane label="待审批" name="pending"></el-tab-pane>
      <el-tab-pane label="已审批" name="history"></el-tab-pane>
    </el-tabs>
    <div style="width:100%;">
      <w-table ref="wMyTable" :columns="columns" :table-data="tableData"
               @getTableSearchSortCon="getTableSearchSortCon" :isPagination="true"
               :page="page" @pageChange="handlePagePagination">
        <span slot="platform" slot-scope="{text,record}">
          {{ getPlatform(text) }}
        </span>
        <!--       折叠-->
        <div slot="foldInfo" slot-scope="{text, record, index}">
          <ex-fold-table-cell :scope="{text:text.split('|'), record, index}"></ex-fold-table-cell>
        </div>
        <!--       折叠-->
        <div slot="foldInfo" slot-scope="{text, record, index}">
          <ex-fold-table-cell :scope="{text:text.split('|'), record, index}"></ex-fold-table-cell>
        </div>
        <!--        审批状态-->
        <span slot="approvalStatus" slot-scope="{text,record}">
          <el-tag :type="text==='PENDING'?'warning':text==='PASSED'?'success':'danger'">  {{
              getApprovalStatus(text)
            }}</el-tag>
        </span>
        <!--        加急状态-->
        <span slot="urgentStatus" slot-scope="{text,record}">
            <span v-if="text">
              <a-icon type="fire" theme="filled" style="color: red"/>加急
            </span>
            <span v-else>
            </span>
        </span>
        <!--        纯分析状态-->
        <span slot="pureStatus" slot-scope="{text,record}">
            <el-tag v-if="text" type="primary" plain>是</el-tag>
            <el-tag v-else type="info" plain>否</el-tag>
        </span>
        <!--        销售姓名展示-->
        <span slot="salesName" slot-scope="{text,record}">
            {{ text ? text.map(item => item.name).join(';') : '' }}
        </span>
        <!--        备注-->
        <span slot="remark" slot-scope="{text,record}">
          <span v-if="text&&text.length>=20">
          {{ text.substring(0, 20) }}
          <el-tooltip :content="text">
            <span style="cursor: pointer">...</span>
          </el-tooltip>
          </span>
          <span v-if="!text||text.length<20">{{ text }}</span>
        </span>
        <!--        审批理由-->
        <span slot="approveMsg" slot-scope="{text, record}">
              <div class="descriptionTxt">{{ text }}</div>
        </span>
        <div slot="operation" slot-scope="{text,record}">
          <el-button size="mini" type="success" v-if="record.approvalStatus==='PENDING'" @click="handleApprove(record)">
            审批
          </el-button>
          <el-button size="mini" type="primary" @click="handleReportSrc(record)">
            报告路径
          </el-button>
          <el-button size="mini" type="warning" @click="handleDownAcq(record)">
            下载采集表
          </el-button>
        </div>
        <div slot="filterDropdownPlatform" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
             style="padding: 8px;width: 340px; position: relative; z-index: 9999 !important;">
          <div style="padding: 0px 15px">
            <el-radio-group v-model="selectedKeys[0]">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-radio label="交付系统"> 交付系统</el-radio>
                </el-col>
                <el-col :span="12">
                  <el-radio label="项目交付系统">项目交付系统</el-radio>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-radio label="多样性交付系统">多样性交付系统</el-radio>
                </el-col>
                <el-col :span="12">
                  <el-radio label="宏基因组交付系统">宏基因组交付系统</el-radio>
                </el-col>

              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-radio label="转录组交付系统">转录组交付系统</el-radio>
                </el-col>
                <el-col :span="12">
                  <el-radio label="代谢组交付系统">代谢组交付系统</el-radio>
                </el-col>

              </el-row>
            </el-radio-group>
          </div>
          <div style="display: flex;padding: 15px">
            <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
            </a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
            </a-button>
          </div>
        </div>
      </w-table>
    </div>
    <picture-loading v-if="loading"></picture-loading>
    <!--    审批弹框-->
    <approve-dialog v-if="showApproveDialog" :show-approve-dialog="showApproveDialog" :select-detail="selectDetail"
                    @submit="submitApprove"></approve-dialog>
    <!--    报告路径弹框-->
    <file-host-path-dialog v-if="showReportPathDialog" :show-dialog="showReportPathDialog" :select-detail="selectDetail"
                           @submit="closReportPathDialog"></file-host-path-dialog>
    <!--    审批结果弹框-->
    <approve-detail-dialog v-if="showApproveDetailDialog" :showDialog="showApproveDetailDialog"
                           :select-detail="selectDetail" @submit="closeApproveDetail"></approve-detail-dialog>
  </div>
</template>

<script>
import PictureLoading from "@/components/customerTable/PictureLoading";
import {approveReportApprove, approveReportList} from "@/api/dilivery/approve/report";
import {apprReportTabCol} from "@/utils/data/tableCol/apprReportTabCol";
import {getApprovalStatusChByEn, getPlatformChByEn, getPlatformEnByCh} from "@/utils/ch_en_trans";
import ApproveDialog from "@/components/approve/ApproveDialog";
import {getDataInLocalStorage} from "@/utils/localStorage";
import ApproveDetailDialog from "@/components/approve/ApproveDetailDialog";
import {downloadCommon} from "@/utils/downFileUtils";
import ExFoldTableCell from "@/components/ExFoldTableCell";
import FileHostPathDialog from "@/components/FileHostPathDialog";
import {tableOperationMixin} from "@/mixins/tableOperation";
import {needRedDoltInfo} from "@/utils/data/basicData/leftNavData";

const baseURL = process.env.VUE_APP_BASE_API
export default {
  name: "ApproveReportUser",
  mixins: [tableOperationMixin],
  components: {
    ApproveDetailDialog,
    ApproveDialog,
    PictureLoading,
    ExFoldTableCell,
    FileHostPathDialog
  },
  data() {
    return {
      loading: true, //是显示加载
      title: '添加客户',
      searchConditionTag: [],//搜索tag
      sortConditionTag: [],
      tableData: [],
      page: {
        pageSize: 10,
        currentNum: 1,
        total: null
      },
      activeName: 'pending',
      showApproveDialog: false,//审批弹框
      showApproveDetailDialog: false,//审批结果弹框
      showReportPathDialog: false,//报告路径弹框
      selectDetail: '',
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    columns: {
      get() {
        let reviewer = [{
          title: '审批人',
          dataIndex: 'reviewerName',
          key: 'reviewerName',
        },
          {
            title: '审批时间',
            dataIndex: 'approvalTime',
            key: 'approvalTime',
          }, {
            title: '审批理由',
            dataIndex: 'approvalRemark',
            key: 'approvalRemark',
            scopedSlots: {
              customRender: 'approveMsg'
            }
          },]
        let columns = apprReportTabCol(this.searchConditionTag || {})
        if (this.activeName !== 'pending') {
          columns.splice(columns.length - 1, 0, ...reviewer)
        }
        return columns
      }
    },
  },
  methods: {
    getTableSearchSortCon(searchConditionTag, sortConditionTag) {
      this.searchConditionTag = searchConditionTag
      this.sortConditionTag = sortConditionTag
      this.getData('search')
    },
    //获取数据
    getData(type) {
      this.loading = true
      let params = {
        'page.current': this.page.currentNum,
        'page.size': this.page.pageSize,
        order: this.activeName !== 'pending' ? 'createTime.desc' : 'createTime.asc'
      }
      if (type) {
        params['page.current'] = 1
        params['page.size'] = 10
      }
      let sendData = {}
      sendData.approvalStatus = this.activeName === 'pending' ? ['PENDING'] : ['PASSED', 'DECLINED']
      sendData = this.getSearch(sendData)
      approveReportList(params, sendData).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.currentNum = parseInt(res.data.current)
          this.page.pageSize = parseInt(res.data.size)
          this.page.total = parseInt(res.data.total)
        }
      }).catch(() => {
      })
    },
    getSearch(sendData) {
      this.searchConditionTag.forEach(item => {
        if (item.key === 'platform') {
          if (item.value !== '交付系统') {
            sendData[item.key] = getPlatformEnByCh(item.value)
          }
        } else {
          sendData[item.key] = item.value
        }
      })
      return sendData
    },
    //表格的选择框
    rowSelection(selectedRows) {
      this.ids = selectedRows.map(item => item.id)
    },
    //页码的变动
    handlePagePagination(page) {
      this.loading = true
      this.page.currentNum = page.currentNum
      if (page.pageSize) {
        this.page.pageSize = page.pageSize
      } else {
        this.page.pageSize = this.page.total
      }
      this.getData()
    },
    getPlatform(text) {
      return getPlatformChByEn(text)
    },
    getApprovalStatus(text) {
      return getApprovalStatusChByEn(text)
    },
    handleClickTab(tab) {
      this.getData('change')
    },
    // 审批
    handleApprove(val) {
      this.selectDetail = val
      this.showApproveDialog = true
    },
    //提交审批
    submitApprove(data, type) {
      this.showApproveDialog = false
      console.log(data)
      if (type !== 'cancel') {
        approveReportApprove(data).then(res => {
          if (res.code === 200) {
            needRedDoltInfo.forEach(item => {
              this.$store.dispatch(`dataStatus/getRedDoltNum`, item)
            })
            this.getData('search')

            this.$message.success('审批成功')
          }
        })
      }
    },
    // 下载报告
    handleDown(row) {
      const url = `${baseURL}/report-approval/report/download/${row.id}?Authorization=${getDataInLocalStorage("token")}`
      downloadCommon(url)
    },
    // 下载采集表报告
    handleDownAcq(row) {
      const url = `${baseURL}/report-approval/acq/download/${row.id}?Authorization=${getDataInLocalStorage("token")}`
      downloadCommon(url)
    },
    //打开审批结果详情
    handleApproveDetail(row) {
      this.selectDetail = row
      this.showApproveDetailDialog = true
    },
    //关闭审批结果详情
    closeApproveDetail() {
      this.showApproveDetailDialog = false
    },
    //打开查看报告路径
    handleReportSrc(row) {
      if (row.analyzeDir && row.hostName) {
        this.showReportPathDialog = true
        this.selectDetail = {
          hostName: row.hostName,
          dirPath: row.analyzeDir
        }
      } else {
        this.$messageUi.error('暂无报告路径')
      }
    },
    // 关闭报告路径弹框
    closReportPathDialog() {
      this.showReportPathDialog = false
    }
  }
}
</script>

<style scoped>
.descriptionTxt {
  width: 200px;
  word-wrap: break-word;
  /*padding: 2px !important;*/
  white-space: pre-wrap;
}
</style>
